export default {
  "Analytics": {
    "charts": {
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
      "refusal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отказ"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешние"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время"])},
      "title": {
        "declinedAnalytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина отказов"])},
        "formFactorAnalytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Устройство оплаты"])},
        "paymentSystemAnalytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежные системы"])},
        "statusAnalytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакции"])},
        "transactionActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активность транзакции"])}
      }
    },
    "select": {
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произвольные даты"])},
      "customDateRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["даты:"])},
      "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["за текущий день"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За 30-дней"])},
      "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Временной промежуток"])}
    }
  },
  "Attestation": {
    "action": {
      "select": {
        "option": {
          "BLOCK_DEVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блокировка на устройство"])},
          "BLOCK_MANUAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual блокировка"])},
          "BLOCK_NFC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFC блокировка"])},
          "BLOCK_PIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIN блокировка"])},
          "BLOCK_QR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR блокировка"])},
          "BLOCK_TRANSACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блокировка на транзакцию"])},
          "NO_BLOCK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет блокировки"])}
        }
      },
      "table": {
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действие"])},
        "actionWeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вес"])},
        "shortName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аббревиатура"])}
      }
    },
    "sequenceThreats": {
      "table": {
        "attestationActions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
        "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])}
      }
    },
    "tab": {
      "actionsThreats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия / Угрозы"])},
      "sequenceThreats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последовательность угроз"])}
    },
    "threat": {
      "table": {
        "CHANNEL_INTEGRITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Целостность каналов"])},
        "DEBUG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тестирование приложения"])},
        "EMULATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эмуляция приложения"])},
        "GEO_POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Геопозиция"])},
        "INSTALL_SOURCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Источник установки"])},
        "INTEGRITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Целостность"])},
        "KEY_ATTESTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сертификация ключей"])},
        "ROOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Права приложения"])},
        "VELOCITY_CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частота транзакций"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действие"])},
        "threat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Угроза"])}
      }
    }
  },
  "AttestationHistory": {
    "table": {
      "attestationPhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фаза"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата отправления"])},
      "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результат аттестации"])},
      "deviceSn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор устройства"])},
      "pinChannelIntegrity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Целостность пин-канала"])},
      "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])}
    }
  },
  "BusinessDayCalculation": {
    "btn": {
      "generateCalculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сформировать расчет"])}
    },
    "form": {
      "billingCalculationNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчет №"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расчеты за бизнес день"])}
    },
    "tab": {
      "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архив"])},
      "calculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расчет"])}
    },
    "table": {
      "allAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
      "allCalculatedFacilityValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия"])},
      "allPercentageFacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Percentage Facility"])},
      "allStaticFacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значение комиссии"])},
      "amountMinusFacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма зачисления"])},
      "calculatedValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма комиссии"])},
      "facilityId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID комиссии"])},
      "facilityName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название комиссии"])},
      "groupFacilityId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер группы комиссии"])},
      "groupTerminalNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер терминала группы"])},
      "numberOfAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего транзакций"])},
      "numberOfPurchases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість покупок"])},
      "numberOfRefund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund"])},
      "numberOfReversal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reversal"])},
      "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])}
    },
    "text": {
      "billingCalculationNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер расчета: "])},
      "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия"])},
      "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата создания: "])},
      "toBeEnrolled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К зачислению"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего"])}
    }
  },
  "ChatHistory": {
    "table": {
      "answerIdList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ид-оры ответов"])},
      "answerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание проблемы"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
      "sessionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор сессии"])},
      "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])}
    }
  },
  "Datepicker": {
    "textChoiceRangeDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите даты начала и окончания отчета"])}
  },
  "Devices": {
    "deviceStatus": {
      "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активный"])},
      "Blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблокирован"])},
      "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удален"])},
      "Key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key"])},
      "Loan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loan"])},
      "Unblocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разблокирован"])}
    },
    "table": {
      "appStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус приложения"])},
      "deviceFingerprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отпечаток устройства"])},
      "deviceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название устройства"])},
      "deviceSn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор устройства"])},
      "deviceStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Состояние устройства"])},
      "initDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата инициации"])},
      "osVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Версия системы"])},
      "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])}
    }
  },
  "EntityAudit": {
    "table": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата и время"])},
      "entityId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор лога"])},
      "entityName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название лога"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь"])}
    }
  },
  "Footer": {
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© Авторские права Transplat 2024."])},
    "versionStr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["версия"])}
  },
  "Investigation": {
    "table": {
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
      "date_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания:"])},
      "date_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата начала:"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["description"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "requestId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request ID"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно"])},
      "terminal_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])}
    }
  },
  "IpsCardGroups": {
    "modal": {
      "addNewGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])},
      "deleteGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить группу платежной системы:"])}
    },
    "table": {
      "firstCardNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Первые цифры"])},
      "ipsCardGroupId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор"])},
      "ipsName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IPS Название"])},
      "ipsSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Символ платежной системы"])},
      "noPinLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лимит без PIN"])},
      "opManual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual маркер"])},
      "opNfc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маркер без Nfc"])},
      "opPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маркер без PIN"])},
      "opQr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маркер без Qr"])}
    }
  },
  "IpsKey": {
    "table": {
      "caHashAlgInd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сa Hash AlgInd"])},
      "caPkAlgInd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сa Pk AlgInd"])},
      "exponentLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Длина экспоненты"])},
      "exponentValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значение показателя"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор записи ключа"])},
      "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индекс"])},
      "keyExpDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата истечения срока действия ключа"])},
      "modulus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Модуль"])},
      "modulusLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Длина модуля"])},
      "rid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rid"])},
      "scheme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Схема"])},
      "secureHashAlg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secure HashAlg"])}
    }
  },
  "Merchant": {
    "modal": {
      "deleteMerchant": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить мерчанта"])}
      }
    },
    "table": {
      "bankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банк"])},
      "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "mcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCC"])},
      "merchantId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор организации"])},
      "merchantLegalName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юридическое название организации"])},
      "merchantLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
      "merchantName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название организации"])},
      "taxId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНН"])},
      "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])}
    }
  },
  "MerchantRegistration": {
    "btn": {
      "addFata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Миграция на сервер FATA"])},
      "addNewMerchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить нового продавца"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтры"])},
      "reloadPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перезагрузить страницу"])},
      "sendSmsPass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправьте СМС с новым паролем"])},
      "submitMigrateMerchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мигрировать продавца"])},
      "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить регистрацию файла"])}
    },
    "fileForDownloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файлы для загрузки"])},
    "fileForDownloadsType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расширение файла должно быть: *.trn"])},
    "regLastDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация за последние дни"])},
    "table": {
      "ReActivationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторный код активации"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
      "addData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина смены статуса"])},
      "bankId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank ID"])},
      "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата создания"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Широта"])},
      "longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Долгота"])},
      "mcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCC"])},
      "merchantId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant ID"])},
      "merchantLegalName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название организации"])},
      "merchantLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фактический адрес торговца"])},
      "merchantLocationEng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Факт. адрес торговца (англ)"])},
      "merchantName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название торговца"])},
      "merchantNameEng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название торговца (англ)"])},
      "merchantRegId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID аккаунта"])},
      "numSecTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кол. подчинённых терминалов"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Финн. номер телефона"])},
      "radius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Радиус"])},
      "registrationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата регистрации"])},
      "serviceGroupGroupNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер группы терминалов"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Состояние"])},
      "taxId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОКПО/ИНН"])},
      "termPriority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главный терминал"])},
      "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])},
      "terminalLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес терминала"])},
      "terminalLocationEng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес терминала англ."])},
      "userLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логин торговца"])},
      "zreportTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время авт. отправки z-report"])}
    },
    "titleModalMerchantStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение статуса торговца"])},
    "titleModalMigrateToFata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принудительная миграция мерчанта из сервера Транзеникс на сервер FATA."])},
    "titleModalRegMerchantFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация торговца через файл"])},
    "toast": {
      "sendSms": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Отправленно на номера:<br> ", _interpolate(_named("numbers"))])}
    },
    "validationRegForm": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введен некорректный email адрес"])},
      "ibanRegex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN введен в неверном формате"])},
      "mccRegex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допустимые значения более 1000 и менее 10000"])},
      "merchantLocationRegEx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введены недопустимые символы"])},
      "passwordRepeatErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введенный повторно пароль не совпадает"])},
      "phoneNumberRegex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите номер телефона в формате: +380991234567"])},
      "radiusReg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допустимые значения более 0 и менее 1 000 000"])},
      "titude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введены некорректно данные (формат 99.0000)"])}
    }
  },
  "Message": {
    "blockTemplate": {
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст"])}
    },
    "blockTitle": {
      "org": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомление для Организаций"])},
      "searchMerchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск продавца"])},
      "searchTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск терминала"])},
      "selectedMerchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список выбранных продавцов"])},
      "selectedTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список выбранных терминалов"])},
      "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблон уведомлений"])},
      "terminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомление для Терминалов"])}
    },
    "btn": {
      "clearText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очистить"])},
      "createMess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать сообщение"])},
      "saveText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить как шаблон"])},
      "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать все найденные"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])}
    },
    "form": {
      "countText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["количество использованных символов: ", _interpolate(_named("num"))])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст"])}
    },
    "toast": {
      "itemUnique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Элемент уже добавлен в список"])}
    }
  },
  "Monitoring": {
    "charts": {
      "refusal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отказ"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время"])},
      "title": {
        "transactionActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активность транзакции"])}
      }
    },
    "health": {
      "dbConnectionHealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Состояние соединения с БД"])},
      "hsmConnectionHealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Состояние подключения HSM"])},
      "isoAuthConnectionHealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Состояние соединения с  ISO"])},
      "isoTessConnectionHealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Состояние соединения ISO Tess"])},
      "serverHealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Состояние сервера"])},
      "smppConnectionHealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Состояние SMPP-соединения"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Состояние сервисов"])}
    }
  },
  "Products": {
    "modal": {
      "addNewProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить новый продукт"])}
    },
    "table": {
      "ipsCardGroupId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор платежной системы"])},
      "ipsSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Символ платежной системы"])},
      "productId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор продукта"])},
      "productName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название продукта"])},
      "rangeBegin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начальный диапазон"])},
      "rangeEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конечный диапазон"])}
    }
  },
  "ReceiptSendAudits": {
    "table": {
      "receiptNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер чека"])},
      "receiptSendChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал отправки"])},
      "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получатель"])},
      "sendDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
      "successful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
      "transactionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер транзакции"])}
    }
  },
  "RegularReports": {
    "activeTerminals": {
      "table": {
        "dateTimeInit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date time init"])},
        "lastTransactionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата последней транзакции"])},
        "lastUpdateDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата последнего обновления"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])}
      }
    },
    "form": {
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт отчета"])},
      "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период:  "])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите тип отчета: "])},
      "type2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип:"])}
    },
    "lifecycle": {
      "table": {
        "fieldName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя поля"])},
        "newValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новое значение"])},
        "oldValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старое значение"])}
      }
    },
    "select": {
      "export": {
        "CSV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV"])},
        "PDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF"])},
        "XLS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XLS"])}
      },
      "formPeriod": {
        "ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За все время"])},
        "DAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["День"])},
        "MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месяц"])},
        "WEEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неделя"])}
      },
      "type": {
        "PURCHASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PURCHASE"])},
        "REFUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REFUND"])},
        "REVERSAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REVERSAL"])},
        "ZREPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZREPORT"])}
      }
    },
    "terminalsByGroups": {
      "table": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество терминалов"])},
        "numberOfSecondaryTerminals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numberOfSecondaryTerminals"])}
      }
    },
    "text": {
      "toStartGenReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для начала формирования отчета введите: "])}
    },
    "transactionsByMerchant": {
      "table": {
        "refundAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["refundAmount"])}
      }
    },
    "typeReportList": {
      "activeTerminals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активные терминалы"])},
      "merchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество терминалов на предприятии"])},
      "terminalLifecycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчёт об изменениях жизненного цикла терминала"])},
      "terminalRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация терминала"])},
      "terminalsByGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество терминалов по группам"])},
      "transactionsByBlame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчёт о транзакциях по типам операций на предприятии"])},
      "transactionsByIps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчёт о транзакциях в разрезе платёжных систем"])},
      "transactionsByMerchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчёт о транзакциях в разрезе предприятия"])},
      "transactionsByTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчёт о транзакциях в разрезе терминала"])},
      "transactionsByType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчёт о транзакциях по типам операций"])}
    }
  },
  "RegulatoryCommissions": {
    "btn": {
      "makeWiring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выгрузить отчёт"])}
    },
    "form": {
      "messageEng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст сообщения на \"Английском\""])},
      "messageRu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст сообщения на \"Русском\""])},
      "messageUkr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст сообщения на \"Украинском\""])}
    },
    "tab": {
      "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])},
      "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссии"])},
      "enrollments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зачисления "])},
      "generalFinBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая финансовая книга"])},
      "merchants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Торговцы"])},
      "proceduralNotices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки уведомлений"])},
      "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчет о выгрузке транзакций"])}
    },
    "table": {
      "accrual": {
        "merchantDebtAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К зачислению"])},
        "shipmentReportId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID отчёта зачисления"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проведён"])}
      },
      "balance": {
        "ibanPayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN плательщика"])},
        "ibanReceiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN получателя"])},
        "operationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип операции"])},
        "paymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежные реквизиты"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])}
      },
      "commission": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
        "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])}
      },
      "generalFinBook": {
        "dateCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date created"])},
        "incomingTransactionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор входящей транзакции"])}
      },
      "merchant": {
        "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])},
        "creditPeriodValueWithType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кредитный период"])},
        "fcmMessageTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FCM типы сообщений"])},
        "merchantId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant ID"])},
        "overdraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Овердрафт"])}
      },
      "transaction": {
        "bankFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия банка"])},
        "dateTransferred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата проведения"])},
        "fataDebt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма FATA"])},
        "fataFeeRegulatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регламентная комиссия FATA"])},
        "fataFeeTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакционная комиссия FATA"])},
        "isMergeWithBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "isTransferred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проведён"])},
        "merchantDebt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма продавца"])},
        "referencedId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID отчёта"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего"])},
        "totalMinusBankFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма банка FATA"])}
      }
    },
    "text": {
      "searchMerchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск продавца по: "])},
      "typeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["начните вводить текст..."])}
    }
  },
  "Schedule": {
    "btn": {
      "changeStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить статус"])},
      "forcedStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принудительный запуск"])}
    },
    "table": {
      "cron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cron"])},
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
      "lastSuccessRunTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последний запуск"])},
      "nextScheduledDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Следующие время запуска"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])}
    }
  },
  "Settings": {
    "Banks": {
      "table": {
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
        "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сайт банка"])},
        "mfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["МФО"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
        "taxId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОКПО/ИНН"])}
      }
    },
    "Installations": {
      "form": {
        "amountTimeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время для ввода суммы (секунд)"])},
        "appActiveTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время активности приложения (минут)"])},
        "appActiveTimeInMinutesMs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время сессии мобильного приложения (мин)"])},
        "attestationTimeMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Макс. время для случайной аттестации (часы/минуты)"])},
        "attestationTimeMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мин. время для случайной аттестации (часы/минуты)"])},
        "beginCardMask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кол-о цифр карты в начале"])},
        "cardMaskSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немаскируемый текст карты"])},
        "endCardMask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кол-во цифр карты в конце"])},
        "manualTimeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время ожидания Manual-pay (секунд)"])},
        "manualTimeoutMs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время ожидания Manual-pay (миллисекунд)"])},
        "maxReceiptNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальный номер чека"])},
        "minReceiptNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимальный номер чека"])},
        "nfcTimeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время ожидания NFC-pay (секунд)"])},
        "nfcTimeoutInMsMs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время приложения карты для NFC"])},
        "numRefundAttemptsForTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кол-во попыток возврата денег"])},
        "pendingNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ко-во попыток запроса (штук)"])},
        "pendingTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Временный интервал запросов (секунд)"])},
        "phoneMask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблон отображения тел."])},
        "proposalEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронная почта для предложений"])},
        "questionEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронная почта для вопросов"])},
        "receiptHost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблон отображения ссылки на е-чек"])},
        "refundLimitDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кол-во дней для возврата денег"])},
        "supervisorCardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Системная карта"])},
        "technicalMerchantId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Системный мерчант-ID"])},
        "technicalTerminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Системный терминал-ID"])},
        "timeZReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время Z-отчета"])}
      }
    },
    "ReceiptTemplate": {
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактор"])},
      "table": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование"])},
        "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер"])},
        "style": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стили"])},
        "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблон"])}
      },
      "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотр"])}
    },
    "TerminalFacility": {
      "btn": {
        "addFullTariff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить полный тариф"])},
        "addLoyaltyTariff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить тариф лояльности"])},
        "addProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать профиль"])},
        "deleteProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление профиля"])},
        "deleteTariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Tariffs"])},
        "updateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить профиль: "])}
      },
      "modal": {
        "addFullTariff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание полного тарифа"])},
        "addLoyaltyTariff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание тарифа лояльности"])},
        "changeTariff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение тарифа: "])},
        "choiceProfileTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите профиль для перехода"])},
        "choiceTariffByType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите тариф для типа операции"])},
        "deleteId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID удаляемого профиля"])}
      },
      "tab": {
        "tariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тарифы"])},
        "tariffsProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профили тарифа"])}
      },
      "table": {
        "beginDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата начала"])},
        "choiceTariffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#"])},
        "createdDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата создания"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
        "expiredDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания срока"])},
        "fixedFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фиксированная плата"])},
        "groupFacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль"])},
        "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID тарифа"])},
        "limitToApplyPercentageFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимальная комиссия по %"])},
        "loyaltyPeriodType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период лояльности"])},
        "loyaltyPeriodValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значение периода лояльности"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
        "nextFacilityId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тариф перехода"])},
        "noTariffProfileData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для этого профиля, тарифы не выбраны"])},
        "originType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип тарифа"])},
        "percentageFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Процентная комиссия"])},
        "relatedLoyaltyFacilityId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прикрепленный тариф лояльности"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип операции"])}
      }
    },
    "UserRoles": {
      "btn": {
        "addRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить новую роль"])}
      },
      "permissionsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступные разрешения"])},
      "permissionsSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбранные разрешения групы"])},
      "table": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
        "roleAuthorities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбор разрешений"])},
        "roleCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код роли"])},
        "roleTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название роли"])}
      }
    }
  },
  "SetupConfig": {
    "IpsCardGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Група карток IPS"])},
    "Products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продукти"])},
    "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Користувачі"])},
    "UserRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ролі користувача"])},
    "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банк"])},
    "currencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта"])},
    "generalConfiguration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загальна конфігурація"])},
    "serviceGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервіс група"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup Config"])}
  },
  "Terminal": {
    "btn": {
      "addSubTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить подчинённый терминал"])}
    },
    "formSubTerminal": {
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код активации"])}
    },
    "formUpdateTID": {
      "addData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительная информация"])},
      "repeatRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторная регистрация"])},
      "zreportEnabledAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z-отчёт влкючён"])}
    },
    "selectStatus": {
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активный"])},
      "BLOCK_ATTESTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблокировано аттестацией"])},
      "BLOCK_BANK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблокировано банком"])},
      "BLOCK_CLIENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблокировано клиентом"])},
      "BLOCK_MANUAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблокировано менеджером"])},
      "BLOCK_SYSTEM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблокировано системой"])},
      "DELETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удален"])},
      "NOT_READY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не готов"])}
    },
    "table": {
      "bankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банк"])},
      "currencyCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта"])},
      "dateTimeInit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата регистрации"])},
      "deviceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Устройство"])},
      "groupNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер группы"])},
      "lastTransactionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата операции"])},
      "lastUpdateDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата обновления"])},
      "masterTerminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Terminal ID"])},
      "mcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCC"])},
      "merchantId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant ID"])},
      "merchantLegalName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название организации"])},
      "merchantName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование торговца"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
      "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])},
      "terminalLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес установки терминала"])},
      "terminalLocationEng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес установки терминала (анг.)"])},
      "zReportAuto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авто Z-отчет"])}
    }
  },
  "TerminalGroups": {
    "btn": {
      "changeGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить группу"])},
      "changeNumberGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить номер группы"])}
    },
    "modal": {
      "addNewGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить новую группу"])},
      "deleteNumberGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удаление группы"])},
      "editGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить группу: "])}
    },
    "table": {
      "currencyCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта"])},
      "geoPosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Определение геопозиции"])},
      "groupFacilityId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Facility ID"])},
      "groupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование группы"])},
      "groupNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер группы"])},
      "ipsCardGroupIdList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прием карт"])},
      "menuItemList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опции меню приложения"])},
      "noPinLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лимит без ПИНа"])},
      "numberOfSecondaryTerminals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество подчиненных терминалов"])},
      "oneTransactionLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лимит на одну транзакцию"])},
      "opCash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операция Cash"])},
      "opCreateSubordinationTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возможность создания подчиненных терминалов"])},
      "opManual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операция Entry-Mode"])},
      "opNfc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операция Pay-NFC"])},
      "opPinMastercard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ввод пин-кода для Mastercard"])},
      "opPinProstir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ввод пин-кода для Простір"])},
      "opPinVisa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ввод пин-кода для Visa"])},
      "opPinAmex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ввод пин-кода для Amex"])},
      "opPinDiscover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ввод пин-кода для Discover"])},
      "opPurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операции оплаты"])},
      "opQr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операция Pay-QR"])},
      "opRefund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операция возврата"])},
      "opReversal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена операции"])},
      "opTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чаевые "])},
      "productIdList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор продукта"])},
      "productIds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор продукта"])},
      "productName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название продукта"])},
      "receiptSendChannelIdList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каналы отправки чека"])},
      "receiptTemplateId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблон чека"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
      "toGroupNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перенести в группу номер"])},
      "totalAmountLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ограничение общей суммы за день"])},
      "totalCountLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ограничение общего количества транзакций в день"])},
      "totalLimitPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общий период лимита"])},
      "transactionGeoposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Геопозиция транзакции"])},
      "velocityCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счетчик скорости"])},
      "velocityPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период скорости"])},
      "velocityTimeUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Единица времени скорости"])}
    }
  },
  "TerminalKeys": {
    "btn": {
      "keyManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принудительный менеджмент ключей"])},
      "keyManagerAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запуск всех ключей"])}
    },
    "tab": {
      "allPosKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все POS-ключи"])},
      "termKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ключи терминалов"])}
    },
    "table": {
      "checkValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контрольное значение"])},
      "effDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата активации ключа"])},
      "expDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания ключа"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ид-р записи ключа"])},
      "keyType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип ключа"])},
      "keyValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значение ключа под LMK"])},
      "refCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ид-р терминала"])},
      "statCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Состояние"])},
      "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])}
    }
  },
  "TmsKey": {
    "table": {
      "checkValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контрольное значение"])},
      "effDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата активации ключа"])},
      "expDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания ключа"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор записи ключа"])},
      "keyType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип ключа"])},
      "keyValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значение ключа под LMK"])},
      "refCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылочное значение"])},
      "statCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])}
    }
  },
  "Transaction": {
    "table": {
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
      "amountOther": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доп. сумма"])},
      "approvalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код авторизации"])},
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта"])},
      "deviceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Устройство"])},
      "formFactor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форм-фактор"])},
      "geoposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Геолокация"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Язык"])},
      "lastTransactionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Локальная дата транзакции"])},
      "merchantId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant ID"])},
      "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип операции"])},
      "panMasked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маска карты"])},
      "receiptNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер чека"])},
      "responseCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код ответа"])},
      "responseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата ответа"])},
      "rrn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RRN"])},
      "statusCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
      "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID терминала"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакции"])},
      "transactionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата транзакции"])},
      "transactionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер транзакции"])}
    }
  },
  "TrnReportsSettlement": {
    "TerminalTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчет по терминалам"])},
    "TransactionTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчет по транзакциям"])},
    "amountAllTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма всех транзакций"])},
    "amountBankCommission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount bank commission"])},
    "amountPinTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма Pin транзакций"])},
    "amountPurchaseTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество Purchase транзакций"])},
    "amountRefundTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма Refund транзакций"])},
    "amountReversalTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма Reversal транзакций"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор расчета"])},
    "initDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата создания"])},
    "quantityActiveTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество активных терминалов"])},
    "quantityAllTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество всех терминалов"])},
    "quantityAllTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество всех транзакций"])},
    "quantityDeclinedTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["количество отклоненных транзакций"])},
    "quantityInactiveTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество неактивных терминалов"])},
    "quantityLoginTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество залогиненных терминалов"])},
    "quantityPinTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество ПИН транзакций"])},
    "quantityReactivateTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество повторно активированных терминалов"])},
    "quantityRefundTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество Refund транзакций"])},
    "quantityRegisterFullTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество полностю зарегистрированых терминалов"])},
    "quantityRegisterWaitTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество зарегистрированных терминалов в ожидании"])},
    "quantityReversalTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество Reversal транзакций"])},
    "quantitySuccessTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество успешных транзакций"])},
    "quantityTurnActiveTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество Turn активных терминалов"])},
    "quantityTurnInactiveTerminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество возвращенных неактивных терминалов"])}
  },
  "Users": {
    "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрировать пользователя"])},
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить пароль"])},
    "table": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
      "userRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логин"])}
    }
  },
  "Validations": {
    "checkSendChannelRequiredList": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Выберите хотя бы один обязательный канал отправки чеков: ", _interpolate(_named("chList"))])},
    "isForbidToChangePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль истек, обратитесь к администратору для изменения."])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Максимально допустимая длина  символов: ", _interpolate(_named("num"))])},
    "maxValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Максимально допустимое значение: ", _interpolate(_named("num"))])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Минимально допустимая длина  символов: ", _interpolate(_named("num"))])},
    "minValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Минимально допустимое значение: ", _interpolate(_named("num"))])},
    "notChosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не выбрано"])},
    "onlyLettersNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допустимы только буквы и цифры латинского алфавита"])},
    "onlyNumbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возможен ввод только цифр."])},
    "passRule1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Требования к паролю:<br>  Минимум одна цифра.<br> Минимум одна заглавная буква.<br> Минимум один специальный символ(", _interpolate(_named("specChar")), ").<br> Без пробелов или символов пробела.<br> Минимум 8 символов.<br>"])},
    "passRule2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество одинаковых повторений символов ограничено до 3 подряд."])},
    "passwordExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль устарел, введите новый."])},
    "passwordExpiresSoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок действия пароля скоро истечет, измените пароль."])},
    "passwordRepeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите пароль повторно"])},
    "reEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введен некорректный электронный адрес"])},
    "reNumAndDot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешены для ввода только цифры и точки"])},
    "reTextAndNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введены не разрешенные символы. Доступны для ввода только буквы и цифры."])},
    "reTextAndNumDot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введены не разрешенные символы. Доступны для ввода только буквы и цифры и (\",\" \".\" \"-\")."])},
    "reTextLatin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешено вводить только символы латиницы"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле обязательно для заполнения"])},
    "userPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите пароль"])}
  },
  "VendorRSO": {
    "table": {
      "addVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавление нового"])},
      "expiredDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месяц"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
      "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проект"])},
      "shortName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сокращенное название"])},
      "vendorCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код поставщика"])},
      "vendorId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Версия"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Год"])}
    }
  },
  "Version": {
    "table": {
      "expDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
      "updateDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата регистрации"])},
      "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Версия"])}
    }
  },
  "ZReportTransactions": {
    "table": {
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
      "approvalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код авторизации"])},
      "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество"])},
      "localTransactionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Локальная Дата/Время"])},
      "merchantName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant ID"])},
      "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип операции"])},
      "responseCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код ответа"])},
      "responseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время ответа"])},
      "responseMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст ответа"])},
      "rrn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RRN"])},
      "statusCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
      "terminalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminal ID"])},
      "transactionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата/Время"])}
    }
  },
  "lang": {
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Русский"])},
    "uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Українська"])}
  },
  "left-menu": {
    "analytics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аналитика"])},
    "attestationHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История аттестаций"])},
    "businessDayCalculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расчет бизнес дня"])},
    "chatHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История чата"])},
    "devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Устройства"])},
    "investigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расследование"])},
    "merchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Организации"])},
    "merchantRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация торговца"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])},
    "monitoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мониторинг"])},
    "receiptSendAudit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросы чеков"])},
    "regularReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регламентные отчеты"])},
    "regulatoryCommissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Финансовая компания"])},
    "reportsSettlement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расчеты с банком"])},
    "serviceGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группы терминалов"])},
    "terminal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Терминалы"])},
    "terminalKeys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ключи терминалов"])},
    "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакции"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи"])},
    "zReportTransactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z-Отчеты"])}
  },
  "settings-menu": {
    "VendorRSO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вендор РРО"])},
    "attestation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аттестационные параметры"])},
    "banks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банки"])},
    "changeAudit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аудит изменений"])},
    "entityAudit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменения в системе"])},
    "installations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установки"])},
    "ipsCardGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежные системы"])},
    "ipsKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ключи платежной системы "])},
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продукты"])},
    "receiptTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблон чека"])},
    "schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расписание"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка системы"])},
    "terminalFacility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тарифы"])},
    "tmsKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ключи системы"])},
    "userRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роли"])},
    "vendorRSO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вендор РРО"])},
    "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Версии"])}
  },
  "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["test"])},
  "uiElements": {
    "btn": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])},
      "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применить"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
      "deleteAllFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить все файлы"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить"])},
      "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтры"])},
      "resetFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить фильтры"])},
      "run": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запустить"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])},
      "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])}
    },
    "tableUpload": {
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действие"])},
      "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя файла"])},
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размер"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])}
    },
    "text": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])},
      "columnName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название колонки"])},
      "countItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество записей"])},
      "currencyCode": {
        "0980": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["грн."])}
      },
      "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отключить"])},
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включено"])},
      "listEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список пуст"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логин"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
      "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет данных"])},
      "noDataSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За данным поиском нет результатов"])},
      "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["из"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
      "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать"])},
      "signInPanel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход в кабинет"])},
      "tableColumnSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка вида таблицы"])},
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])}
    },
    "toast": {
      "err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "errSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка сохранения"])},
      "sendSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно отправлено"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно"])},
      "successDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно удалено"])},
      "successSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно сохранено"])}
    }
  }
}