<template>
  <form id="threats_form" @submit.prevent="submitForm"></form>
  <div class="row">
    <div class="col-6">
      <div class="table-responsive table-block overflow-auto" id="table-block">
        <table class="table table-sm table-light table-striped">
          <thead>
            <tr class="text-center">
              <th scope="col">
                {{ $t(`Attestation.threat.table.threat`) }}
              </th>
              <th scope="col">
                {{ $t(`Attestation.threat.table.action`) }}
              </th>
            </tr>
          </thead>
          <tbody class="text-nowrap">
            <tr class="">
              <td>{{ $t(`Attestation.threat.table.DEBUG`) }}</td>
              <td>
                <select
                  class="form-select"
                  id="input-DEBUG"
                  v-model="formData.DEBUG"
                >
                  <option
                    v-for="item in actionOption"
                    :key="item"
                    :value="item.value"
                    :disabled="item.disabled"
                  >
                    {{ $t(item.text) }}
                  </option>
                </select>
              </td>
            </tr>
            <tr class="">
              <td>{{ $t(`Attestation.threat.table.INTEGRITY`) }}</td>
              <td>
                <select
                  class="form-select"
                  id="input-INTEGRITY"
                  v-model="formData.INTEGRITY"
                >
                  <option
                    v-for="item in actionOption"
                    :key="item"
                    :value="item.value"
                    :disabled="item.disabled"
                  >
                    {{ $t(item.text) }}
                  </option>
                </select>
              </td>
            </tr>
            <tr class="">
              <td>{{ $t(`Attestation.threat.table.EMULATOR`) }}</td>
              <td>
                <select
                  class="form-select"
                  id="input-EMULATOR"
                  v-model="formData.EMULATOR"
                >
                  <option
                    v-for="item in actionOption"
                    :key="item"
                    :value="item.value"
                    :disabled="item.disabled"
                  >
                    {{ $t(item.text) }}
                  </option>
                </select>
              </td>
            </tr>
            <tr class="">
              <td>{{ $t(`Attestation.threat.table.ROOT`) }}</td>
              <td>
                <select
                  class="form-select"
                  id="input-ROOT"
                  v-model="formData.ROOT"
                >
                  <option
                    v-for="item in actionOption"
                    :key="item"
                    :value="item.value"
                    :disabled="item.disabled"
                  >
                    {{ $t(item.text) }}
                  </option>
                </select>
              </td>
            </tr>
            <tr class="">
              <td>{{ $t(`Attestation.threat.table.VELOCITY_CHECK`) }}</td>
              <td>
                <select
                  class="form-select"
                  id="input-VELOCITY_CHECK"
                  v-model="formData.VELOCITY_CHECK"
                >
                  <option
                    v-for="item in actionOption"
                    :key="item"
                    :value="item.value"
                    :disabled="item.disabled"
                  >
                    {{ $t(item.text) }}
                  </option>
                </select>
              </td>
            </tr>
            <tr class="">
              <td>{{ $t(`Attestation.threat.table.CHANNEL_INTEGRITY`) }}</td>
              <td>
                <select
                  class="form-select"
                  id="input-CHANNEL_INTEGRITY"
                  v-model="formData.CHANNEL_INTEGRITY"
                >
                  <option
                    v-for="item in actionOption"
                    :key="item"
                    :value="item.value"
                    :disabled="item.disabled"
                  >
                    {{ $t(item.text) }}
                  </option>
                </select>
              </td>
            </tr>
            <tr class="">
              <td>{{ $t(`Attestation.threat.table.GEO_POSITION`) }}</td>
              <td>
                <select
                  class="form-select"
                  id="input-GEO_POSITION"
                  v-model="formData.GEO_POSITION"
                >
                  <option
                    v-for="item in actionOption"
                    :key="item"
                    :value="item.value"
                    :disabled="item.disabled"
                  >
                    {{ $t(item.text) }}
                  </option>
                </select>
              </td>
            </tr>
            <tr class="">
              <td>{{ $t(`Attestation.threat.table.INSTALL_SOURCE`) }}</td>
              <td>
                <select
                  class="form-select"
                  id="input-INSTALL_SOURCE"
                  v-model="formData.INSTALL_SOURCE"
                >
                  <option
                    v-for="item in actionOption"
                    :key="item"
                    :value="item.value"
                    :disabled="item.disabled"
                  >
                    {{ $t(item.text) }}
                  </option>
                </select>
              </td>
            </tr>
            <tr class="">
              <td>{{ $t(`Attestation.threat.table.KEY_ATTESTATION`) }}</td>
              <td>
                <select
                  class="form-select"
                  id="input-KEY_ATTESTATION"
                  v-model="formData.KEY_ATTESTATION"
                >
                  <option
                    v-for="item in actionOption"
                    :key="item"
                    :value="item.value"
                    :disabled="item.disabled"
                  >
                    {{ $t(item.text) }}
                  </option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 text-center">
      <button form="threats_form" class="btn btn-success">
        {{ $t(`uiElements.btn.save`) }}
      </button>
    </div>
  </div>
  <div
    class="toast-container position-absolute p-3 top-0 end-0"
    v-if="showToast"
  >
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import BsToast from "@/components/UI-elements/BsToast";
import attestationThreatsApi from "@/api/settings-api/attestation";

export default {
  name: "TrnAttestationThreatTab",
  components: {
    BsToast,
  },
  data() {
    return {
      isLoading: true,
      showToast: false,
      toastPropsList: [],
      formData: {
        DEBUG: null,
        INTEGRITY: null,
        EMULATOR: null,
        ROOT: null,
        VELOCITY_CHECK: null,
        CHANNEL_INTEGRITY: null,
        GEO_POSITION: null,
        INSTALL_SOURCE: null,
        KEY_ATTESTATION: null,
      },
      actionOption: [
        { disabled: true, value: null, text: "Validations.notChosen" },
        {
          disabled: false,
          value: "BLOCK_MANUAL",
          text: "Attestation.action.select.option.BLOCK_MANUAL",
        },
        {
          disabled: false,
          value: "BLOCK_NFC",
          text: "Attestation.action.select.option.BLOCK_NFC",
        },
        {
          disabled: false,
          value: "NO_BLOCK",
          text: "Attestation.action.select.option.NO_BLOCK",
        },
        {
          disabled: false,
          value: "BLOCK_PIN",
          text: "Attestation.action.select.option.BLOCK_PIN",
        },
        {
          disabled: false,
          value: "BLOCK_QR",
          text: "Attestation.action.select.option.BLOCK_QR",
        },
        {
          disabled: false,
          value: "BLOCK_DEVICE",
          text: "Attestation.action.select.option.BLOCK_DEVICE",
        },
        {
          disabled: false,
          value: "BLOCK_TRANSACTION",
          text: "Attestation.action.select.option.BLOCK_TRANSACTION",
        },
      ],
    };
  },
  methods: {
    fetchData() {
      attestationThreatsApi
        .getAttestationThreats()
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          // console.log("result", result);
          if (result.status === 200) {
            for (let item in result.data) {
              this.formData[result.data[item].threatName] =
                result.data[item].defaultAction.action;
            }
          } else {
            console.log("err");
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.err`)}`,
              bgColor: "bg-danger",
              textColor: "text-white",
            });
            this.showToast = true;
          }
        });
    },
    submitForm() {
      let updateArr = [];
      for (let key in this.formData) {
        updateArr.push({
          threatId: key,
          defaultActionId: this.formData[key],
        });
      }

      attestationThreatsApi
        .updateAttestationThreats(updateArr)
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          // console.log("result", result);
          if (result.status === 200) {
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.successSave`)}`,
              bgColor: "bg-success",
              textColor: "text-white",
            });
            this.showToast = true;
          } else {
            console.log("err");
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.err`)}`,
              bgColor: "bg-danger",
              textColor: "text-white",
            });
            this.showToast = true;
          }
        });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped></style>
