import axios from "@/api/axios";

const getVendorRSO = (apiParams) => {
  return axios.get("/vendor?" + apiParams);
};

const updateVendorRSO = (reqObj) => {
  return axios.put("/vendor", {
    ...reqObj,
  });
};

const addVendorRSO = (reqObj) => {
  return axios.post("/vendor", {
    ...reqObj,
  });
};

export default {
  getVendorRSO,
  updateVendorRSO,
  addVendorRSO,
};
