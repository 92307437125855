<template>
  <div class="title-table mb-2 ps-3 py-1 fw-bold">
    {{ $t(`left-menu.terminal`) }}
  </div>
  <!-- Фільтр -->
  <div class="toolbar">
    <div class="btn-group mb-2">
      <button
        class="btn btn-outline-primary btn-sm"
        type="button"
        @click="showAddSubTerminal()"
      >
        <i class="bi bi-file-earmark-plus"></i>
        <span class="ms-1">{{ $t(`Terminal.btn.addSubTerminal`) }}</span>
      </button>
      <button
        class="btn btn-outline-primary btn-sm"
        type="button"
        @click="showKeyManagerForm"
      >
        <i class="bi bi-filetype-key me-2"></i>
        {{ $t(`TerminalKeys.btn.keyManager`) }}
      </button>
      <button
        class="btn btn-outline-primary btn-sm dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
        aria-expanded="false"
        ref="dropdownBtnFilter"
      >
        <i class="bi bi-filter-square me-2"></i>
        <span>{{ $t(`uiElements.btn.filters`) }}</span>
      </button>
      <button
        class="btn btn-outline-primary btn-sm"
        type="button"
        @click="showModalViewTableSettings"
        title="Налаштування вигляду таблиці"
      >
        <i class="bi bi-table"></i>
      </button>
      <form
        class="dropdown-menu p-4"
        style="width: 500px"
        @submit.prevent="formFilterRm"
        ref="dropdownFormFilter"
      >
        <div class="row mb-3">
          <label
            for="terminalId-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`Terminal.table.terminalId`) }}</label
          >
          <div class="col-sm-8">
            <input
              v-model.trim="filters.terminalId"
              type="text"
              class="form-control form-control-sm"
              id="terminalId-ff"
              :placeholder="$t(`Validations.notChosen`)"
            />
          </div>
        </div>

        <div class="row mb-3">
          <label
            for="merchantId-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`Terminal.table.merchantId`) }}</label
          >
          <div class="col-sm-8">
            <input
              v-model.trim="filters.merchantId"
              type="text"
              class="form-control form-control-sm"
              id="merchantId-ff"
              :placeholder="$t(`Validations.notChosen`)"
            />
          </div>
        </div>

        <div class="row mb-3">
          <label
            for="groupNumber-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`Terminal.table.groupNumber`) }}</label
          >
          <div class="col-sm-8">
            <input
              v-model.trim="filters.groupNumber"
              type="text"
              class="form-control form-control-sm"
              id="groupNumber-ff"
              :placeholder="$t(`Validations.notChosen`)"
            />
          </div>
        </div>

        <div class="row mb-3">
          <label
            for="dateTimeInit-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`Terminal.table.dateTimeInit`) }}</label
          >
          <div class="col-sm-8">
            <Datepicker
              id="dateTimeInit-ff"
              v-model="dateTimeInit"
              autoApply
              locale="uk"
              :format="dateFormat"
              :previewFormat="dateFormat"
            />
          </div>
        </div>

        <div class="row mb-3">
          <label
            for="merchantName-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`Terminal.table.merchantName`) }}</label
          >
          <div class="col-sm-8">
            <input
              v-model.trim="filters.merchantName"
              type="text"
              class="form-control form-control-sm"
              id="merchantName-ff"
              :placeholder="$t(`Validations.notChosen`)"
            />
          </div>
        </div>

        <div class="row mb-3">
          <label
            for="merchantLegalName-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`Terminal.table.merchantLegalName`) }}</label
          >
          <div class="col-sm-8">
            <input
              v-model.trim="filters.merchantLegalName"
              type="text"
              class="form-control form-control-sm"
              id="merchantLegalName-ff"
              :placeholder="$t(`Validations.notChosen`)"
            />
          </div>
        </div>

        <div class="row mb-3">
          <label
            for="merchantLegalName-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`Terminal.table.masterTerminalId`) }}</label
          >
          <div class="col-sm-8">
            <input
              v-model.trim="filters.masterTerminalId"
              type="text"
              class="form-control form-control-sm"
              id="merchantLegalName-ff"
              :placeholder="$t(`Validations.notChosen`)"
            />
          </div>
        </div>

        <div class="row mb-3">
          <label
            for="status-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`Terminal.table.status`) }}</label
          >
          <div class="col-sm-8">
            <select
              class="form-select form-select-sm"
              id="status-ff"
              v-model.trim="filters.status"
            >
              <option value="" selected>
                {{ $t(`Validations.notChosen`) }}
              </option>
              <option value="ACTIVE">
                {{ $t(`Terminal.selectStatus.ACTIVE`) }}
              </option>
              <option value="BLOCK_SYSTEM">
                {{ $t(`Terminal.selectStatus.BLOCK_SYSTEM`) }}
              </option>
              <option value="BLOCK_ATTESTATION">
                {{ $t(`Terminal.selectStatus.BLOCK_ATTESTATION`) }}
              </option>
              <option value="BLOCK_BANK">
                {{ $t(`Terminal.selectStatus.BLOCK_BANK`) }}
              </option>
              <option value="BLOCK_MANUAL">
                {{ $t(`Terminal.selectStatus.BLOCK_MANUAL`) }}
              </option>
              <option value="BLOCK_CLIENT">
                {{ $t(`Terminal.selectStatus.BLOCK_CLIENT`) }}
              </option>
              <option value="NOT_READY">
                {{ $t(`Terminal.selectStatus.NOT_READY`) }}
              </option>
              <option value="DELETED">
                {{ $t(`Terminal.selectStatus.DELETED`) }}
              </option>
            </select>
          </div>
        </div>

        <div class="justify-content-end d-flex">
          <button
            class="btn btn-outline-primary btn-sm me-3"
            type="button"
            @click="resetFilterForm"
          >
            <i class="bi bi-x-square me-1"></i>
            <span>{{ $t(`uiElements.btn.resetFilters`) }}</span>
          </button>
          <button type="submit" class="btn btn-primary btn-sm">
            <i class="bi bi-clipboard-check me-1"></i>
            <span>{{ $t(`uiElements.btn.apply`) }}</span>
          </button>
        </div>
      </form>
    </div>
  </div>

  <!-- Фільтр кінець -->

  <div
    class="table-responsive table-block overflow-auto"
    id="table-block"
    @scroll="scrollTable"
  >
    <div class="row g-0">
      <div class="col-auto">
        <table class="table table-striped table-hover table-sm table-light">
          <thead>
            <tr class="text-center">
              <th
                scope="col"
                data-col-sort="terminalId"
                v-show="
                  columnList.find((item) => item.key === 'terminalId').visible
                "
                @click="clickSortCol($event, 'terminalId')"
              >
                {{ $t(`Terminal.table.terminalId`) }}
                <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
              </th>

              <th
                scope="col"
                data-col-sort="groupNumber"
                v-show="
                  columnList.find((item) => item.key === 'groupNumber').visible
                "
                @click="clickSortCol($event, 'groupNumber')"
              >
                {{ $t(`Terminal.table.groupNumber`) }}
                <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
              </th>

              <th
                scope="col"
                data-col-sort="dateTimeInit"
                v-show="
                  columnList.find((item) => item.key === 'dateTimeInit').visible
                "
                @click="clickSortCol($event, 'dateTimeInit')"
              >
                {{ $t(`Terminal.table.dateTimeInit`) }}
                <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
              </th>

              <th
                scope="col"
                data-col-sort="merchant-merchantId"
                v-show="
                  columnList.find((item) => item.key === 'merchantId').visible
                "
                @click="clickSortCol($event, 'merchant.merchantId')"
              >
                {{ $t(`Terminal.table.merchantId`) }}
                <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
              </th>

              <th
                scope="col"
                data-col-sort="merchant-merchantName"
                v-show="
                  columnList.find((item) => item.key === 'merchantName').visible
                "
                @click="clickSortCol($event, 'merchant.merchantName')"
              >
                {{ $t(`Terminal.table.merchantName`) }}
                <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
              </th>

              <th
                scope="col"
                data-col-sort="merchant-merchantLegalName"
                v-show="
                  columnList.find((item) => item.key === 'merchantLegalName')
                    .visible
                "
                @click="clickSortCol($event, 'merchant.merchantLegalName')"
              >
                {{ $t(`Terminal.table.merchantLegalName`) }}
                <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
              </th>

              <th
                scope="col"
                v-show="
                  columnList.find((item) => item.key === 'bankName').visible
                "
              >
                {{ $t(`Terminal.table.bankName`) }}
                <div class="icon-sort"><i class="bi bi-app"></i></div>
              </th>

              <th
                scope="col"
                v-show="
                  columnList.find((item) => item.key === 'currencyCode').visible
                "
              >
                {{ $t(`Terminal.table.currencyCode`) }}
                <div class="icon-sort"><i class="bi bi-app"></i></div>
              </th>

              <th
                scope="col"
                v-show="
                  columnList.find((item) => item.key === 'deviceName').visible
                "
              >
                {{ $t(`Terminal.table.deviceName`) }}
                <div class="icon-sort"><i class="bi bi-app"></i></div>
              </th>

              <th
                scope="col"
                v-show="
                  columnList.find((item) => item.key === 'zReportAuto').visible
                "
              >
                {{ $t(`Terminal.table.zReportAuto`) }}
                <div class="icon-sort"><i class="bi bi-app"></i></div>
              </th>

              <th
                scope="col"
                v-show="
                  columnList.find((item) => item.key === 'lastTransactionDate')
                    .visible
                "
              >
                {{ $t(`Terminal.table.lastTransactionDate`) }}
                <div class="icon-sort"><i class="bi bi-app"></i></div>
              </th>

              <th
                scope="col"
                v-show="
                  columnList.find((item) => item.key === 'lastUpdateDate')
                    .visible
                "
              >
                {{ $t(`Terminal.table.lastUpdateDate`) }}
                <div class="icon-sort"><i class="bi bi-app"></i></div>
              </th>

              <th
                scope="col"
                v-show="
                  columnList.find((item) => item.key === 'status').visible
                "
              >
                {{ $t(`Terminal.table.status`) }}
                <div class="icon-sort"><i class="bi bi-app"></i></div>
              </th>

              <th
                scope="col"
                v-show="
                  columnList.find((item) => item.key === 'masterTerminalId')
                    .visible
                "
              >
                {{ $t(`Terminal.table.masterTerminalId`) }}
                <div class="icon-sort"><i class="bi bi-app"></i></div>
              </th>

              <th
                scope="col"
                v-show="
                  columnList.find((item) => item.key === 'terminalLocation')
                    .visible
                "
              >
                {{ $t(`Terminal.table.terminalLocation`) }}
                <div class="icon-sort"><i class="bi bi-app"></i></div>
              </th>

              <th
                scope="col"
                v-show="
                  columnList.find((item) => item.key === 'terminalLocationEng')
                    .visible
                "
              >
                {{ $t(`Terminal.table.terminalLocationEng`) }}
                <div class="icon-sort"><i class="bi bi-app"></i></div>
              </th>

              <th
                scope="col"
                v-show="columnList.find((item) => item.key === 'taxId').visible"
              >
                {{ $t(`MerchantRegistration.table.taxId`) }}
                <div class="icon-sort"><i class="bi bi-app"></i></div>
              </th>

              <th
                scope="col"
                v-show="columnList.find((item) => item.key === 'mcc').visible"
              >
                {{ $t(`Terminal.table.mcc`) }}
                <div class="icon-sort"><i class="bi bi-app"></i></div>
              </th>

              <th
                scope="col"
                v-show="
                  columnList.find((item) => item.key === 'totalAmountLimit')
                    .visible
                "
              >
                {{ $t(`TerminalGroups.table.totalAmountLimit`) }}
                <div class="icon-sort"><i class="bi bi-app"></i></div>
              </th>

              <th
                scope="col"
                v-show="
                  columnList.find((item) => item.key === 'totalCountLimit')
                    .visible
                "
              >
                {{ $t(`TerminalGroups.table.totalCountLimit`) }}
                <div class="icon-sort"><i class="bi bi-app"></i></div>
              </th>
            </tr>
          </thead>
          <tbody class="text-nowrap">
            <template v-if="isLoading">
              <tr>
                <td colspan="16">
                  <div class="loading text-center">
                    <trn-loading></trn-loading>
                  </div>
                </td>
              </tr>
            </template>
            <template v-if="dataResp">
              <tr
                v-for="item in dataResp.page.content"
                :key="item"
                class="text-center cursor-pointer"
                @click="showUpdateTIDForm(item)"
              >
                <td
                  v-show="
                    columnList.find((item) => item.key === 'terminalId').visible
                  "
                >
                  {{ item.terminalId }}
                </td>
                <td
                  v-show="
                    columnList.find((item) => item.key === 'groupNumber')
                      .visible
                  "
                >
                  {{ item.groupNumber }}
                </td>
                <td
                  v-show="
                    columnList.find((item) => item.key === 'dateTimeInit')
                      .visible
                  "
                >
                  {{ $filters.formatDateTime(item.dateTimeInit) }}
                </td>
                <td
                  v-show="
                    columnList.find((item) => item.key === 'merchantId').visible
                  "
                >
                  {{ item.merchant.merchantId }}
                </td>
                <td
                  v-show="
                    columnList.find((item) => item.key === 'merchantName')
                      .visible
                  "
                >
                  {{ item.merchant.merchantName }}
                </td>
                <td
                  v-show="
                    columnList.find((item) => item.key === 'merchantLegalName')
                      .visible
                  "
                >
                  {{ item.merchant.merchantLegalName }}
                </td>
                <td
                  v-show="
                    columnList.find((item) => item.key === 'bankName').visible
                  "
                >
                  {{ item.merchant.bank.name }}
                </td>
                <td
                  v-show="
                    columnList.find((item) => item.key === 'currencyCode')
                      .visible
                  "
                >
                  {{ item.currency.letterCode }}
                </td>
                <td
                  v-show="
                    columnList.find((item) => item.key === 'deviceName').visible
                  "
                >
                  {{ item?.device?.deviceName ? item.device.deviceName : "" }}
                </td>
                <td
                  v-show="
                    columnList.find((item) => item.key === 'zReportAuto')
                      .visible
                  "
                >
                  {{ item.zreportMondayEnabled }}
                </td>
                <td
                  v-show="
                    columnList.find(
                      (item) => item.key === 'lastTransactionDate'
                    ).visible
                  "
                >
                  {{ $filters.formatDateTime(item.lastTransactionDate) }}
                </td>
                <td
                  v-show="
                    columnList.find((item) => item.key === 'lastUpdateDate')
                      .visible
                  "
                >
                  {{ $filters.formatDateTime(item.lastUpdateDate) }}
                </td>
                <td
                  v-show="
                    columnList.find((item) => item.key === 'status').visible
                  "
                >
                  {{ item.status }}
                </td>
                <td
                  v-show="
                    columnList.find((item) => item.key === 'masterTerminalId')
                      .visible
                  "
                >
                  {{ item.masterTerminalId }}
                </td>
                <td
                  v-show="
                    columnList.find((item) => item.key === 'terminalLocation')
                      .visible
                  "
                >
                  {{ item.terminalLocation }}
                </td>
                <td
                  v-show="
                    columnList.find(
                      (item) => item.key === 'terminalLocationEng'
                    ).visible
                  "
                >
                  {{ item.terminalLocationEng }}
                </td>

                <td
                  v-show="
                    columnList.find((item) => item.key === 'taxId').visible
                  "
                >
                  {{ item.merchant.taxId }}
                </td>
                <td
                  v-show="columnList.find((item) => item.key === 'mcc').visible"
                >
                  {{ item.merchant.mcc }}
                </td>
                <td
                  v-show="
                    columnList.find((item) => item.key === 'totalAmountLimit')
                      .visible
                  "
                >
                  {{ item.totalAmountLimit }}
                </td>
                <td
                  v-show="
                    columnList.find((item) => item.key === 'totalCountLimit')
                      .visible
                  "
                >
                  {{ item.totalCountLimit }}
                </td>
              </tr>
              <template v-if="isLoadingScroll">
                <tr>
                  <td colspan="16">
                    <div class="loading text-center">
                      <trn-loading></trn-loading>
                    </div>
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <bs-modal
    :modal-toggle-emit="showModal"
    :title="titleModal"
    @hideMainModal="showModal = false"
  >
    <template v-if="formType === 'formUpdateTID'">
      <form class="needs-validation" @submit.prevent="formUpdateTID" novalidate>
        <!--  input-status  -->
        <div class="row mb-3">
          <label for="input-status" class="col-sm-6 col-form-label">
            {{ $t(`Terminal.table.status`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              id="input-status"
              v-model="v$.formTIDUpdate.status.$model"
            >
              <option value="ACTIVE">
                {{ $t(`Terminal.selectStatus.ACTIVE`) }}
              </option>
              <option value="BLOCK_SYSTEM">
                {{ $t(`Terminal.selectStatus.BLOCK_SYSTEM`) }}
              </option>
              <option value="BLOCK_ATTESTATION">
                {{ $t(`Terminal.selectStatus.BLOCK_ATTESTATION`) }}
              </option>
              <option value="BLOCK_BANK">
                {{ $t(`Terminal.selectStatus.BLOCK_BANK`) }}
              </option>
              <option value="BLOCK_MANUAL">
                {{ $t(`Terminal.selectStatus.BLOCK_MANUAL`) }}
              </option>
              <option value="BLOCK_CLIENT">
                {{ $t(`Terminal.selectStatus.BLOCK_CLIENT`) }}
              </option>
              <option value="NOT_READY">
                {{ $t(`Terminal.selectStatus.NOT_READY`) }}
              </option>
              <option value="DELETED">
                {{ $t(`Terminal.selectStatus.DELETED`) }}
              </option>
            </select>
            <div class="invalid-feedback">
              <div v-if="v$.formTIDUpdate.status.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-repeatRegistration  -->
        <div class="row mb-3">
          <label for="input-repeatRegistration" class="col-sm-6 col-form-label">
            {{ $t(`Terminal.formUpdateTID.repeatRegistration`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              id="input-repeatRegistration"
              v-model="v$.formTIDUpdate.repeatRegistration.$model"
            >
              <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
              <option value="N">{{ $t(`uiElements.text.no`) }}</option>
            </select>
            <div class="invalid-feedback">
              <div v-if="v$.formTIDUpdate.repeatRegistration.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-opPurchase  -->
        <div class="row mb-3">
          <label for="input-opPurchase" class="col-sm-6 col-form-label">
            {{ $t(`TerminalGroups.table.opPurchase`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('opPurchase')"
              id="input-opPurchase"
              v-model="v$.formTIDUpdate.opPurchase.$model"
            >
              <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
              <option value="N">{{ $t(`uiElements.text.no`) }}</option>
            </select>
            <div class="invalid-feedback">
              <div v-if="v$.formTIDUpdate.opPurchase.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-opManual  -->
        <div class="row mb-3">
          <label for="input-opManual" class="col-sm-6 col-form-label">
            {{ $t(`TerminalGroups.table.opManual`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('opManual')"
              id="input-opManual"
              v-model="v$.formTIDUpdate.opManual.$model"
            >
              <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
              <option value="N">{{ $t(`uiElements.text.no`) }}</option>
            </select>
            <div class="invalid-feedback">
              <div v-if="v$.formTIDUpdate.opManual.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-opCash  -->
        <div class="row mb-3">
          <label for="input-opCash" class="col-sm-6 col-form-label">
            {{ $t(`TerminalGroups.table.opCash`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('opCash')"
              id="input-opCash"
              v-model="v$.formTIDUpdate.opCash.$model"
            >
              <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
              <option value="N">{{ $t(`uiElements.text.no`) }}</option>
            </select>
            <div class="invalid-feedback">
              <div v-if="v$.formTIDUpdate.opCash.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-opReversal  -->
        <div class="row mb-3">
          <label for="input-opReversal" class="col-sm-6 col-form-label">
            {{ $t(`TerminalGroups.table.opReversal`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('opReversal')"
              id="input-opReversal"
              v-model="v$.formTIDUpdate.opReversal.$model"
            >
              <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
              <option value="N">{{ $t(`uiElements.text.no`) }}</option>
            </select>
            <div class="invalid-feedback">
              <div v-if="v$.formTIDUpdate.opReversal.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-opRefund  -->
        <div class="row mb-3">
          <label for="input-opRefund" class="col-sm-6 col-form-label">
            {{ $t(`TerminalGroups.table.opRefund`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('opRefund')"
              id="input-opRefund"
              v-model="v$.formTIDUpdate.opRefund.$model"
            >
              <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
              <option value="N">{{ $t(`uiElements.text.no`) }}</option>
            </select>
            <div class="invalid-feedback">
              <div v-if="v$.formTIDUpdate.opRefund.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-opPinMastercard  -->
        <div class="row mb-3">
          <label for="input-opPinMastercard" class="col-sm-6 col-form-label">
            {{ $t(`TerminalGroups.table.opPinMastercard`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('opPinMasterCard')"
              id="input-opPinMastercard"
              v-model="v$.formTIDUpdate.opPinMasterCard.$model"
            >
              <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
              <option value="N">{{ $t(`uiElements.text.no`) }}</option>
            </select>
            <div class="invalid-feedback">
              <div v-if="v$.formTIDUpdate.opPinMasterCard.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-opPinVisa  -->
        <div class="row mb-3">
          <label for="input-opPinVisa" class="col-sm-6 col-form-label">
            {{ $t(`TerminalGroups.table.opPinVisa`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('opPinVisa')"
              id="input-opPinVisa"
              v-model="v$.formTIDUpdate.opPinVisa.$model"
            >
              <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
              <option value="N">{{ $t(`uiElements.text.no`) }}</option>
            </select>
            <div class="invalid-feedback">
              <div v-if="v$.formTIDUpdate.opPinVisa.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-opPinProstir  -->
        <div class="row mb-3">
          <label for="input-opPinProstir" class="col-sm-6 col-form-label">
            {{ $t(`TerminalGroups.table.opPinProstir`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('opPinProstir')"
              id="input-opPinProstir"
              v-model="v$.formTIDUpdate.opPinProstir.$model"
            >
              <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
              <option value="N">{{ $t(`uiElements.text.no`) }}</option>
            </select>
            <div class="invalid-feedback">
              <div v-if="v$.formTIDUpdate.opPinProstir.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-opPinAmex  -->
        <div class="row mb-3">
          <label for="input-opPinAmex" class="col-sm-6 col-form-label">
            {{ $t(`TerminalGroups.table.opPinAmex`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
                class="form-select"
                :class="addClassValidInput('opPinAmex')"
                id="input-opPinAmex"
                v-model="v$.formTIDUpdate.opPinAmex.$model"
            >
              <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
              <option value="N">{{ $t(`uiElements.text.no`) }}</option>
            </select>
            <div class="invalid-feedback">
              <div v-if="v$.formTIDUpdate.opPinAmex.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-opPinDiscover  -->
        <div class="row mb-3">
          <label for="input-opPinDiscover" class="col-sm-6 col-form-label">
            {{ $t(`TerminalGroups.table.opPinDiscover`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
                class="form-select"
                :class="addClassValidInput('opPinDiscover')"
                id="input-opPinDiscover"
                v-model="v$.formTIDUpdate.opPinDiscover.$model"
            >
              <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
              <option value="N">{{ $t(`uiElements.text.no`) }}</option>
            </select>
            <div class="invalid-feedback">
              <div v-if="v$.formTIDUpdate.opPinDiscover.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-geoPosition  -->
        <div class="row mb-3">
          <label for="input-geoPosition" class="col-sm-6 col-form-label">
            {{ $t(`TerminalGroups.table.geoPosition`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('geoPosition')"
              id="input-geoPosition"
              v-model="v$.formTIDUpdate.geoPosition.$model"
            >
              <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
              <option value="N">{{ $t(`uiElements.text.no`) }}</option>
            </select>
            <div class="invalid-feedback">
              <div v-if="v$.formTIDUpdate.geoPosition.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-receiptSendChannelIdList  -->
        <div class="row mb-3">
          <label
            for="input-receiptSendChannelIdList"
            class="col-sm-6 col-form-label"
          >
            {{ $t(`TerminalGroups.table.receiptSendChannelIdList`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <multiselect
              v-model="v$.formTIDUpdate.receiptSendChannelIdList.$model"
              :options="multiSelectOptions.allReceiptSendChannels"
              class="multiselect-blue"
              :class="addClassValidInput('receiptSendChannelIdList')"
              id="input-receiptSendChannelIdList"
              mode="multiple"
              :close-on-select="false"
              :hide-selected="false"
              :disabled="false"
            >
            </multiselect>
            <div class="invalid-feedback">
              <div
                v-if="
                  v$.formTIDUpdate.receiptSendChannelIdList.required.$invalid
                "
              >
                {{ $t(`Validations.required`) }}
              </div>
              <div
                v-if="
                  v$.formTIDUpdate.receiptSendChannelIdList.checkRequiredList
                    .$invalid
                "
              >
                {{
                  $t(`Validations.checkSendChannelRequiredList`, {
                    chList: receiptSendChannelRequired.join(", "),
                  })
                }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-productIdList  -->
        <div class="row mb-3">
          <label for="input-productIds" class="col-sm-6 col-form-label">
            {{ $t(`TerminalGroups.table.productIdList`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <multiselect
              v-model="v$.formTIDUpdate.productIdList.$model"
              :options="multiSelectOptions.allProducts"
              class="multiselect-blue"
              :class="addClassValidInput('productIdList')"
              id="input-productIds"
              mode="multiple"
              :close-on-select="false"
              :hide-selected="false"
              :disabled="false"
            >
            </multiselect>
            <div class="invalid-feedback">
              <div v-if="v$.formTIDUpdate.productIdList.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-menuItemList  -->
        <div class="row mb-3">
          <label for="input-menuItemList" class="col-sm-6 col-form-label">
            {{ $t(`TerminalGroups.table.menuItemList`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <multiselect
              v-model="v$.formTIDUpdate.menuItemList.$model"
              :options="multiSelectOptions.allMenuItems"
              class="multiselect-blue"
              :class="addClassValidInput('menuItemList')"
              id="input-menuItemList"
              mode="multiple"
              :close-on-select="false"
              :hide-selected="false"
              :can-clear="false"
              :disabled="false"
            >
            </multiselect>
            <div class="invalid-feedback">
              <div v-if="v$.formTIDUpdate.menuItemList.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-receiptTemplateId  -->
        <div class="row mb-3">
          <label for="input-receiptTemplateId" class="col-sm-6 col-form-label">
            {{ $t(`TerminalGroups.table.receiptTemplateId`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('receiptTemplateId')"
              id="input-receiptTemplateId"
              v-model="v$.formTIDUpdate.receiptTemplateId.$model"
              :disabled="false"
            >
              <option
                :value="item.value"
                v-for="item in this.multiSelectOptions.allReceiptTemplates"
                :key="item.value"
              >
                {{ item.label }}
              </option>
            </select>
            <div class="invalid-feedback">
              <div v-if="v$.formTIDUpdate.receiptTemplateId.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-oneTransactionLimit  -->
        <div class="row mb-3">
          <label
            for="input-oneTransactionLimit"
            class="col-sm-6 col-form-label"
          >
            {{ $t(`TerminalGroups.table.oneTransactionLimit`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="number"
              class="form-control"
              :class="addClassValidInput('oneTransactionLimit')"
              id="input-oneTransactionLimit"
              v-model="v$.formTIDUpdate.oneTransactionLimit.$model"
            />
            <div class="invalid-feedback">
              <div
                v-if="v$.formTIDUpdate.oneTransactionLimit.required.$invalid"
              >
                {{ $t(`Validations.required`) }}
              </div>
              <div
                v-if="v$.formTIDUpdate.oneTransactionLimit.maxLength.$invalid"
              >
                {{ $t(`Validations.maxLength`, { num: 9 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-noPinLimit  -->
        <div class="row mb-3">
          <label for="input-noPinLimit" class="col-sm-6 col-form-label">
            {{ $t(`TerminalGroups.table.noPinLimit`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="number"
              class="form-control"
              :class="addClassValidInput('noPinLimit')"
              id="input-noPinLimit"
              v-model="v$.formTIDUpdate.noPinLimit.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formTIDUpdate.noPinLimit.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formTIDUpdate.noPinLimit.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 9 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-totalLimitPeriod  -->
        <div class="row mb-3">
          <label for="input-totalLimitPeriod" class="col-sm-6 col-form-label">
            {{ $t(`TerminalGroups.table.totalLimitPeriod`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="number"
              class="form-control"
              :class="addClassValidInput('totalLimitPeriod')"
              id="input-totalLimitPeriod"
              v-model="v$.formTIDUpdate.totalLimitPeriod.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formTIDUpdate.totalLimitPeriod.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formTIDUpdate.totalLimitPeriod.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 9 }) }}
              </div>
              <div v-if="v$.formTIDUpdate.totalLimitPeriod.minValue.$invalid">
                {{ $t(`Validations.minValue`, { num: 1 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-zreportEnabledAll  -->
        <div class="row mb-3">
          <label for="input-zreportEnabledAll" class="col-sm-6 col-form-label">
            {{ $t(`Terminal.formUpdateTID.zreportEnabledAll`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <multiselect
              v-model="v$.formTIDUpdate.zreportEnabledAll.$model"
              :options="multiSelectOptions.allZReport"
              class="multiselect-blue"
              :class="addClassValidInput('zreportEnabledAll')"
              id="input-zreportEnabledAll"
              mode="multiple"
              :close-on-select="false"
              :hide-selected="false"
              :can-clear="false"
            >
            </multiselect>
          </div>
        </div>

        <!--  input-opNfc  -->
        <div class="row mb-3">
          <label for="input-opNfc" class="col-sm-6 col-form-label">
            {{ $t(`TerminalGroups.table.opNfc`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('opNfc')"
              id="input-opNfc"
              v-model="v$.formTIDUpdate.opNfc.$model"
            >
              <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
              <option value="N">{{ $t(`uiElements.text.no`) }}</option>
            </select>
            <div class="invalid-feedback">
              <div v-if="v$.formTIDUpdate.opNfc.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-velocityCount  -->
        <div class="row mb-3">
          <label for="input-velocityCount" class="col-sm-6 col-form-label">
            {{ $t(`TerminalGroups.table.velocityCount`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="number"
              class="form-control"
              :class="addClassValidInput('velocityCount')"
              id="input-velocityCount"
              v-model="v$.formTIDUpdate.velocityCount.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formTIDUpdate.velocityCount.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formTIDUpdate.velocityCount.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 9 }) }}
              </div>
              <div v-if="v$.formTIDUpdate.velocityCount.minValue.$invalid">
                {{ $t(`Validations.minValue`, { num: 0 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-velocityPeriod  -->
        <div class="row mb-3">
          <label for="input-velocityPeriod" class="col-sm-6 col-form-label">
            {{ $t(`TerminalGroups.table.velocityPeriod`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="number"
              class="form-control"
              :class="addClassValidInput('velocityPeriod')"
              id="input-velocityPeriod"
              v-model="v$.formTIDUpdate.velocityPeriod.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formTIDUpdate.velocityPeriod.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formTIDUpdate.velocityPeriod.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 9 }) }}
              </div>
              <div v-if="v$.formTIDUpdate.velocityPeriod.minValue.$invalid">
                {{ $t(`Validations.minValue`, { num: 0 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-velocityTimeUnit  -->
        <div class="row mb-3">
          <label for="input-velocityTimeUnit" class="col-sm-6 col-form-label">
            {{ $t(`TerminalGroups.table.velocityTimeUnit`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('velocityTimeUnit')"
              id="input-velocityTimeUnit"
              v-model="v$.formTIDUpdate.velocityTimeUnit.$model"
            >
              <option value="NANOS">NANOS</option>
              <option value="MICROS">MICROS</option>
              <option value="MILLIS">MILLIS</option>
              <option value="SECONDS">SECONDS</option>
              <option value="MINUTES">MINUTES</option>
              <option value="HOURS">HOURS</option>
              <option value="HALF_DAYS">HALF_DAYS</option>
              <option value="DAYS">DAYS</option>
              <option value="WEEKS">WEEKS</option>
              <option value="MONTHS">MONTHS</option>
              <option value="YEARS">YEARS</option>
            </select>
            <div class="invalid-feedback">
              <div v-if="v$.formTIDUpdate.velocityTimeUnit.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-opTips  -->
        <div class="row mb-3">
          <label for="input-opTips" class="col-sm-6 col-form-label">
            {{ $t(`TerminalGroups.table.opTips`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('opTips')"
              id="input-opTips"
              v-model="v$.formTIDUpdate.opTips.$model"
            >
              <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
              <option value="N">{{ $t(`uiElements.text.no`) }}</option>
            </select>
            <div class="invalid-feedback">
              <div v-if="v$.formTIDUpdate.opTips.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-transactionGeoposition  -->
        <div class="row mb-3">
          <label for="input-opTips" class="col-sm-6 col-form-label">
            {{ $t(`TerminalGroups.table.transactionGeoposition`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('transactionGeoposition')"
              id="input-opTips"
              v-model="v$.formTIDUpdate.transactionGeoposition.$model"
            >
              <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
              <option value="N">{{ $t(`uiElements.text.no`) }}</option>
            </select>
            <div class="invalid-feedback">
              <div
                v-if="v$.formTIDUpdate.transactionGeoposition.required.$invalid"
              >
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-latitude  -->
        <div class="row mb-3">
          <label for="input-latitude" class="col-sm-6 col-form-label">
            {{ $t(`MerchantRegistration.table.latitude`) }}
            <span
              class="text-danger"
              v-if="v$.formTIDUpdate.geoPosition.$model === 'Y'"
              >*</span
            >
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInput('latitude')"
              id="input-latitude"
              v-model="v$.formTIDUpdate.latitude.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formTIDUpdate.latitude.requiredIf.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formTIDUpdate.latitude.regex.$invalid">
                {{ $t(`MerchantRegistration.validationRegForm.titude`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-longitude  -->
        <div class="row mb-3">
          <label for="input-longitude" class="col-sm-6 col-form-label">
            {{ $t(`MerchantRegistration.table.longitude`) }}
            <span
              class="text-danger"
              v-if="v$.formTIDUpdate.geoPosition.$model === 'Y'"
              >*</span
            >
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInput('longitude')"
              id="input-longitude"
              v-model="v$.formTIDUpdate.longitude.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formTIDUpdate.longitude.requiredIf.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formTIDUpdate.longitude.regex.$invalid">
                {{ $t(`MerchantRegistration.validationRegForm.titude`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-radius  -->
        <div class="row mb-3">
          <label for="input-radius" class="col-sm-6 col-form-label">
            {{ $t(`MerchantRegistration.table.radius`) }}
            <span
              class="text-danger"
              v-if="v$.formTIDUpdate.geoPosition.$model === 'Y'"
              >*</span
            >
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInput('radius')"
              id="input-radius"
              v-model="v$.formTIDUpdate.radius.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formTIDUpdate.radius.requiredIf.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formTIDUpdate.radius.radiusCheck.$invalid">
                {{ $t(`MerchantRegistration.validationRegForm.radiusReg`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-opCreateSubordinationTerminal  -->
        <div class="row mb-3">
          <label
            for="input-opCreateSubordinationTerminal"
            class="col-sm-6 col-form-label"
          >
            {{ $t(`TerminalGroups.table.opCreateSubordinationTerminal`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('opCreateSubordinationTerminal')"
              id="input-opCreateSubordinationTerminal"
              v-model="v$.formTIDUpdate.opCreateSubordinationTerminal.$model"
            >
              <option value="Y">{{ $t(`uiElements.text.yes`) }}</option>
              <option value="N">{{ $t(`uiElements.text.no`) }}</option>
            </select>
            <div class="invalid-feedback">
              <div
                v-if="
                  v$.formTIDUpdate.opCreateSubordinationTerminal.required
                    .$invalid
                "
              >
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-numberOfSecondaryTerminals  -->
        <div
          class="row mb-3"
          v-if="v$.formTIDUpdate.opCreateSubordinationTerminal.$model === 'Y'"
        >
          <label
            for="input-numberOfSecondaryTerminals"
            class="col-sm-6 col-form-label"
          >
            {{ $t(`TerminalGroups.table.numberOfSecondaryTerminals`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="number"
              v-maska
              data-maska="#############"
              class="form-control"
              :class="addClassValidInput('numberOfSecondaryTerminals')"
              id="input-numberOfSecondaryTerminals"
              v-model="v$.formTIDUpdate.numberOfSecondaryTerminals.$model"
            />
            <div class="invalid-feedback">
              <div
                v-if="
                  v$.formTIDUpdate.numberOfSecondaryTerminals.requiredIf
                    .$invalid
                "
              >
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-addData  -->
        <div class="row mb-3">
          <label for="input-groupNumber" class="col-sm-6 col-form-label">
            {{ $t(`Terminal.formUpdateTID.addData`) }}
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              id="input-addData"
              v-model="v$.formTIDUpdate.addData.$model"
            />
          </div>
        </div>

        <!--  Buttons form  -->
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-outline-secondary me-3"
            @click="showModal = false"
          >
            {{ $t(`uiElements.btn.close`) }}
          </button>
          <button
            type="submit"
            class="btn btn-outline-primary"
            :disabled="v$.$invalid && v$.$dirty ? true : false"
          >
            {{ $t(`uiElements.btn.save`) }}
          </button>
        </div>
      </form>
    </template>
    <template v-if="formType === 'formAddSubTerminal'">
      <form
        class="needs-validation"
        @submit.prevent="submitFormSubTerminal"
        novalidate
      >
        <!--  input-userLogin  -->
        <div class="row mb-3">
          <label for="input-userLogin" class="col-sm-6 col-form-label">
            {{ $t(`MerchantRegistration.table.userLogin`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInputBySubTerminal('userLogin')"
              id="input-userLogin"
              v-model="v$.formSubTerminal.userLogin.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formSubTerminal.userLogin.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formSubTerminal.userLogin.regex.$invalid">
                {{ $t(`Validations.onlyLettersNumbers`) }}
              </div>
              <div v-if="v$.formSubTerminal.userLogin.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 10 }) }}
              </div>
              <div v-if="v$.formSubTerminal.userLogin.minLength.$invalid">
                {{ $t(`Validations.minLength`, { num: 4 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-masterTerminalId  -->
        <div class="row mb-3">
          <label for="input-merchantName" class="col-sm-6 col-form-label">
            {{ $t(`Terminal.table.masterTerminalId`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInputBySubTerminal('masterTerminalId')"
              id="input-merchantName"
              v-model="v$.formSubTerminal.masterTerminalId.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formSubTerminal.masterTerminalId.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-merchantLocation  -->
        <div class="row mb-3">
          <label for="input-terminalLocation" class="col-sm-6 col-form-label">
            {{ $t(`MerchantRegistration.table.terminalLocation`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <textarea
              rows="3"
              class="form-control"
              :class="addClassValidInputBySubTerminal('terminalLocation')"
              id="input-terminalLocation"
              v-model="v$.formSubTerminal.terminalLocation.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formSubTerminal.terminalLocation.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div
                v-if="v$.formSubTerminal.terminalLocation.maxLength.$invalid"
              >
                {{ $t(`Validations.maxLength`, { num: 50 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-merchantLocationEng  -->
        <div class="row mb-3">
          <label
            for="input-terminalLocationEng"
            class="col-sm-6 col-form-label"
          >
            {{ $t(`MerchantRegistration.table.terminalLocationEng`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <textarea
              rows="3"
              class="form-control"
              :class="addClassValidInputBySubTerminal('terminalLocationEng')"
              id="input-merchantLocationEng"
              v-model="v$.formSubTerminal.terminalLocationEng.$model"
            />
            <div class="invalid-feedback">
              <div
                v-if="v$.formSubTerminal.terminalLocationEng.required.$invalid"
              >
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formSubTerminal.terminalLocationEng.regex.$invalid">
                {{
                  $t(
                    `MerchantRegistration.validationRegForm.merchantLocationRegEx`
                  )
                }}
              </div>
              <div
                v-if="v$.formSubTerminal.terminalLocationEng.maxLength.$invalid"
              >
                {{ $t(`Validations.maxLength`, { num: 50 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-phoneNumber  -->
        <div class="row mb-3">
          <label for="input-phoneNumber" class="col-sm-6 col-form-label">
            {{ $t(`MerchantRegistration.table.phoneNumber`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInputBySubTerminal('phoneNumber')"
              id="input-phoneNumber"
              v-model="v$.formSubTerminal.phoneNumber.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formSubTerminal.phoneNumber.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formSubTerminal.phoneNumber.regex.$invalid">
                {{
                  $t(`MerchantRegistration.validationRegForm.phoneNumberRegex`)
                }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-code  -->
        <div class="row mb-3">
          <label for="input-code" class="col-sm-6 col-form-label">
            {{ $t(`Terminal.formSubTerminal.code`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInputBySubTerminal('code')"
              id="input-terminalId"
              v-model="v$.formSubTerminal.code.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formSubTerminal.code.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formSubTerminal.code.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 6 }) }}
              </div>
              <div v-if="v$.formSubTerminal.code.minLength.$invalid">
                {{ $t(`Validations.minLength`, { num: 6 }) }}
              </div>
            </div>
          </div>
        </div>
        <!--  Buttons form  -->
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-outline-secondary me-3"
            @click="showModal = false"
          >
            {{ $t(`uiElements.btn.close`) }}
          </button>
          <button
            type="submit"
            class="btn btn-outline-primary"
            :disabled="v$.$invalid && v$.$dirty ? true : false"
          >
            {{ $t(`uiElements.btn.save`) }}
          </button>
        </div>
      </form>
    </template>
    <template v-if="formType === 'formViewTableSettings'">
      <trn-table-column-settings
        :columns="columnList"
        @update-columns="changeVisibleColumns"
        page-table-view-name="visibleColumnsTableTerminalList"
      ></trn-table-column-settings>
    </template>
    <template v-if="formType === 'formKeyManager'">
      <form
        class="needs-validation"
        @submit.prevent="submitFormKeyManager"
        novalidate
      >
        <!--  input-refCode  -->
        <div class="row mb-3">
          <label for="input-refCode" class="col-sm-6 col-form-label">
            {{ $t(`TerminalKeys.table.refCode`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInputKM('refCode')"
              id="input-refCode"
              v-model="v$.formKeyManager.refCode.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formKeyManager.refCode.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  Buttons form  -->
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-outline-secondary me-3"
            @click="showModal = false"
          >
            {{ $t(`uiElements.btn.close`) }}
          </button>
          <button
            @click="runAllKeyManager"
            class="btn btn-outline-success me-3"
          >
            {{ $t(`TerminalKeys.btn.keyManagerAll`) }}
          </button>
          <button
            type="submit"
            class="btn btn-outline-primary"
            :disabled="v$.$invalid && v$.$dirty ? true : false"
          >
            {{ $t(`uiElements.btn.run`) }}
          </button>
        </div>
      </form>
    </template>
  </bs-modal>
  <div
    class="toast-container position-absolute p-3 end-0 z-index-1500"
    v-if="showToast"
  >
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import TrnLoading from "@/components/Loading";
import BsModal from "@/components/UI-elements/BsModal";
import { mapState } from "vuex";
import { stringify } from "query-string";
import { actionTypes } from "@/store/modules/terminal";
import {
  helpers,
  maxLength,
  required,
  minLength,
  minValue,
  requiredIf,
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import terminalApi from "@/api/terminal";
import BsToast from "@/components/UI-elements/BsToast";
import Datepicker from "vue3-date-time-picker";
import Multiselect from "@vueform/multiselect";
import TrnTableColumnSettings from "@/components/TableColumnSettings.vue";
import { getItemLocalStorage } from "@/helpers/persistanceStorage";
import terminalKeysApi from "@/api/terminal-keys";

export default {
  name: "TrnTerminal",
  components: {
    TrnTableColumnSettings,
    TrnLoading,
    BsModal,
    BsToast,
    Datepicker,
    Multiselect,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      page: 0,
      size: 50,
      sort: {
        col: "terminalId",
        order: "desc",
      },
      filters: {
        terminalId: "",
        groupNumber: "",
        dateTimeInit: "",
        merchantName: "",
        merchantLegalName: "",
        status: "",
        masterTerminalId: "",
      },
      dateFormat: "dd/MM/yyyy",
      dateTimeInit: "",
      showModal: false,
      titleModal: "",
      updateTID: "",
      formTIDUpdate: {},
      formSubTerminal: {},
      showToast: false,
      toastPropsList: [],
      formType: "",
      multiSelectOptions: {
        allReceiptTemplates: [],
        allIpsCardGroups: [],
        allCurrencies: [],
        allMenuItems: [],
        allMenuMandatoryItems: [],
        allProducts: [],
        allReceiptSendChannels: [],
        allZReport: [],
      },
      receiptSendChannelRequired: [],
      columnList: [
        {
          title: this.$t(`Terminal.table.terminalId`),
          key: "terminalId",
          visible: true,
          required: true,
        },
        {
          title: this.$t(`Terminal.table.groupNumber`),
          key: "groupNumber",
          visible: true,
          required: false,
        },
        {
          title: this.$t(`Terminal.table.dateTimeInit`),
          key: "dateTimeInit",
          visible: true,
          required: false,
        },
        {
          title: this.$t(`Terminal.table.merchantId`),
          key: "merchantId",
          visible: true,
          required: false,
        },
        {
          title: this.$t(`Terminal.table.merchantName`),
          key: "merchantName",
          visible: true,
          required: false,
        },
        {
          title: this.$t(`Terminal.table.merchantLegalName`),
          key: "merchantLegalName",
          visible: true,
          required: false,
        },
        {
          title: this.$t(`Terminal.table.bankName`),
          key: "bankName",
          visible: true,
          required: false,
        },
        {
          title: this.$t(`Terminal.table.currencyCode`),
          key: "currencyCode",
          visible: true,
          required: false,
        },
        {
          title: this.$t(`Terminal.table.deviceName`),
          key: "deviceName",
          visible: true,
          required: false,
        },
        {
          title: this.$t(`Terminal.table.zReportAuto`),
          key: "zReportAuto",
          visible: true,
          required: false,
        },
        {
          title: this.$t(`Terminal.table.lastTransactionDate`),
          key: "lastTransactionDate",
          visible: true,
          required: false,
        },
        {
          title: this.$t(`Terminal.table.lastUpdateDate`),
          key: "lastUpdateDate",
          visible: true,
          required: false,
        },
        {
          title: this.$t(`Terminal.table.status`),
          key: "status",
          visible: true,
          required: false,
        },
        {
          title: this.$t(`Terminal.table.masterTerminalId`),
          key: "masterTerminalId",
          visible: true,
          required: false,
        },
        {
          title: this.$t(`Terminal.table.terminalLocation`),
          key: "terminalLocation",
          visible: true,
          required: false,
        },
        {
          title: this.$t(`Terminal.table.terminalLocationEng`),
          key: "terminalLocationEng",
          visible: true,
          required: false,
        },
        {
          title: this.$t(`MerchantRegistration.table.taxId`),
          key: "taxId",
          visible: true,
          required: false,
        },
        {
          title: this.$t(`Terminal.table.mcc`),
          key: "mcc",
          visible: true,
          required: false,
        },
        {
          title: this.$t(`TerminalGroups.table.totalAmountLimit`),
          key: "totalAmountLimit",
          visible: true,
          required: false,
        },
        {
          title: this.$t(`TerminalGroups.table.totalCountLimit`),
          key: "totalCountLimit",
          visible: true,
          required: false,
        },
      ],
      formKeyManager: {},
    };
  },
  validations() {
    return {
      formTIDUpdate: {
        status: {
          required,
        },
        repeatRegistration: {
          required,
        },
        addData: {},
        opPinMasterCard: {
          required,
        },
        receiptSendChannelIdList: {
          required,
          checkRequiredList: helpers.withAsync(() => {
            let result = false;
            this.receiptSendChannelRequired = [];
            this.dataResp.allReceiptSendChannels.forEach((item) => {
              if (item.receiptSendChannelType === "BASIC") {
                this.receiptSendChannelRequired.push(item.name);
              }
            });

            this.formTIDUpdate.receiptSendChannelIdList.forEach((id) => {
              this.dataResp.allReceiptSendChannels.forEach((item) => {
                if (item.receiptSendChannelType === "BASIC") {
                  if (id === item.id) {
                    result = true;
                  }
                }
              });
            });
            return result;
          }),
        },
        productIdList: {
          required,
        },
        receiptTemplateId: {
          required,
        },
        zreportEnabledAll: {},
        opPurchase: {
          required,
        },
        opReversal: {
          required,
        },
        opRefund: {
          required,
        },
        opNfc: {
          required,
        },
        opManual: {
          required,
        },
        opCash: {
          required,
        },
        opPinVisa: {
          required,
        },
        opPinProstir: {
          required,
        },
        opPinAmex: {
          required,
        },
        opPinDiscover: {
          required,
        },
        geoPosition: {
          required,
        },
        oneTransactionLimit: {
          required,
          maxLength: maxLength(9),
        },
        noPinLimit: {
          required,
          maxLength: maxLength(9),
        },
        menuItemList: {
          required,
        },
        opTips: {
          required,
        },
        totalLimitPeriod: {
          required,
          maxLength: maxLength(9),
          minValue: minValue(1),
        },
        velocityCount: {
          required,
          maxLength: maxLength(9),
          minValue: minValue(0),
        },
        velocityPeriod: {
          required,
          maxLength: maxLength(9),
          minValue: minValue(0),
        },
        velocityTimeUnit: {
          required,
        },
        latitude: {
          requiredIf: requiredIf(this.formTIDUpdate.geoPosition === "Y"),
          regex: helpers.regex(/-?\d{2,}/),
        },
        longitude: {
          requiredIf: requiredIf(this.formTIDUpdate.geoPosition === "Y"),
          regex: helpers.regex(/-?\d{2,}/),
        },
        radius: {
          requiredIf: requiredIf(this.formTIDUpdate.geoPosition === "Y"),
          radiusCheck: function (val) {
            return val >= 0 && val <= 1000000;
          },
        },

        opCreateSubordinationTerminal: {
          required,
        },
        numberOfSecondaryTerminals: {
          requiredIf: requiredIf(
            this.formTIDUpdate.opCreateSubordinationTerminal === "Y"
          ),
        },
        transactionGeoposition: {
          required,
        },
      },
      formSubTerminal: {
        userLogin: {
          required,
          maxLength: maxLength(10),
          minLength: minLength(4),
          regex: helpers.regex(/^[a-zA-Z0-9]+$/),
        },
        masterTerminalId: {
          required,
          // regex: helpers.regex(/^[a-zA-Z0-9]+$/),
        },
        terminalLocation: {
          required,
          maxLength: maxLength(50),
        },
        terminalLocationEng: {
          required,
          regex: helpers.regex(/^[\\s,./a-zA-Zа-яёА-ЯЁїЇєЄіІҐґ0-9]+$/),
          maxLength: maxLength(50),
        },
        phoneNumber: {
          required,
          regex: helpers.regex(/^(\+?\d{10,12})$/),
        },
        code: {
          required,
          maxLength: maxLength(6),
          minLength: minLength(6),
        },
      },
      formKeyManager: {
        refCode: {
          required,
        },
      },
    };
  },
  computed: {
    dataResp: {
      get() {
        return this.$store.state.terminal.dataResp;
      },
      set(newValue) {
        this.$store.state.terminal.dataResp = newValue;
      },
    },
    ...mapState({
      isLoading: (state) => state.terminal.isLoading,
      isLoadingScroll: (state) => state.terminal.isLoadingScroll,
      dataModalResp: (state) => state.terminal.dataModalResp,
      errorResp: (state) => state.terminal.errorResp,
    }),
  },
  methods: {
    fetchTerminals() {
      this.changeArrowIcon();
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
        ...this.filters,
      });
      this.$store.dispatch(actionTypes.getTerminal, {
        apiParams: stringifiedParams,
      });
    },
    scrollTable(event) {
      const currentEl = event.target;
      let checkScrollEnd =
        currentEl.scrollTop + currentEl.clientHeight + 3 >=
        currentEl.scrollHeight;
      if (checkScrollEnd && this.isLoadingScroll === false) {
        const currentPage = this.page + 1;
        const totalPages = this.dataResp.page.totalPages;
        if (currentPage < totalPages) this.loadMoreData();
      }
    },
    loadMoreData() {
      this.page += 1;
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
      });
      this.$store.dispatch(actionTypes.getTerminalScrollPush, {
        apiParams: stringifiedParams,
      });
    },
    reloadPage() {
      this.page = 0;
      this.fetchTerminals();
    },
    clickSortCol(event, newSortItem) {
      const newSortItemData = newSortItem;
      newSortItem = newSortItem.replaceAll(".", "-");
      const scrollTableEl = document.getElementById("table-block");
      const beforeSortKey = this.sort.col.replaceAll(".", "-");
      const beforeSortDirection = this.sort.order;

      const beforeEl = document.querySelectorAll(
        `[data-col-sort~=${beforeSortKey}]`
      );
      beforeEl[0].children[0].innerHTML = '<i class="bi bi-arrow-down-up"></i>';

      // let newSortVal = [newSortItem, ""];

      if (beforeSortDirection === "desc" && beforeSortKey === newSortItem) {
        this.sort.order = "asc";
      } else {
        this.sort.order = "desc";
      }
      this.page = 0;
      this.sort.col = newSortItemData;

      scrollTableEl.scrollTo(0, 0);
      this.fetchTerminals();
    },
    changeArrowIcon(sortKey = this.sort.col, sortDirection = this.sort.order) {
      sortKey = sortKey.replaceAll(".", "-");
      const targetEl = document.querySelectorAll(`[data-col-sort~=${sortKey}]`);
      if (sortDirection === "asc") {
        targetEl[0].children[0].innerHTML =
          '<i class="bi bi-arrow-up-square"></i>';
      } else {
        targetEl[0].children[0].innerHTML =
          '<i class="bi bi-arrow-down-square"></i>';
      }
    },
    formFilterRm() {
      this.$refs.dropdownBtnFilter.click();
      if (this.dateTimeInit) {
        let initDate = new Date(this.dateTimeInit);
        initDate.setHours(0, 0, 0, 0);
        this.filters.dateTimeInit = initDate.toISOString();
      } else {
        this.filters.dateTimeInit = "";
      }
      this.fetchTerminals();
    },
    resetFilterForm() {
      for (let item in this.filters) {
        this.filters[item] = "";
      }
      this.dateTimeInit = "";
      this.$refs.dropdownBtnFilter.click();
      this.fetchTerminals();
    },
    showUpdateTIDForm(rowItem) {
      this.titleModal =
        this.$t(`Terminal.table.terminalId`) + ": " + rowItem.terminalId;

      this.updateTID = rowItem.terminalId;
      this.formTIDUpdate.repeatRegistration = rowItem.repeatRegistration;
      this.formTIDUpdate.status = rowItem.status;
      this.formTIDUpdate.opPurchase = rowItem.opPurchase;
      this.formTIDUpdate.opManual = rowItem.opManual;
      this.formTIDUpdate.opCash = rowItem.opCash;
      this.formTIDUpdate.opReversal = rowItem.opReversal;
      this.formTIDUpdate.opRefund = rowItem.opRefund;
      this.formTIDUpdate.opPinMasterCard = rowItem.opPinMasterCard;
      this.formTIDUpdate.opPinVisa = rowItem.opPinVisa;
      this.formTIDUpdate.opPinProstir = rowItem.opPinProstir;
      this.formTIDUpdate.opPinAmex = rowItem.opPinAmex;
      this.formTIDUpdate.opPinDiscover = rowItem.opPinDiscover;
      this.formTIDUpdate.geoPosition = rowItem.geoPosition;
      this.formTIDUpdate.transactionGeoposition =
        rowItem.transactionGeoposition;

      this.formTIDUpdate.receiptSendChannelIdList = [];
      for (const key in rowItem.receiptSendChannels) {
        this.formTIDUpdate.receiptSendChannelIdList.push(
          rowItem.receiptSendChannels[key].id
        );
      }

      this.formTIDUpdate.productIdList = [];
      for (const key in rowItem.products) {
        this.formTIDUpdate.productIdList.push(rowItem.products[key].productId);
      }

      this.formTIDUpdate.ipsCardGroupIdList = [];
      for (const key in rowItem.allowedIpsCardGroups) {
        this.formTIDUpdate.ipsCardGroupIdList.push(
          rowItem.allowedIpsCardGroups[key].ipsCardGroupId
        );
      }

      this.formTIDUpdate.receiptTemplateId = rowItem.receiptTemplate.id;
      this.formTIDUpdate.oneTransactionLimit = rowItem.oneTransactionLimit;
      this.formTIDUpdate.noPinLimit = rowItem.noPinLimit;
      this.formTIDUpdate.totalLimitPeriod = rowItem.totalLimitPeriod;

      // z report values
      this.formTIDUpdate.zreportEnabledAll = [];
      if (rowItem.zreportMondayEnabled === "Y") {
        this.formTIDUpdate.zreportEnabledAll.push(1);
      }
      if (rowItem.zreportTuesdayEnabled === "Y") {
        this.formTIDUpdate.zreportEnabledAll.push(2);
      }
      if (rowItem.zreportWednesdayEnabled === "Y") {
        this.formTIDUpdate.zreportEnabledAll.push(3);
      }
      if (rowItem.zreportThursdayEnabled === "Y") {
        this.formTIDUpdate.zreportEnabledAll.push(4);
      }
      if (rowItem.zreportFridayEnabled === "Y") {
        this.formTIDUpdate.zreportEnabledAll.push(5);
      }
      if (rowItem.zreportSaturdayEnabled === "Y") {
        this.formTIDUpdate.zreportEnabledAll.push(6);
      }
      if (rowItem.zreportSundayEnabled === "Y") {
        this.formTIDUpdate.zreportEnabledAll.push(7);
      }

      this.formTIDUpdate.opNfc = rowItem.opNfc;
      this.formTIDUpdate.velocityCount = rowItem.velocityCount;
      this.formTIDUpdate.velocityPeriod = rowItem.velocityPeriod;
      this.formTIDUpdate.velocityTimeUnit = rowItem.velocityTimeUnit;
      this.formTIDUpdate.opTips = rowItem.opTips;

      this.formTIDUpdate.latitude = rowItem.latitude;
      this.formTIDUpdate.longitude = rowItem.longitude;
      this.formTIDUpdate.radius = rowItem.radius;

      this.formTIDUpdate.opCreateSubordinationTerminal =
        rowItem.opCreateSubordinationTerminal;
      this.formTIDUpdate.numberOfSecondaryTerminals =
        rowItem.numberOfSecondaryTerminals;

      // menu list values
      let setMenuItemList = new Set();
      this.formTIDUpdate.menuItemList = [];
      for (const key in rowItem.menuItemList) {
        setMenuItemList.add(rowItem.menuItemList[key]);
      }
      for (const key in this.multiSelectOptions.allMenuMandatoryItems) {
        setMenuItemList.add(this.multiSelectOptions.allMenuMandatoryItems[key]);
      }
      this.formTIDUpdate.menuItemList = [...setMenuItemList];
      // console.log("setMenuItemList", [...setMenuItemList]);

      this.formType = "formUpdateTID";
      this.showModal = !this.showModal;
    },
    showModalViewTableSettings() {
      this.titleModal = this.$t(`uiElements.text.tableColumnSettings`);
      this.formType = "formViewTableSettings";
      this.showModal = !this.showModal;
    },
    showKeyManagerForm() {
      this.showModal = !this.showModal;
      this.formType = "formKeyManager";
      this.titleModal = this.$t(`TerminalKeys.btn.keyManager`);
    },
    addClassValidInput(inputName) {
      return {
        "is-invalid":
          this.v$.formTIDUpdate[inputName].$error === true &&
          this.v$.formTIDUpdate[inputName].$dirty === true,
        "is-valid":
          this.v$.formTIDUpdate[inputName].$error === false &&
          this.v$.formTIDUpdate[inputName].$dirty === true,
      };
    },
    addClassValidInputBySubTerminal(inputName) {
      return {
        "is-invalid":
          this.v$.formSubTerminal[inputName]?.$error === true &&
          this.v$.formSubTerminal[inputName].$dirty === true,
        "is-valid":
          this.v$.formSubTerminal[inputName].$error === false &&
          this.v$.formSubTerminal[inputName].$dirty === true,
      };
    },
    addClassValidInputKM(inputName) {
      return {
        "is-invalid":
          this.v$.formKeyManager[inputName].$error === true &&
          this.v$.formKeyManager[inputName].$dirty === true,
        "is-valid":
          this.v$.formKeyManager[inputName].$error === false &&
          this.v$.formKeyManager[inputName].$dirty === true,
      };
    },
    formUpdateTID() {
      // console.log("subTID", { ...this.formTIDUpdate });
      this.v$.formTIDUpdate.$touch();
      if (!this.v$.formTIDUpdate.$error) {
        let zreportMondayEnabled = "N";
        let zreportTuesdayEnabled = "N";
        let zreportWednesdayEnabled = "N";
        let zreportThursdayEnabled = "N";
        let zreportFridayEnabled = "N";
        let zreportSaturdayEnabled = "N";
        let zreportSundayEnabled = "N";

        for (let item in this.formTIDUpdate.zreportEnabledAll) {
          if (this.formTIDUpdate.zreportEnabledAll[item] === 1) {
            zreportMondayEnabled = "Y";
          }
          if (this.formTIDUpdate.zreportEnabledAll[item] === 2) {
            zreportTuesdayEnabled = "Y";
          }
          if (this.formTIDUpdate.zreportEnabledAll[item] === 3) {
            zreportWednesdayEnabled = "Y";
          }
          if (this.formTIDUpdate.zreportEnabledAll[item] === 4) {
            zreportThursdayEnabled = "Y";
          }
          if (this.formTIDUpdate.zreportEnabledAll[item] === 5) {
            zreportFridayEnabled = "Y";
          }
          if (this.formTIDUpdate.zreportEnabledAll[item] === 6) {
            zreportSaturdayEnabled = "Y";
          }
          if (this.formTIDUpdate.zreportEnabledAll[item] === 7) {
            zreportSundayEnabled = "Y";
          }
        }

        let numberOfSecondaryTerminals = 0;

        if (this.formTIDUpdate.opCreateSubordinationTerminal === "Y") {
          numberOfSecondaryTerminals =
            this.formTIDUpdate.numberOfSecondaryTerminals;
        }

        terminalApi
          .updateTerminal(this.updateTID, {
            addData: this.formTIDUpdate.addData,
            transactionGeoposition: this.formTIDUpdate.transactionGeoposition,
            repeatRegistration: this.formTIDUpdate.repeatRegistration,
            status: this.formTIDUpdate.status,
            opPurchase: this.formTIDUpdate.opPurchase,
            opManual: this.formTIDUpdate.opManual,
            opReversal: this.formTIDUpdate.opReversal,
            opRefund: this.formTIDUpdate.opRefund,
            opPinMasterCard: this.formTIDUpdate.opPinMasterCard,
            opPinVisa: this.formTIDUpdate.opPinVisa,
            opPinProstir: this.formTIDUpdate.opPinProstir,
            opPinAmex: this.formTIDUpdate.opPinAmex,
            opPinDiscover: this.formTIDUpdate.opPinDiscover,
            geoPosition: this.formTIDUpdate.geoPosition,
            receiptSendChannelIdList:
              this.formTIDUpdate.receiptSendChannelIdList,
            productIdList: this.formTIDUpdate.productIdList,
            ipsCardGroupIdList: this.formTIDUpdate.ipsCardGroupIdList,
            receiptTemplateId: this.formTIDUpdate.receiptTemplateId,
            oneTransactionLimit: this.formTIDUpdate.oneTransactionLimit,
            noPinLimit: this.formTIDUpdate.noPinLimit,
            totalLimitPeriod: this.formTIDUpdate.totalLimitPeriod,

            zreportMondayEnabled: zreportMondayEnabled,
            zreportTuesdayEnabled: zreportTuesdayEnabled,
            zreportWednesdayEnabled: zreportWednesdayEnabled,
            zreportThursdayEnabled: zreportThursdayEnabled,
            zreportFridayEnabled: zreportFridayEnabled,
            zreportSaturdayEnabled: zreportSaturdayEnabled,
            zreportSundayEnabled: zreportSundayEnabled,

            opNfc: this.formTIDUpdate.opNfc,
            opCash: this.formTIDUpdate.opCash,
            velocityCount: this.formTIDUpdate.velocityCount,
            velocityPeriod: this.formTIDUpdate.velocityPeriod,
            velocityTimeUnit: this.formTIDUpdate.velocityTimeUnit,
            opTips: this.formTIDUpdate.opTips,
            latitude: this.formTIDUpdate.latitude,
            longitude: this.formTIDUpdate.longitude,
            radius: this.formTIDUpdate.radius,
            menuItemList: this.formTIDUpdate.menuItemList,
            numberOfSecondaryTerminals: numberOfSecondaryTerminals,
          })
          .then(function (result) {
            return result;
          })
          .catch(function (result) {
            return result;
          })
          .then((result) => {
            // console.log("result", result);
            if (result.status === 200) {
              this.showModal = false;

              this.toastPropsList.push({
                content: `${this.$t(`uiElements.toast.successSave`)}`,
                bgColor: "bg-success",
                textColor: "text-white",
              });
              this.showToast = true;

              this.fetchTerminals();
            } else {
              console.log("err");
              this.toastPropsList.push({
                content: `${this.$t(`uiElements.toast.errSave`)}`,
                bgColor: "bg-danger",
                textColor: "text-white",
              });
              this.showToast = true;
            }
          });
      }
    },
    addAllOption() {
      // allProducts
      for (const key in this.dataResp.allProducts) {
        const obj = {
          value: this.dataResp.allProducts[key].productId,
          label: this.dataResp.allProducts[key].productName,
        };
        this.multiSelectOptions.allProducts.push(obj);
      }

      // allReceiptTemplates
      for (const key in this.dataResp.allReceiptTemplates) {
        const obj = {
          value: this.dataResp.allReceiptTemplates[key].id,
          label: this.dataResp.allReceiptTemplates[key].templateName,
        };
        this.multiSelectOptions.allReceiptTemplates.push(obj);
      }

      // allIpsCardGroups
      for (const key in this.dataResp.allIpsCardGroups) {
        const obj = {
          value: this.dataResp.allIpsCardGroups[key].ipsCardGroupId,
          label: this.dataResp.allIpsCardGroups[key].ipsName,
        };
        this.multiSelectOptions.allIpsCardGroups.push(obj);
      }

      // allCurrencies
      for (const key in this.dataResp.allCurrencies) {
        const obj = {
          value: this.dataResp.allCurrencies[key].code,
          label: this.dataResp.allCurrencies[key].letterCode,
        };
        this.multiSelectOptions.allCurrencies.push(obj);
      }

      // allMenuItems
      this.multiSelectOptions.menuItemList = [];
      for (const key in this.dataResp.allMenuItems) {
        let mandatoryItem = false;
        if (this.dataResp.allMenuItems[key].mandatory) {
          mandatoryItem = true;
          this.multiSelectOptions.menuItemList.push(
            this.dataResp.allMenuItems[key].name
          );
          this.multiSelectOptions.allMenuMandatoryItems.push(
            this.dataResp.allMenuItems[key].name
          );
        }
        const obj = {
          value: this.dataResp.allMenuItems[key].name,
          label: this.dataResp.allMenuItems[key].name,
          disabled: mandatoryItem,
        };
        this.multiSelectOptions.allMenuItems.push(obj);
      }

      // allReceiptSendChannels
      for (const key in this.dataResp.allReceiptSendChannels) {
        const obj = {
          value: this.dataResp.allReceiptSendChannels[key].id,
          label: this.dataResp.allReceiptSendChannels[key].name,
        };
        this.multiSelectOptions.allReceiptSendChannels.push(obj);
      }

      this.multiSelectOptions.allZReport = [
        { label: "Monday", value: "1" },
        { label: "Tuesday", value: "2" },
        { label: "Wednesday", value: "3" },
        { label: "Thursday", value: "4" },
        { label: "Friday", value: "5" },
        { label: "Saturday", value: "6" },
        { label: "Sunday", value: "7" },
      ];
    },
    showAddSubTerminal() {
      this.formType = "formAddSubTerminal";
      this.showModal = !this.showModal;
      this.titleModal = this.$t(`Terminal.btn.addSubTerminal`);
    },
    submitFormSubTerminal() {
      this.v$.formSubTerminal.$touch();
      if (!this.v$.formSubTerminal.$error) {
        terminalApi
          .addSubordinationTerminalData({ ...this.formSubTerminal })
          .then(function (result) {
            return result;
          })
          .catch(function (result) {
            return result;
          })
          .then((result) => {
            // console.log("result", result);
            if (result.status === 200) {
              this.showModal = false;

              this.toastPropsList.push({
                content: `${this.$t(`uiElements.toast.successSave`)}`,
                bgColor: "bg-success",
                textColor: "text-white",
              });
              this.showToast = true;

              this.fetchTerminals();
            } else {
              console.log("err");
              this.toastPropsList.push({
                title: `${this.$t(`uiElements.toast.errSave`)}`,
                content: result.response.data.message,
                bgColor: "bg-danger",
                textColor: "text-white",
              });
              this.showToast = true;
            }
          });
      }
    },
    changeVisibleColumns() {
      const visibleList = getItemLocalStorage(
        "visibleColumnsTableTerminalList"
      );

      if (!visibleList) {
        return;
      }

      this.columnList.forEach((item) => {
        const findItem = visibleList.find((el) => el.key === item.key);
        if (findItem) {
          item.visible = findItem.visible;
        }
      });
    },

    submitFormKeyManager() {
      this.v$.formKeyManager.$touch();
      if (!this.v$.formKeyManager.$error) {
        terminalKeysApi
          .runOneKeyManager(this.formKeyManager.refCode)
          .then(function (result) {
            return result;
          })
          .catch(function (result) {
            return result;
          })
          .then((result) => {
            // console.log("result", result);
            if (result.status === 200) {
              this.showModal = false;

              this.toastPropsList.push({
                content: `${this.$t(`uiElements.toast.success`)}`,
                bgColor: "bg-success",
                textColor: "text-white",
              });
              this.showToast = true;
              this.formKeyManager.refCode = "";
            } else {
              console.log("err");
              this.toastPropsList.push({
                content: `${this.$t(`uiElements.toast.err`)}`,
                bgColor: "bg-danger",
                textColor: "text-white",
              });
              this.showToast = true;
            }
          });
      }
    },
    runAllKeyManager() {
      terminalKeysApi
        .runAllKeyManager()
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          // console.log("result", result);
          if (result.status === 200) {
            this.showModal = false;

            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.success`)}`,
              bgColor: "bg-success",
              textColor: "text-white",
            });
            this.showToast = true;
          } else {
            console.log("err");
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.err`)}`,
              bgColor: "bg-danger",
              textColor: "text-white",
            });
            this.showToast = true;
          }
        });
    },
  },
  watch: {
    dataResp() {
      this.addAllOption();
    },
    dateTimeInit() {
      if (this.dateTimeInit) {
        let startDate = new Date();
        let endDate = new Date(this.dateTimeInit);
        if (startDate < endDate) {
          this.dateTimeInit = startDate;
        }
      }
    },
    "formTIDUpdate.opTips"() {
      const opTips = this.formTIDUpdate.opTips;
      const indexTips = this.multiSelectOptions.allMenuItems.findIndex(
        (o) => o.value === "TIPS"
      );
      if (opTips === "Y") {
        this.multiSelectOptions.allMenuItems[indexTips].disabled = false;
      } else {
        this.multiSelectOptions.allMenuItems[indexTips].disabled = true;
        const menuFindIndex = this.formTIDUpdate.menuItemList.indexOf("TIPS");
        if (menuFindIndex > -1) {
          this.formTIDUpdate.menuItemList.splice(menuFindIndex, 1);
        }
      }
    },
  },
  mounted() {
    this.fetchTerminals();
    this.changeVisibleColumns();
  },
};
</script>

<style scoped></style>
