<template>
  <div class="title-table mb-2 ps-3 py-1 fw-bold">
    {{ $t(`settings-menu.VendorRSO`) }}
  </div>

  <!-- ToolBar -->
  <div class="toolbar">
    <div class="btn-group mb-2">
      <button
        class="btn btn-outline-primary btn-sm"
        type="button"
        @click="clickFormRSO('add')"
      >
        <i class="bi bi-file-earmark-plus"></i>
        <span class="ms-1">{{ $t(`uiElements.btn.add`) }}</span>
      </button>
      <button
        class="btn btn-outline-primary btn-sm"
        :class="{ disabled: !selectedTableRow?.id }"
        type="button"
        @click="clickFormRSO('update')"
      >
        <i class="bi bi-pencil-square"></i>
        <span class="ms-1">{{ $t(`uiElements.btn.change`) }}</span>
      </button>
    </div>
  </div>

  <!-- ToolBar кінець -->

  <div
    class="table-responsive table-block overflow-auto col-md-4"
    id="table-block"
    @scroll="scrollTable"
  >
    <table class="table table-sm table-light">
      <thead>
        <tr class="text-center">
          <th scope="col">
            {{ $t(`VendorRSO.table.vendorId`) }}
          </th>
          <th scope="col">
            {{ $t(`VendorRSO.table.name`) }}
          </th>
          <th scope="col">
            {{ $t(`VendorRSO.table.version`) }}
          </th>
          <th scope="col">
            {{ $t(`VendorRSO.table.expiredDate`) }}
          </th>
        </tr>
      </thead>
      <tbody class="text-nowrap">
        <template v-if="isLoading">
          <tr>
            <td colspan="16">
              <div class="loading text-center">
                <trn-loading></trn-loading>
              </div>
            </td>
          </tr>
        </template>
        <template v-if="dataResp">
          <tr
            v-for="item in dataResp.content"
            :key="item"
            class="text-center cursor-pointer"
            :class="{
              'select-td': item.id === selectedTableRow?.id,
            }"
            @click="clickTableRow(item)"
          >
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.version }}</td>
            <td>{{ $filters.formatDateTime(item.expiredDate, "date") }}</td>
          </tr>
          <template v-if="isLoadingScroll">
            <tr>
              <td colspan="16">
                <div class="loading text-center">
                  <trn-loading></trn-loading>
                </div>
              </td>
            </tr>
          </template>
        </template>
        <template v-if="dataResp?.content.length === 0">
          <tr>
            <td colspan="16">
              <div class="text-center fw-bold bg-info text-black">
                {{ $t(`uiElements.text.noData`) }}
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>

  <bs-modal
    :modal-toggle-emit="showModal"
    :title="titleModal"
    @hideMainModal="showModal = false"
  >
    <template v-if="showFormGroup">
      <form class="needs-validation" @submit.prevent="submitFormRSO" novalidate>
        <!-- if type add  -->
        <!--  input-project  -->
        <div class="row mb-3" v-if="formVendorRSOType === 'add'">
          <label for="input-project" class="col-sm-6 col-form-label">
            {{ $t(`VendorRSO.table.project`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInput('project')"
              id="input-project"
              v-model="v$.formVendorRSO.project.$model"
              v-maska
              data-maska="##"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formVendorRSO.project.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formVendorRSO.project.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 2 }) }}
              </div>
              <div v-if="v$.formVendorRSO.project.minLength.$invalid">
                {{ $t(`Validations.minLength`, { num: 2 }) }}
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-3" v-if="formVendorRSOType === 'add'">
          <label for="input-vendorCode" class="col-sm-6 col-form-label">
            {{ $t(`VendorRSO.table.vendorCode`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInput('vendorCode')"
              id="input-vendorCode"
              v-model="v$.formVendorRSO.vendorCode.$model"
              v-maska
              data-maska="###"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formVendorRSO.vendorCode.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formVendorRSO.vendorCode.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 3 }) }}
              </div>
              <div v-if="v$.formVendorRSO.vendorCode.minLength.$invalid">
                {{ $t(`Validations.minLength`, { num: 3 }) }}
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-3" v-if="formVendorRSOType === 'add'">
          <label for="input-shortName" class="col-sm-6 col-form-label">
            {{ $t(`VendorRSO.table.shortName`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInput('shortName')"
              id="input-shortName"
              v-model="v$.formVendorRSO.shortName.$model"
              v-maska
              data-maska="@@@"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formVendorRSO.shortName.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formVendorRSO.shortName.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 3 }) }}
              </div>
              <div v-if="v$.formVendorRSO.shortName.minLength.$invalid">
                {{ $t(`Validations.minLength`, { num: 3 }) }}
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-3" v-if="formVendorRSOType === 'add'">
          <label for="input-year" class="col-sm-6 col-form-label">
            {{ $t(`VendorRSO.table.year`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInput('year')"
              id="input-year"
              v-model="v$.formVendorRSO.year.$model"
              v-maska
              data-maska="####"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formVendorRSO.year.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formVendorRSO.year.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 4 }) }}
              </div>
              <div v-if="v$.formVendorRSO.year.minLength.$invalid">
                {{ $t(`Validations.minLength`, { num: 4 }) }}
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-3" v-if="formVendorRSOType === 'add'">
          <label for="input-month" class="col-sm-6 col-form-label">
            {{ $t(`VendorRSO.table.month`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInput('month')"
              id="input-month"
              max="12"
              min="1"
              v-maska
              data-maska="##"
              v-model="v$.formVendorRSO.month.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formVendorRSO.month.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formVendorRSO.month.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 2 }) }}
              </div>
              <div v-if="v$.formVendorRSO.month.minLength.$invalid">
                {{ $t(`Validations.minLength`, { num: 2 }) }}
              </div>
            </div>
          </div>
        </div>
        <!-- end type add  -->

        <!--  input-name  -->
        <div class="row mb-3">
          <label for="input-name" class="col-sm-6 col-form-label">
            {{ $t(`VendorRSO.table.name`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInput('name')"
              id="input-name"
              v-model="v$.formVendorRSO.name.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formVendorRSO.name.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formVendorRSO.name.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 255 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-version  -->
        <div class="row mb-3">
          <label for="input-version" class="col-sm-6 col-form-label">
            {{ $t(`VendorRSO.table.version`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInput('version')"
              id="input-version"
              v-model="v$.formVendorRSO.version.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formVendorRSO.version.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formVendorRSO.version.reNumAndDot.$invalid">
                {{ $t(`Validations.reNumAndDot`) }}
              </div>
              <div v-if="v$.formVendorRSO.version.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 255 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-expiredDate  -->
        <div class="row mb-3">
          <label for="input-expiredDate" class="col-sm-6 col-form-label">
            {{ $t(`VendorRSO.table.expiredDate`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <Datepicker
              id="expiredDate-ff"
              :class="addClassValidInput('expiredDate')"
              v-model="v$.formVendorRSO.expiredDate.$model"
              autoApply
              locale="en"
              :format="dateFormat"
              required
            />
            <div class="invalid-feedback">
              <div v-if="v$.formVendorRSO.expiredDate.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  Buttons form  -->
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-outline-secondary me-3"
            @click="showModal = false"
          >
            {{ $t(`uiElements.btn.close`) }}
          </button>
          <button
            type="submit"
            class="btn btn-outline-primary"
            :disabled="v$.$invalid && v$.$dirty ? true : false"
          >
            {{ $t(`uiElements.btn.save`) }}
          </button>
        </div>
      </form>
    </template>
  </bs-modal>
  <div class="toast-container position-absolute p-3 end-0" v-if="showToast">
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import TrnLoading from "@/components/Loading";
import BsModal from "@/components/UI-elements/BsModal";
import BsToast from "@/components/UI-elements/BsToast";
import useVuelidate from "@vuelidate/core";
import {
  helpers,
  maxLength,
  minLength,
  required,
  requiredIf,
} from "@vuelidate/validators";
import { mapState } from "vuex";
import { stringify } from "query-string";
import { actionTypes } from "@/store/modules/settings-store/vendor-aidl";
import vendorRSOApi from "@/api/settings-api/vendor-rso";
import Datepicker from "vue3-date-time-picker";

export default {
  name: "TrnVendorRSO",
  components: {
    TrnLoading,
    BsModal,
    BsToast,
    Datepicker,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      page: 0,
      size: 100,
      sort: {
        col: "id",
        order: "asc",
      },
      showModal: false,
      titleModal: "",
      showFormGroup: false,
      formVendorRSO: {},
      formVendorRSOType: "",
      showToast: false,
      toastPropsList: [],
      selectedTableRow: null,
      dateFormat: "dd.MM.yyyy",
    };
  },
  validations() {
    return {
      formVendorRSO: {
        project: {
          required: requiredIf(() => this.formVendorRSOType === "add"),
          maxLength: maxLength(2),
          minLength: minLength(2),
        },
        vendorCode: {
          required: requiredIf(() => this.formVendorRSOType === "add"),
          maxLength: maxLength(3),
          minLength: minLength(3),
        },
        shortName: {
          required: requiredIf(() => this.formVendorRSOType === "add"),
          maxLength: maxLength(3),
          minLength: minLength(3),
        },
        year: {
          required: requiredIf(() => this.formVendorRSOType === "add"),
          maxLength: maxLength(4),
          minLength: minLength(4),
        },
        month: {
          required: requiredIf(() => this.formVendorRSOType === "add"),
          maxLength: maxLength(2),
          minLength: minLength(2),
        },
        name: {
          required,
          maxLength: maxLength(255),
        },
        version: {
          required,
          reNumAndDot: helpers.regex(/^[0-9.]+$/u), // input expression is only Latin, Cyrillic and numbers.
          maxLength: maxLength(255),
        },
        expiredDate: {
          required,
        },
      },
    };
  },
  computed: {
    dataResp: {
      get() {
        return this.$store.state.vendorRSO.dataResp;
      },
      set(newValue) {
        this.$store.state.vendorRSO.dataResp = newValue;
      },
    },
    ...mapState({
      isLoading: (state) => state.vendorRSO.isLoading,
      isLoadingScroll: (state) => state.vendorRSO.isLoadingScroll,
      dataModalResp: (state) => state.vendorRSO.dataModalResp,
      errorResp: (state) => state.vendorRSO.errorResp,
    }),
  },
  methods: {
    fetchVersion() {
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
      });
      this.$store.dispatch(actionTypes.getVendorRSO, {
        apiParams: stringifiedParams,
      });
    },
    scrollTable(event) {
      const currentEl = event.target;
      let checkScrollEnd =
        currentEl.scrollTop + currentEl.clientHeight + 3 >=
        currentEl.scrollHeight;
      if (checkScrollEnd && this.isLoadingScroll === false) {
        const currentPage = this.page + 1;
        const totalPages = this.dataResp.totalPages;
        if (currentPage < totalPages) this.loadMoreData();
      }
    },
    loadMoreData() {
      this.page += 1;
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
      });
      this.$store.dispatch(actionTypes.getVendorRSOScrollPush, {
        apiParams: stringifiedParams,
      });
    },
    reloadPage() {
      this.page = 0;
      this.fetchVersion();
    },
    addClassValidInput(inputName) {
      return {
        "is-invalid":
          this.v$.formVendorRSO[inputName].$error === true &&
          this.v$.formVendorRSO[inputName].$dirty === true,
        "is-valid":
          this.v$.formVendorRSO[inputName].$error === false &&
          this.v$.formVendorRSO[inputName].$dirty === true,
      };
    },
    clickTableRow(rowItem) {
      this.selectedTableRow = rowItem;
    },
    clickFormRSO(type) {
      this.formVendorRSOType = type;
      this.showModal = true;
      this.showFormGroup = true;
      if (type === "add") {
        this.selectedTableRow = null;
        this.formVendorRSO = {};
        this.titleModal = this.$t(`VendorRSO.table.addVendor`);
      } else {
        this.titleModal = this.selectedTableRow.name;
        this.formVendorRSO.name = this.selectedTableRow.name;
        this.formVendorRSO.version = this.selectedTableRow.version;
        this.formVendorRSO.expiredDate = this.$filters.formatDateTime(
          this.selectedTableRow.expiredDate,
          "date-obj"
        );
        this.formVendorRSO.id = this.selectedTableRow.id;
      }
    },
    submitFormRSO() {
      this.v$.formVendorRSO.$touch();
      if (!this.v$.formVendorRSO.$error) {
        this.formVendorRSO.expiredDate = new Date(
          this.formVendorRSO.expiredDate
        )
          .toISOString()
          .split("T")[0];
        if (this.formVendorRSOType === "add") {
          const vendorId =
            this.formVendorRSO.project +
            this.formVendorRSO.vendorCode +
            this.formVendorRSO.shortName +
            this.formVendorRSO.year +
            this.formVendorRSO.month;
          vendorRSOApi
            .addVendorRSO({
              name: this.formVendorRSO.name,
              version: this.formVendorRSO.version,
              expiredDate: this.formVendorRSO.expiredDate + " 00:00:00",
              id: vendorId,
            })
            .then(function (result) {
              return result;
            })
            .catch(function (result) {
              return result;
            })
            .then((result) => {
              // console.log("result", result);
              if (result.status === 200) {
                this.showModal = false;
                this.selectedTableRow = null;
                this.formVendorRSO = {};

                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.successSave`)}`,
                  bgColor: "bg-success",
                  textColor: "text-white",
                });
                this.showToast = true;

                this.fetchVersion();
              } else {
                this.showModal = false;
                this.selectedTableRow = null;
                console.log("err");
                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.errSave`)}`,
                  bgColor: "bg-danger",
                  textColor: "text-white",
                });
                this.showToast = true;
              }
            });
        } else {
          vendorRSOApi
            .updateVendorRSO({
              name: this.formVendorRSO.name,
              version: this.formVendorRSO.version,
              expiredDate: this.formVendorRSO.expiredDate + " 00:00:00",
              id: this.formVendorRSO.id,
            })
            .then(function (result) {
              return result;
            })
            .catch(function (result) {
              return result;
            })
            .then((result) => {
              // console.log("result", result);
              if (result.status === 200) {
                this.showModal = false;

                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.successSave`)}`,
                  bgColor: "bg-success",
                  textColor: "text-white",
                });
                this.showToast = true;

                this.fetchVersion();
              } else {
                this.showModal = false;
                this.selectedTableRow = null;
                console.log("err");
                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.errSave`)}`,
                  bgColor: "bg-danger",
                  textColor: "text-white",
                });
                this.showToast = true;
              }
            });
        }
      }
    },
  },
  watch: {
    "formVendorRSO.expiredDate"() {
      if (this.formVendorRSO.expiredDate) {
        let startDate = new Date();
        let endDate = new Date(this.formVendorRSO.expiredDate);
        if (startDate > endDate) {
          this.formVendorRSO.expiredDate = startDate;
        }
      }
    },
  },
  mounted() {
    this.fetchVersion();
  },
};
</script>

<style scoped>
.select-td > td {
  background-color: #0d6efd !important;
  color: white !important;
}
</style>
