<template>
  <div class="title-table mb-2 ps-3 py-1 fw-bold">
    {{ $t(`left-menu.merchantRegistration`) }}
  </div>
  <div class="toolbar mb-2">
    <div class="btn-group btn-group-sm ps-3" role="group">
      <button
        type="button"
        class="btn btn-outline-primary"
        :title="$t(`MerchantRegistration.btn.reloadPage`)"
        @click="reloadPage"
      >
        <i class="bi bi-arrow-clockwise"></i>
      </button>
      <!--Фільтри-->
      <button
        type="button"
        class="btn btn-outline-primary"
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
        aria-expanded="false"
        :title="$t(`MerchantRegistration.btn.filter`)"
        ref="dropdownBtnFilter"
      >
        <i class="bi bi-funnel-fill"></i>
        <span class="ms-2">{{ $t(`MerchantRegistration.btn.filter`) }}</span>
      </button>
      <button
        class="btn btn-outline-primary btn-sm"
        type="button"
        @click="showModalViewTableSettings"
        title="Налаштування вигляду таблиці"
      >
        <i class="bi bi-table"></i>
      </button>
      <form
        class="dropdown-menu p-4"
        style="width: 500px"
        @submit.prevent="formFilterRm"
        ref="dropdownFormFilter"
      >
        <div class="row mb-3">
          <label class="col-sm-4 col-form-label col-form-label-sm">{{
            $t(`MerchantRegistration.table.registrationDate`)
          }}</label>
          <div class="col-sm-8">
            <Datepicker
              id="registrationDateRange-ff"
              class=""
              v-model="registrationDateRange"
              range
              autoApply
              :clearable="false"
              locale="ru"
              :format="registrationDateRangeFormat"
              :previewFormat="registrationDateRangeFormat"
            />
          </div>
        </div>
        <div class="row mb-3">
          <label
            for="merchantName-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`MerchantRegistration.table.merchantName`) }}</label
          >
          <div class="col-sm-8">
            <input
              v-model.trim="filters.merchantName"
              type="text"
              class="form-control form-control-sm"
              id="merchantName-ff"
              :placeholder="$t(`Validations.notChosen`)"
            />
          </div>
        </div>
        <div class="row mb-3">
          <label
            for="phoneNumber-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`MerchantRegistration.table.phoneNumber`) }}</label
          >
          <div class="col-sm-8">
            <input
              v-model.trim="filters.phoneNumber"
              type="text"
              class="form-control form-control-sm"
              id="phoneNumber-ff"
              :placeholder="$t(`Validations.notChosen`)"
            />
          </div>
        </div>
        <div class="row mb-3">
          <label
            for="merchantId-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >Merchant ID</label
          >
          <div class="col-sm-8">
            <input
              v-model.trim="filters.merchantId"
              type="text"
              class="form-control form-control-sm"
              id="merchantId-ff"
              :placeholder="$t(`Validations.notChosen`)"
            />
          </div>
        </div>

        <div class="justify-content-end d-flex">
          <button
            class="btn btn-outline-primary btn-sm me-3"
            type="button"
            @click="resetFilterForm"
          >
            <i class="bi bi-x-square me-1"></i>
            <span>{{ $t(`uiElements.btn.resetFilters`) }}</span>
          </button>
          <button type="submit" class="btn btn-primary btn-sm">
            <i class="bi bi-clipboard-check me-1"></i>
            <span>{{ $t(`uiElements.btn.apply`) }}</span>
          </button>
        </div>
      </form>
      <!--Кінець фільтрів-->
      <button
        type="button"
        class="btn btn-outline-primary"
        :title="$t(`MerchantRegistration.btn.addNewMerchant`)"
        @click="addMerchantManualForm"
      >
        <i class="bi bi-shop-window"></i>
        <span class="ms-2">{{
          $t(`MerchantRegistration.btn.addNewMerchant`)
        }}</span>
      </button>
      <button
        type="button"
        class="btn btn-outline-primary"
        :title="$t(`Merchant.modal.deleteMerchant.title`)"
        @click="showDeleteMerchantForm"
      >
        <i class="bi bi-person-x"></i>
      </button>
      <button
        type="button"
        class="btn btn-outline-primary"
        :title="$t(`MerchantRegistration.btn.sendSmsPass`)"
        :disabled="btnSendSms"
        @click="sendSms"
      >
        <i class="bi bi-envelope-plus"></i>
        <span class="ms-2">{{
          $t(`MerchantRegistration.btn.sendSmsPass`)
        }}</span>
      </button>
    </div>
  </div>

  <div
    class="table-responsive table-block overflow-auto"
    id="table-block"
    @scroll="scrollTable"
  >
    <table
      class="table table-striped table-hover table-sm table-light text-center"
    >
      <thead>
        <tr>
          <th scope="col" class="th-activation-code">
            {{ $t(`MerchantRegistration.table.ReActivationCode`) }}
          </th>
          <th
            scope="col"
            v-show="
              columnList.find((item) => item.key === 'merchantRegId').visible
            "
          >
            {{ $t(`MerchantRegistration.table.merchantRegId`) }}
          </th>
          <th
            scope="col"
            v-show="
              columnList.find((item) => item.key === 'createdDate').visible
            "
            data-col-sort="createdDate"
            @click="clickSortCol($event, 'createdDate')"
          >
            {{ $t(`MerchantRegistration.table.createdDate`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>
          <th
            scope="col"
            v-show="
              columnList.find((item) => item.key === 'registrationDate').visible
            "
            data-col-sort="registrationDate"
            @click="clickSortCol($event, 'registrationDate')"
          >
            {{ $t(`MerchantRegistration.table.registrationDate`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>
          <th
            scope="col"
            v-show="columnList.find((item) => item.key === 'action').visible"
          >
            {{ $t(`MerchantRegistration.table.action`) }}
          </th>
          <th
            scope="col"
            v-show="columnList.find((item) => item.key === 'status').visible"
          >
            {{ $t(`MerchantRegistration.table.status`) }}
          </th>
          <th
            scope="col"
            v-show="
              columnList.find((item) => item.key === 'merchantName').visible
            "
          >
            {{ $t(`MerchantRegistration.table.merchantName`) }}
          </th>
          <th
            scope="col"
            v-show="
              columnList.find((item) => item.key === 'merchantNameEng').visible
            "
          >
            {{ $t(`MerchantRegistration.table.merchantNameEng`) }}
          </th>
          <th
            scope="col"
            v-show="
              columnList.find((item) => item.key === 'merchantLegalName')
                .visible
            "
          >
            {{ $t(`MerchantRegistration.table.merchantLegalName`) }}
          </th>
          <th
            scope="col"
            v-show="columnList.find((item) => item.key === 'iban').visible"
          >
            {{ $t(`MerchantRegistration.table.iban`) }}
          </th>
          <th
            scope="col"
            v-show="
              columnList.find((item) => item.key === 'phoneNumber').visible
            "
          >
            {{ $t(`MerchantRegistration.table.phoneNumber`) }}
          </th>
          <th
            scope="col"
            v-show="columnList.find((item) => item.key === 'taxId').visible"
          >
            {{ $t(`MerchantRegistration.table.taxId`) }}
          </th>
          <th
            scope="col"
            v-show="columnList.find((item) => item.key === 'mcc').visible"
          >
            MCC
          </th>
          <th
            scope="col"
            v-show="columnList.find((item) => item.key === 'userLogin').visible"
          >
            {{ $t(`MerchantRegistration.table.userLogin`) }}
          </th>
          <th
            scope="col"
            v-show="
              columnList.find((item) => item.key === 'merchantId').visible
            "
          >
            Merchant ID
          </th>
          <th
            scope="col"
            v-show="
              columnList.find((item) => item.key === 'terminalId').visible
            "
          >
            Terminal ID
          </th>
          <th
            scope="col"
            v-show="
              columnList.find((item) => item.key === 'serviceGroupGroupNumber')
                .visible
            "
          >
            {{ $t(`MerchantRegistration.table.serviceGroupGroupNumber`) }}
          </th>
          <th
            scope="col"
            v-show="
              columnList.find((item) => item.key === 'merchantLocation').visible
            "
          >
            {{ $t(`MerchantRegistration.table.merchantLocation`) }}
          </th>
          <th
            scope="col"
            v-show="
              columnList.find((item) => item.key === 'merchantLocationEng')
                .visible
            "
          >
            {{ $t(`MerchantRegistration.table.merchantLocationEng`) }}
          </th>
          <th
            scope="col"
            v-show="columnList.find((item) => item.key === 'latitude').visible"
          >
            {{ $t(`MerchantRegistration.table.latitude`) }}
          </th>
          <th
            scope="col"
            v-show="columnList.find((item) => item.key === 'longitude').visible"
          >
            {{ $t(`MerchantRegistration.table.longitude`) }}
          </th>
          <th
            scope="col"
            v-show="columnList.find((item) => item.key === 'radius').visible"
          >
            {{ $t(`MerchantRegistration.table.radius`) }}
          </th>
          <th
            scope="col"
            v-show="
              columnList.find((item) => item.key === 'zreportTime').visible
            "
          >
            {{ $t(`MerchantRegistration.table.zreportTime`) }}
          </th>
          <th
            scope="col"
            v-show="
              columnList.find((item) => item.key === 'termPriority').visible
            "
          >
            {{ $t(`MerchantRegistration.table.termPriority`) }}
          </th>
          <th
            scope="col"
            v-show="
              columnList.find((item) => item.key === 'numSecTerm').visible
            "
          >
            {{ $t(`MerchantRegistration.table.numSecTerm`) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="isLoading">
          <tr>
            <td colspan="16">
              <div class="loading text-center">
                <trn-loading></trn-loading>
              </div>
            </td>
          </tr>
        </template>
        <template v-if="dataResp">
          <tr v-for="item in dataResp.page.content" :key="item">
            <td class="align-middle">
              <input
                class="form-check-input"
                type="checkbox"
                :value="item.terminalId"
                v-model="checkBoxSendSms"
              />
            </td>
            <td
              class=""
              v-show="
                columnList.find((item) => item.key === 'merchantRegId').visible
              "
            >
              {{ item.id }}
            </td>
            <td
              class=""
              v-show="
                columnList.find((item) => item.key === 'createdDate').visible
              "
            >
              {{ $filters.formatDateTime(item.createdDate) }}
            </td>
            <td
              class=""
              v-show="
                columnList.find((item) => item.key === 'registrationDate')
                  .visible
              "
            >
              {{ $filters.formatDateTime(item.registrationDate) }}
            </td>
            <td
              class="text-nowrap"
              v-show="columnList.find((item) => item.key === 'action').visible"
            >
              <button
                type="button"
                class="btn mt-1"
                @click="showFormEditMerchantStatus(item)"
                v-if="
                  item.status !== 'DELETED' && item.status !== 'DELETED_BY_USER'
                "
              >
                <i class="bi bi-person-fill-gear"></i>
              </button>
              <div v-else>-</div>
            </td>
            <td
              class="text-nowrap"
              v-show="columnList.find((item) => item.key === 'status').visible"
            >
              {{ item.status }}
            </td>
            <td
              class="text-nowrap"
              v-show="
                columnList.find((item) => item.key === 'merchantName').visible
              "
            >
              {{ item.merchantName }}
            </td>
            <td
              class="text-nowrap"
              v-show="
                columnList.find((item) => item.key === 'merchantNameEng')
                  .visible
              "
            >
              {{ item.merchantNameEng }}
            </td>
            <td
              class="text-nowrap"
              v-show="
                columnList.find((item) => item.key === 'merchantLegalName')
                  .visible
              "
            >
              {{ item.merchantLegalName }}
            </td>
            <td
              class="text-nowrap"
              v-show="columnList.find((item) => item.key === 'iban').visible"
            >
              {{ item.iban }}
            </td>
            <td
              class="text-nowrap"
              v-show="
                columnList.find((item) => item.key === 'phoneNumber').visible
              "
            >
              {{ item.phoneNumber }}
            </td>
            <td
              class="text-nowrap"
              v-show="columnList.find((item) => item.key === 'taxId').visible"
            >
              {{ item.taxId }}
            </td>
            <td
              class="text-nowrap"
              v-show="columnList.find((item) => item.key === 'mcc').visible"
            >
              {{ item.mcc }}
            </td>
            <td
              class="text-nowrap"
              v-show="
                columnList.find((item) => item.key === 'userLogin').visible
              "
            >
              {{ item.userLogin }}
            </td>
            <td
              class="text-nowrap"
              v-show="
                columnList.find((item) => item.key === 'merchantId').visible
              "
            >
              {{ item.merchantId }}
            </td>
            <td
              class="text-nowrap"
              v-show="
                columnList.find((item) => item.key === 'terminalId').visible
              "
            >
              {{ item.terminalId }}
            </td>
            <td
              class="text-nowrap"
              v-show="
                columnList.find(
                  (item) => item.key === 'serviceGroupGroupNumber'
                ).visible
              "
            >
              {{ item.serviceGroup.groupNumber }}
            </td>
            <td
              class="text-nowrap"
              v-show="
                columnList.find((item) => item.key === 'merchantLocation')
                  .visible
              "
            >
              {{ item.merchantLocation }}
            </td>
            <td
              class="text-nowrap"
              v-show="
                columnList.find((item) => item.key === 'merchantLocationEng')
                  .visible
              "
            >
              {{ item.merchantLocationEng }}
            </td>
            <td
              class="text-nowrap"
              v-show="
                columnList.find((item) => item.key === 'latitude').visible
              "
            >
              {{ item.latitude }}
            </td>
            <td
              class="text-nowrap"
              v-show="
                columnList.find((item) => item.key === 'longitude').visible
              "
            >
              {{ item.longitude }}
            </td>
            <td
              class="text-nowrap"
              v-show="columnList.find((item) => item.key === 'radius').visible"
            >
              {{ item.radius }}
            </td>
            <td
              class="text-nowrap"
              v-show="
                columnList.find((item) => item.key === 'zreportTime').visible
              "
            >
              {{ item.zreportTime }}
            </td>
            <td
              class="text-nowrap"
              v-show="
                columnList.find((item) => item.key === 'termPriority').visible
              "
            >
              {{ item.termPriority }}
            </td>
            <td
              class="text-nowrap"
              v-show="
                columnList.find((item) => item.key === 'numSecTerm').visible
              "
            >
              {{ item.numSecTerm }}
            </td>
          </tr>
          <template v-if="isLoadingScroll">
            <tr>
              <td colspan="22">
                <div class="loading text-center">
                  <trn-loading></trn-loading>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>
  <div class="toast-container position-absolute p-3 end-0" v-if="showToast">
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
  <bs-modal
    :modal-toggle-emit="showModal"
    @hideMainModal="showModal = false"
    :title="titleModal"
  >
    <trn-merchant-registration-form
      @hideMainModal="showModal = false"
      @reloadPage="reloadPage"
      :all-banks="dataResp.allBanks"
      :all-service-groups="dataResp.allServiceGroups"
      v-if="showFormName === 'showFormReg'"
    ></trn-merchant-registration-form>
    <trn-merchant-registration-edit-status
      v-if="showFormName === 'showFormEditMerchantStatus'"
      :merchant-obj="merchantEditObj"
      @hideMainModal="showModal = false"
      @showToastContent="pushToastContent"
      @update-page="reloadPage"
    ></trn-merchant-registration-edit-status>
    <trn-table-column-settings
      v-if="showFormName === 'tableColumnSettings'"
      :columns="columnList"
      @update-columns="changeVisibleColumns"
      page-table-view-name="visibleColumnsTableTransactionList"
    ></trn-table-column-settings>
    <trn-merchant-registration-delete-form
      v-if="showFormName === 'showDelete'"
      :key="Math.random()"
      @hideMainModal="showModal = false"
      @showToastContent="pushToastContent"
      @update-page="reloadPage"
    ></trn-merchant-registration-delete-form>
  </bs-modal>
</template>

<script>
import TrnLoading from "@/components/Loading";
import Datepicker from "vue3-date-time-picker";
import { stringify } from "query-string";
import { actionTypes } from "@/store/modules/merchant-registration";
import { mapState } from "vuex";
import BsToast from "@/components/UI-elements/BsToast";
import BsModal from "@/components/UI-elements/BsModal";
import TrnMerchantRegistrationForm from "@/components/block-pages/MerchantRegistrationForm";
import TrnMerchantRegistrationEditStatus from "@/components/block-pages/MerchantRegistrationEditStatus.vue";
import TrnTableColumnSettings from "@/components/TableColumnSettings.vue";
import { getItemLocalStorage } from "@/helpers/persistanceStorage";
import TrnMerchantRegistrationDeleteForm from "@/components/block-pages/MerchantRegistrationDeleteForm.vue";

export default {
  name: "TrnMerchantRegistration",
  components: {
    TrnMerchantRegistrationDeleteForm,
    TrnTableColumnSettings,
    TrnMerchantRegistrationEditStatus,
    TrnMerchantRegistrationForm,
    BsModal,
    BsToast,
    TrnLoading,
    Datepicker,
  },
  data() {
    return {
      page: 0,
      size: 50,
      sort: {
        col: "createdDate",
        order: "desc",
      },
      filters: {
        startRegistrationDate: "",
        endRegistrationDate: "",
        merchantName: "",
        phoneNumber: "",
        merchantId: "",
      },
      checkBoxSendSms: [],
      btnSendSms: true,
      registrationDateRange: [],
      registrationDateRangeFormat: "dd/MM/yyyy",
      titleModal: "",
      showToast: true,
      showModal: false,
      showFormName: "",
      toastPropsList: [],
      merchantEditObj: null,
      columnList: [
        {
          title: this.$t(`MerchantRegistration.table.merchantRegId`),
          key: "merchantRegId",
          visible: false,
          required: false,
        },
        {
          title: this.$t(`MerchantRegistration.table.createdDate`),
          key: "createdDate",
          visible: false,
          required: false,
        },
        {
          title: this.$t(`MerchantRegistration.table.registrationDate`),
          key: "registrationDate",
          visible: true,
          required: true,
        },
        {
          title: this.$t(`MerchantRegistration.table.action`),
          key: "action",
          visible: true,
          required: true,
        },
        {
          title: this.$t(`MerchantRegistration.table.status`),
          key: "status",
          visible: true,
          required: true,
        },
        {
          title: this.$t(`MerchantRegistration.table.merchantName`),
          key: "merchantName",
          visible: false,
          required: false,
        },
        {
          title: this.$t(`MerchantRegistration.table.merchantNameEng`),
          key: "merchantNameEng",
          visible: false,
          required: false,
        },
        {
          title: this.$t(`MerchantRegistration.table.merchantLegalName`),
          key: "merchantLegalName",
          visible: false,
          required: false,
        },
        {
          title: this.$t(`MerchantRegistration.table.iban`),
          key: "iban",
          visible: false,
          required: false,
        },
        {
          title: this.$t(`MerchantRegistration.table.phoneNumber`),
          key: "phoneNumber",
          visible: false,
          required: false,
        },
        {
          title: this.$t(`MerchantRegistration.table.taxId`),
          key: "taxId",
          visible: false,
          required: false,
        },
        {
          title: this.$t(`MerchantRegistration.table.mcc`),
          key: "mcc",
          visible: false,
          required: false,
        },
        {
          title: this.$t(`MerchantRegistration.table.userLogin`),
          key: "userLogin",
          visible: true,
          required: true,
        },
        {
          title: this.$t(`MerchantRegistration.table.merchantId`),
          key: "merchantId",
          visible: true,
          required: true,
        },
        {
          title: this.$t(`MerchantRegistration.table.terminalId`),
          key: "terminalId",
          visible: true,
          required: true,
        },
        {
          title: this.$t(`MerchantRegistration.table.serviceGroupGroupNumber`),
          key: "serviceGroupGroupNumber",
          visible: false,
          required: false,
        },
        {
          title: this.$t(`MerchantRegistration.table.merchantLocation`),
          key: "merchantLocation",
          visible: false,
          required: false,
        },
        {
          title: this.$t(`MerchantRegistration.table.merchantLocationEng`),
          key: "merchantLocationEng",
          visible: false,
          required: false,
        },
        {
          title: this.$t(`MerchantRegistration.table.latitude`),
          key: "latitude",
          visible: false,
          required: false,
        },
        {
          title: this.$t(`MerchantRegistration.table.longitude`),
          key: "longitude",
          visible: false,
          required: false,
        },
        {
          title: this.$t(`MerchantRegistration.table.radius`),
          key: "radius",
          visible: false,
          required: false,
        },
        {
          title: this.$t(`MerchantRegistration.table.zreportTime`),
          key: "zreportTime",
          visible: false,
          required: false,
        },
        {
          title: this.$t(`MerchantRegistration.table.termPriority`),
          key: "termPriority",
          visible: false,
          required: false,
        },
        {
          title: this.$t(`MerchantRegistration.table.numSecTerm`),
          key: "numSecTerm",
          visible: false,
          required: false,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.merchantRegistration.isLoading,
      isLoadingScroll: (state) => state.merchantRegistration.isLoadingScroll,
      dataResp: (state) => state.merchantRegistration.dataResp,
      dataModalResp: (state) => state.merchantRegistration.dataModalResp,
      errorResp: (state) => state.merchantRegistration.errorResp,
    }),
  },
  methods: {
    fetchAllRegistration() {
      this.changeArrowIcon();
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
        ...this.filters,
      });
      this.$store.dispatch(actionTypes.getAllRegistration, {
        apiParams: stringifiedParams,
      });
    },
    scrollTable(event) {
      const currentEl = event.target;
      let checkScrollEnd =
        currentEl.scrollTop + currentEl.clientHeight + 3 >=
        currentEl.scrollHeight;
      if (checkScrollEnd && this.isLoadingScroll === false) {
        const currentPage = this.page + 1;
        const totalPages = this.dataResp.page.totalPages;
        if (currentPage < totalPages) this.loadMoreData();
      }
    },
    loadMoreData() {
      this.page += 1;
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
      });
      this.$store.dispatch(actionTypes.getAllRegistrationScrollPage, {
        apiParams: stringifiedParams,
      });
    },
    reloadPage() {
      this.page = 0;
      this.fetchAllRegistration();
    },
    clickSortCol(event, newSortItem) {
      const scrollTableEl = document.getElementById("table-block");
      const beforeSortKey = this.sort.col;
      const beforeSortDirection = this.sort.order;

      const beforeEl = document.querySelectorAll(
        `[data-col-sort~=${beforeSortKey}]`
      );
      beforeEl[0].children[0].innerHTML = '<i class="bi bi-arrow-down-up"></i>';

      // let newSortVal = [newSortItem, ""];

      if (beforeSortDirection === "desc" && beforeSortKey === newSortItem) {
        this.sort.order = "asc";
      } else {
        this.sort.order = "desc";
      }
      this.page = 0;
      this.sort.col = newSortItem;
      scrollTableEl.scrollTo(0, 0);
      this.fetchAllRegistration();
    },
    changeArrowIcon(sortKey = this.sort.col, sortDirection = this.sort.order) {
      const targetEl = document.querySelectorAll(`[data-col-sort~=${sortKey}]`);
      if (sortDirection === "asc") {
        targetEl[0].children[0].innerHTML =
          '<i class="bi bi-arrow-up-square"></i>';
      } else {
        targetEl[0].children[0].innerHTML =
          '<i class="bi bi-arrow-down-square"></i>';
      }
    },
    formFilterRm() {
      this.$refs.dropdownBtnFilter.click();
      this.fetchAllRegistration();
    },
    resetFilterForm() {
      for (let item in this.filters) {
        this.filters[item] = "";
      }
      this.registrationDateRange = ["", ""];
      this.$refs.dropdownBtnFilter.click();
      this.fetchAllRegistration();
    },
    sendSms() {
      let smsPhoneList = [];
      for (let index in this.checkBoxSendSms) {
        const findPhone = this.dataResp.page.content.find(
          (tid) => tid.terminalId === this.checkBoxSendSms[index]
        ).phoneNumber;
        smsPhoneList.push(findPhone);
      }
      const stringifiedParams = this.checkBoxSendSms.join(",");
      this.$store.dispatch(actionTypes.sendSms, {
        loginsString: stringifiedParams,
      });
      this.btnSendSms = true;
      this.checkBoxSendSms = [];

      const phoneString = smsPhoneList.join("<br>");

      this.toastPropsList.push({
        title: this.$t("MerchantRegistration.table.ReActivationCode"),
        content: `${this.$t(`MerchantRegistration.toast.sendSms`, {
          numbers: phoneString,
        })}`,
      });
      this.showToast = true;
    },
    showFormEditMerchantStatus(value) {
      this.titleModal = this.$t(
        "MerchantRegistration.titleModalMerchantStatus"
      );
      this.merchantEditObj = value;
      this.showFormName = "showFormEditMerchantStatus";
      this.showModal = !this.showModal;
    },
    addMerchantManualForm() {
      this.titleModal = this.$t("left-menu.merchantRegistration");
      this.showAddFata = false;
      this.showFormName = "showFormReg";
      this.showModal = !this.showModal;
    },
    showDeleteMerchantForm() {
      this.titleModal = this.$t("Merchant.modal.deleteMerchant.title");
      this.showFormName = "showDelete";
      this.showModal = !this.showModal;
    },
    pushToastContent(val) {
      this.toastPropsList.push(val);
    },
    showModalViewTableSettings() {
      this.titleModal = this.$t(`uiElements.text.tableColumnSettings`);
      this.showFormName = "tableColumnSettings";
      this.showModal = !this.showModal;
    },
    changeVisibleColumns() {
      const visibleList = getItemLocalStorage(
        "visibleColumnsTableTransactionList"
      );

      if (!visibleList) {
        return;
      }

      this.columnList.forEach((item) => {
        const findItem = visibleList.find((el) => el.key === item.key);
        if (findItem) {
          item.visible = findItem.visible;
        }
      });
    },
  },
  watch: {
    registrationDateRange() {
      if (
        this.registrationDateRange[0] !== "" &&
        this.registrationDateRange[1] !== ""
      ) {
        const nowDate = new Date();
        if (this.registrationDateRange[0] > nowDate) {
          this.registrationDateRange[0] = nowDate;
        }

        if (this.registrationDateRange[1] > nowDate) {
          this.registrationDateRange[1] = nowDate;
        }

        let startRegistrationDate = new Date(this.registrationDateRange[0]);
        startRegistrationDate.setHours(0, 0, 0, 0)
        this.filters.startRegistrationDate = startRegistrationDate.toISOString();
        let endRegistrationDate = new Date(this.registrationDateRange[1]);
        endRegistrationDate.setHours(0, 0, 0, 0)
        this.filters.endRegistrationDate = endRegistrationDate.toISOString();
      }
    },
    checkBoxSendSms: function () {
      this.btnSendSms = this.checkBoxSendSms.length <= 0;
    },
  },
  mounted() {
    this.fetchAllRegistration();
    this.changeVisibleColumns();
  },
};
</script>

<style scoped>
.th-activation-code {
  width: 50px;
}
</style>
