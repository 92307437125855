<template>
  <div class="title-table mb-2 ps-3 py-1 fw-bold">
    {{ $t(`left-menu.devices`) }}
  </div>
  <!-- Фільтр -->
  <div class="toolbar">
    <div class="btn-group mb-2">
      <button
        class="btn btn-outline-primary btn-sm dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
        aria-expanded="false"
        ref="dropdownBtnFilter"
      >
        <i class="bi bi-filter-square me-2"></i>
        <span>{{ $t(`uiElements.btn.filters`) }}</span>
      </button>
      <button
        class="btn btn-outline-primary btn-sm"
        type="button"
        @click="resetFilterForm"
      >
        <i class="bi bi-x-square"></i>
      </button>
      <form
        class="dropdown-menu p-4"
        style="width: 500px"
        @submit.prevent="formFilterRm"
        ref="dropdownFormFilter"
      >
        <div class="row mb-3">
          <label
            for="deviceSn-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`Devices.table.deviceSn`) }}</label
          >
          <div class="col-sm-8">
            <input
              v-model.trim="filters.deviceSn"
              type="text"
              class="form-control form-control-sm"
              id="deviceSn-ff"
              :placeholder="$t(`Validations.notChosen`)"
            />
          </div>
        </div>

        <div class="row mb-3">
          <label
            for="terminalId-ff"
            class="col-sm-4 col-form-label col-form-label-sm"
            >{{ $t(`Devices.table.terminalId`) }}</label
          >
          <div class="col-sm-8">
            <input
              v-model.trim="filters.terminalId"
              type="text"
              class="form-control form-control-sm"
              id="terminalId-ff"
              :placeholder="$t(`Validations.notChosen`)"
            />
          </div>
        </div>

        <!--  input-deviceStatus  -->
        <div class="row mb-3">
          <label
            for="input-status"
            class="col-sm-4 col-form-label col-form-label-sm"
          >
            {{ $t(`Devices.table.deviceStatus`) }}
          </label>
          <div class="col-sm-8">
            <select
              class="form-select form-select-sm"
              id="input-status"
              v-model="filters.deviceStatus"
            >
              <option value="A">
                {{ $t(`Devices.deviceStatus.Active`) }}
              </option>
              <option value="D">
                {{ $t(`Devices.deviceStatus.Delete`) }}
              </option>
              <option value="B">
                {{ $t(`Devices.deviceStatus.Blocked`) }}
              </option>
              <option value="K">
                {{ $t(`Devices.deviceStatus.Key`) }}
              </option>
              <option value="L">
                {{ $t(`Devices.deviceStatus.Loan`) }}
              </option>
              <option value="UB">
                {{ $t(`Devices.deviceStatus.Unblocked`) }}
              </option>
            </select>
          </div>
        </div>

        <div class="justify-content-end d-flex">
          <button
            class="btn btn-outline-primary btn-sm me-3"
            type="button"
            @click="resetFilterForm"
          >
            <i class="bi bi-x-square me-1"></i>
            <span>{{ $t(`uiElements.btn.resetFilters`) }}</span>
          </button>
          <button type="submit" class="btn btn-primary btn-sm">
            <i class="bi bi-clipboard-check me-1"></i>
            <span>{{ $t(`uiElements.btn.apply`) }}</span>
          </button>
        </div>
      </form>
    </div>
  </div>

  <!-- Фільтр кінець -->

  <div
    class="table-responsive table-block overflow-auto"
    id="table-block"
    @scroll="scrollTable"
  >
    <table class="table table-striped table-hover table-sm table-light">
      <thead>
        <tr class="text-center">
          <th
            scope="col"
            data-col-sort="deviceSn"
            @click="clickSortCol($event, 'deviceSn')"
          >
            {{ $t(`Devices.table.deviceSn`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="deviceName"
            @click="clickSortCol($event, 'deviceName')"
          >
            {{ $t(`Devices.table.deviceName`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="osVersion"
            @click="clickSortCol($event, 'osVersion')"
          >
            {{ $t(`Devices.table.osVersion`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="initDate"
            @click="clickSortCol($event, 'initDate')"
          >
            {{ $t(`Devices.table.initDate`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="deviceStatus"
            @click="clickSortCol($event, 'deviceStatus')"
          >
            {{ $t(`Devices.table.deviceStatus`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="appStatus"
            @click="clickSortCol($event, 'appStatus')"
          >
            {{ $t(`Devices.table.appStatus`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>

          <th
            scope="col"
            data-col-sort="deviceFingerprint"
            @click="clickSortCol($event, 'deviceFingerprint')"
          >
            {{ $t(`Devices.table.deviceFingerprint`) }}
            <div class="icon-sort"><i class="bi bi-arrow-down-up"></i></div>
          </th>
          <th scope="col">
            {{ $t(`Devices.table.terminalId`) }}
            <div class="icon-sort"><i class="bi bi-app"></i></div>
          </th>
        </tr>
      </thead>
      <tbody class="text-nowrap">
        <template v-if="isLoading">
          <tr>
            <td colspan="16">
              <div class="loading text-center">
                <trn-loading></trn-loading>
              </div>
            </td>
          </tr>
        </template>
        <template v-if="dataResp">
          <tr
            v-for="item in dataResp.content"
            :key="item"
            class="text-center cursor-pointer"
            @click="showUpdateDeviceForm(item)"
          >
            <td>{{ item.deviceSn }}</td>
            <td>{{ item.deviceName }}</td>
            <td>{{ item.osVersion }}</td>
            <td>{{ $filters.formatDateTime(item.initDate) }}</td>
            <td>{{ item.deviceStatus }}</td>
            <td>{{ item.appStatus }}</td>
            <td>{{ item.deviceFingerprint }}</td>
            <td>
              {{
                item.terminal?.terminalId ? item.terminal.terminalId : "- - -"
              }}
            </td>
          </tr>
          <template v-if="isLoadingScroll">
            <tr>
              <td colspan="16">
                <div class="loading text-center">
                  <trn-loading></trn-loading>
                </div>
              </td>
            </tr>
          </template>
          <template v-if="dataResp?.content.length === 0">
            <tr>
              <td colspan="16">
                <div class="text-center fw-bold bg-info text-black">
                  {{ $t(`uiElements.text.noData`) }}
                </div>
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>

  <bs-modal
    :modal-toggle-emit="showModal"
    :title="titleModal"
    @hideMainModal="showModal = false"
  >
    <form
      class="needs-validation"
      @submit.prevent="submitUpdateDevice"
      novalidate
    >
      <!--  input-deviceSn  -->
      <div class="row mb-3">
        <label for="input-deviceID" class="col-sm-6 col-form-label">
          {{ $t(`Devices.table.deviceSn`) }}
          <span class="text-danger">*</span>
        </label>
        <div class="col-sm-6">
          <input
            type="text"
            class="form-control"
            id="input-deviceID"
            v-model="v$.formDeviceUpdate.deviceId.$model"
            disabled
          />
          <div class="invalid-feedback">
            <div v-if="v$.formDeviceUpdate.deviceId.required.$invalid">
              {{ $t(`Validations.required`) }}
            </div>
          </div>
        </div>
      </div>

      <!--  input-deviceStatus  -->
      <div class="row mb-3">
        <label for="input-status" class="col-sm-6 col-form-label">
          {{ $t(`Devices.table.deviceStatus`) }}
          <span class="text-danger">*</span>
        </label>
        <div class="col-sm-6">
          <select
            class="form-select"
            id="input-status"
            v-model="v$.formDeviceUpdate.deviceStatus.$model"
          >
            <option value="A">
              {{ $t(`Devices.deviceStatus.Active`) }}
            </option>
            <option value="D">
              {{ $t(`Devices.deviceStatus.Delete`) }}
            </option>
            <option value="B">
              {{ $t(`Devices.deviceStatus.Blocked`) }}
            </option>
            <option value="K">
              {{ $t(`Devices.deviceStatus.Key`) }}
            </option>
            <option value="L">
              {{ $t(`Devices.deviceStatus.Loan`) }}
            </option>
            <option value="UB">
              {{ $t(`Devices.deviceStatus.Unblocked`) }}
            </option>
          </select>
          <div class="invalid-feedback">
            <div v-if="v$.formDeviceUpdate.deviceStatus.required.$invalid">
              {{ $t(`Validations.required`) }}
            </div>
          </div>
        </div>
      </div>

      <!--  Buttons form  -->
      <div class="d-flex justify-content-end">
        <button
          type="button"
          class="btn btn-outline-secondary me-3"
          @click="showModal = false"
        >
          {{ $t(`uiElements.btn.close`) }}
        </button>
        <button
          type="submit"
          class="btn btn-outline-primary"
          :disabled="v$.$invalid && v$.$dirty ? true : false"
        >
          {{ $t(`uiElements.btn.save`) }}
        </button>
      </div>
    </form>
  </bs-modal>
  <div class="toast-container position-absolute p-3 end-0" v-if="showToast">
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import TrnLoading from "@/components/Loading";
import BsModal from "@/components/UI-elements/BsModal";
import BsToast from "@/components/UI-elements/BsToast";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapState } from "vuex";
import { stringify } from "query-string";
import { actionTypes } from "@/store/modules/devices";
import devicesApi from "@/api/devices";

export default {
  name: "TrnDevices",
  components: {
    TrnLoading,
    BsModal,
    BsToast,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      page: 0,
      size: 100,
      sort: {
        col: "deviceSn",
        order: "desc",
      },
      filters: {
        deviceSn: "",
      },
      showModal: false,
      titleModal: "",
      formDeviceUpdate: {},
      showToast: false,
      toastPropsList: [],
    };
  },
  validations() {
    return {
      formDeviceUpdate: {
        deviceStatus: {
          required,
        },
        deviceId: {
          required,
        },
      },
    };
  },
  computed: {
    dataResp: {
      get() {
        return this.$store.state.devices.dataResp;
      },
      set(newValue) {
        this.$store.state.devices.dataResp = newValue;
      },
    },
    ...mapState({
      isLoading: (state) => state.devices.isLoading,
      isLoadingScroll: (state) => state.devices.isLoadingScroll,
      dataModalResp: (state) => state.devices.dataModalResp,
      errorResp: (state) => state.devices.errorResp,
    }),
  },
  methods: {
    fetchDevices() {
      this.changeArrowIcon();
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
        ...this.filters,
      });
      this.$store.dispatch(actionTypes.getDevices, {
        apiParams: stringifiedParams,
      });
    },
    scrollTable(event) {
      const currentEl = event.target;
      let checkScrollEnd =
        currentEl.scrollTop + currentEl.clientHeight + 3 >=
        currentEl.scrollHeight;
      if (checkScrollEnd && this.isLoadingScroll === false) {
        const currentPage = this.page + 1;
        const totalPages = this.dataResp.totalPages;
        if (currentPage < totalPages) this.loadMoreData();
      }
    },
    loadMoreData() {
      this.page += 1;
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
      });
      this.$store.dispatch(actionTypes.getDevicesScrollPush, {
        apiParams: stringifiedParams,
      });
    },
    reloadPage() {
      this.page = 0;
      this.fetchDevices();
    },
    clickSortCol(event, newSortItem) {
      const newSortItemData = newSortItem;
      newSortItem = newSortItem.replaceAll(".", "-");
      const scrollTableEl = document.getElementById("table-block");
      const beforeSortKey = this.sort.col.replaceAll(".", "-");
      const beforeSortDirection = this.sort.order;

      const beforeEl = document.querySelectorAll(
        `[data-col-sort~=${beforeSortKey}]`
      );
      beforeEl[0].children[0].innerHTML = '<i class="bi bi-arrow-down-up"></i>';

      // let newSortVal = [newSortItem, ""];

      if (beforeSortDirection === "desc" && beforeSortKey === newSortItem) {
        this.sort.order = "asc";
      } else {
        this.sort.order = "desc";
      }
      this.page = 0;
      this.sort.col = newSortItemData;

      scrollTableEl.scrollTo(0, 0);
      this.fetchDevices();
    },
    changeArrowIcon(sortKey = this.sort.col, sortDirection = this.sort.order) {
      sortKey = sortKey.replaceAll(".", "-");
      const targetEl = document.querySelectorAll(`[data-col-sort~=${sortKey}]`);
      if (sortDirection === "asc") {
        targetEl[0].children[0].innerHTML =
          '<i class="bi bi-arrow-up-square"></i>';
      } else {
        targetEl[0].children[0].innerHTML =
          '<i class="bi bi-arrow-down-square"></i>';
      }
    },
    formFilterRm() {
      this.$refs.dropdownBtnFilter.click();
      this.fetchDevices();
    },
    resetFilterForm() {
      for (let item in this.filters) {
        this.filters[item] = "";
      }
      this.$refs.dropdownBtnFilter.click();
      this.fetchDevices();
    },
    showUpdateDeviceForm(rowItem) {
      this.showModal = !this.showModal;
      this.titleModal = rowItem.deviceSn;

      this.formDeviceUpdate.deviceId = rowItem.deviceSn;
      this.formDeviceUpdate.deviceStatus = rowItem.deviceStatus;

      console.log(rowItem.status);
    },
    addClassValidInput(inputName) {
      return {
        "is-invalid":
          this.v$.formDeviceUpdate[inputName].$error === true &&
          this.v$.formDeviceUpdate[inputName].$dirty === true,
        "is-valid":
          this.v$.formDeviceUpdate[inputName].$error === false &&
          this.v$.formDeviceUpdate[inputName].$dirty === true,
      };
    },
    submitUpdateDevice() {
      this.v$.formDeviceUpdate.$touch();
      if (!this.v$.formDeviceUpdate.$error) {
        devicesApi
          .updateDevice({
            deviceStatus: this.formDeviceUpdate.deviceStatus,
            deviceId: this.formDeviceUpdate.deviceId,
          })
          .then(function (result) {
            return result;
          })
          .catch(function (result) {
            return result;
          })
          .then((result) => {
            // console.log("result", result);
            if (result.status === 200) {
              this.showModal = false;

              this.toastPropsList.push({
                content: `${this.$t(`uiElements.toast.successSave`)}`,
                bgColor: "bg-success",
                textColor: "text-white",
              });
              this.showToast = true;

              this.fetchDevices();
            } else {
              console.log("err");
              this.toastPropsList.push({
                content: `${this.$t(`uiElements.toast.errSave`)}`,
                bgColor: "bg-danger",
                textColor: "text-white",
              });
              this.showToast = true;
            }
          });
      }
    },
  },
  mounted() {
    this.fetchDevices();
  },
};
</script>

<style scoped></style>
