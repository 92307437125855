<template>
  <template v-if="isPathNameLogin">
    <div class="position-absolute end-0">
      <trn-locale-switcher></trn-locale-switcher>
    </div>
    <router-view />
  </template>
  <template v-else>
    <div class="flex-wrapper">
      <div class="header">
        <trn-topbar v-if="!isPathNameLogin"></trn-topbar>
      </div>
      <div class="main-content">
        <div class="container-fluid mt-3">
          <div class="row">
            <div class="col-md-2 left-block">
              <trn-leftbar v-if="!isPathNameLogin"></trn-leftbar>
            </div>
            <div class="col-md-10">
              <div class="content d-flex flex-column overflow-auto">
                <router-view />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="d-flex align-items-center">
          <div class="container d-flex justify-content-between">
            <div class="text-white">{{ $t(`Footer.copyright`) }}</div>
            <div class="">{{ $t(`Footer.versionStr`) }}: {{ uiVersion }}</div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import TrnTopbar from "@/components/Topbar";
import TrnLeftbar from "@/components/Leftbar";
import TrnLocaleSwitcher from "@/components/LocaleSwitcher.vue";

export default {
  components: { TrnLocaleSwitcher, TrnLeftbar, TrnTopbar },
  computed: {
    isPathNameLogin() {
      const pathName = this.$router.currentRoute.value.meta.name;
      return pathName === "login";
    },
    uiVersion() {
      return process.env.VUE_APP_UI_VERSION;
    },
  },
};
</script>
<style scoped>
.left-block {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05), 0 2px 10px 0 rgba(0, 0, 0, 0.05);
}
.content {
  height: calc(100vh - 110px);
}
.footer {
}
</style>
