<template>
  <div class="title-table mb-2 ps-3 py-1 fw-bold">
    {{ $t(`settings-menu.schedule`) }}
  </div>

  <!-- ToolBar -->
  <div class="toolbar">
    <div class="btn-group mb-2">
      <button
        class="btn btn-outline-primary btn-sm"
        type="button"
        @click="clickChangeGroupObj('add')"
      >
        <i class="bi bi-file-earmark-plus"></i>
        <span class="ms-1">{{ $t(`uiElements.btn.add`) }}</span>
      </button>
      <button
        class="btn btn-outline-primary btn-sm"
        :class="{ disabled: !selectedTableRow?.id }"
        type="button"
        @click="clickChangeGroupObj('update')"
      >
        <i class="bi bi-pencil-square"></i>
        <span class="ms-1">{{ $t(`uiElements.btn.change`) }}</span>
      </button>
      <button
        class="btn btn-outline-primary btn-sm"
        :class="{ disabled: !selectedTableRow?.id }"
        type="button"
        @click="clickForcedStartJob"
      >
        <i class="bi bi-play-btn"></i>
        <span class="ms-1">{{ $t(`Schedule.btn.forcedStart`) }}</span>
      </button>
    </div>
  </div>

  <!-- ToolBar кінець -->

  <div
    class="table-responsive table-block overflow-auto"
    id="table-block"
    @scroll="scrollTable"
  >
    <table class="table table-sm table-light">
      <thead>
        <tr class="">
          <th scope="col">
            {{ $t(`Schedule.table.title`) }}
          </th>
          <th scope="col">
            {{ $t(`Schedule.table.nextScheduledDate`) }}
          </th>
          <th scope="col">
            {{ $t(`Schedule.table.lastSuccessRunTime`) }}
          </th>
          <th scope="col">
            {{ $t(`Schedule.table.cron`) }}
          </th>
          <th scope="col">
            {{ $t(`Schedule.table.enabled`) }}
          </th>
          <th scope="col">
            {{ $t(`Schedule.table.type`) }}
          </th>
        </tr>
      </thead>
      <tbody class="text-nowrap">
        <template v-if="isLoading">
          <tr>
            <td colspan="16">
              <div class="loading text-center">
                <trn-loading></trn-loading>
              </div>
            </td>
          </tr>
        </template>
        <template v-if="dataResp">
          <tr
            v-for="item in dataResp.page.content"
            :key="item"
            class="cursor-pointer"
            :class="{
              'select-td': item.id === selectedTableRow?.id,
            }"
            @click="clickTableRow(item)"
          >
            <td>{{ item.title ? item.title : item.type }}</td>
            <td>{{ $filters.formatDateTime(item.nextScheduledDate) }}</td>
            <td>
              {{ $filters.formatDateTime(item.result?.lastSuccessRunTime) }}
            </td>
            <td>{{ item.cron }}</td>
            <td>
              <div class="form-check form-switch d-flex justify-content-center">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :checked="item.enabled === 'Y'"
                  disabled
                />
              </div>
            </td>
            <td>{{ item.type }}</td>
          </tr>
          <template v-if="isLoadingScroll">
            <tr>
              <td colspan="16">
                <div class="loading text-center">
                  <trn-loading></trn-loading>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>

  <bs-modal
    :modal-toggle-emit="showModal"
    :title="titleModal"
    @hideMainModal="showModal = false"
  >
    <template v-if="typeModalForm === 'AddUpdate'">
      <div>
        <!--  input-title  -->
        <div class="row mb-3">
          <label for="input-title" class="col-sm-4 col-form-label">
            {{ $t(`Schedule.table.title`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInput('title')"
              id="input-title"
              v-model="v$.formSchedule.title.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formSchedule.title.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formSchedule.title.reTextAndNum.$invalid">
                {{ $t(`Validations.reTextAndNum`) }}
              </div>
              <div v-if="v$.formSchedule.title.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 255 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-cron  -->
        <div class="row mb-3">
          <label for="input-cron" class="col-sm-12 col-form-label">
            {{ $t(`Schedule.table.cron`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-12">
            <Cron
              showResultCron="true"
              :cron="v$.formSchedule.cron.$model"
              showResultText="true"
              @cron-change="valChangedCron"
              :class="addClassValidInput('cron')"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formSchedule.cron.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-enabled  -->
        <div class="row mb-3">
          <label for="input-enabled" class="col-sm-6 col-form-label">
            {{ $t(`Schedule.table.enabled`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('enabled')"
              id="input-enabled"
              v-model="v$.formSchedule.enabled.$model"
            >
              <option value="Y">{{ $t(`uiElements.text.enabled`) }}</option>
              <option value="N">{{ $t(`uiElements.text.disable`) }}</option>
            </select>
            <div class="invalid-feedback">
              <div v-if="v$.formSchedule.enabled.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-type  -->
        <div class="row mb-3">
          <label for="input-type" class="col-sm-6 col-form-label">
            {{ $t(`Schedule.table.type`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('type')"
              id="input-type"
              v-model="v$.formSchedule.type.$model"
            >
              <option
                :value="item"
                v-for="item in this.dataResp.allTypes"
                :key="item"
              >
                {{ item }}
              </option>
            </select>
            <div class="invalid-feedback">
              <div v-if="v$.formSchedule.type.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-nextScheduledDate  -->
        <div class="row mb-3">
          <label for="input-nextScheduledDate" class="col-sm-6 col-form-label">
            {{ $t(`Schedule.table.nextScheduledDate`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <Datepicker
              id="nextScheduledDate-ff"
              :class="addClassValidInput('nextScheduledDate')"
              v-model="v$.formSchedule.nextScheduledDate.$model"
              autoApply
              locale="uk"
              :format="dateFormat"
              required
            />
            <div class="invalid-feedback">
              <div v-if="v$.formSchedule.nextScheduledDate.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  Buttons form  -->
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-outline-secondary me-3"
            @click="showModal = false"
          >
            {{ $t(`uiElements.btn.close`) }}
          </button>
          <button
            type="submit"
            class="btn btn-outline-primary"
            :disabled="v$.$invalid && v$.$dirty ? true : false"
            @click="submitScheduleForm"
          >
            {{ $t(`uiElements.btn.save`) }}
          </button>
        </div>
      </div>
    </template>

    <template v-if="typeModalForm === 'forcedStartJob'">
      <div :key="'new' + Math.random()">
        <!--  input-title  -->
        <div class="row mb-3">
          <label for="input-title1" class="col-sm-4 col-form-label">
            {{ $t(`Schedule.table.title`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInput('title')"
              id="input-title1"
              v-model="formScheduleTitle"
              disabled
            />
          </div>
        </div>

        <!--  Buttons form  -->
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-outline-secondary me-3"
            @click="showModal = false"
          >
            {{ $t(`uiElements.btn.close`) }}
          </button>
          <button
            type="submit"
            class="btn btn-outline-primary"
            @click="runForcedStartJob"
          >
            {{ $t(`uiElements.btn.run`) }}
          </button>
        </div>
      </div>
    </template>
  </bs-modal>
  <div
    class="toast-container position-absolute p-3 end-0 z-index-1500"
    v-if="showToast"
  >
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import TrnLoading from "@/components/Loading";
import BsModal from "@/components/UI-elements/BsModal";
import BsToast from "@/components/UI-elements/BsToast";
import useVuelidate from "@vuelidate/core";
import {helpers, maxLength, required} from "@vuelidate/validators";
import { mapState } from "vuex";
import { stringify } from "query-string";
import { actionTypes } from "@/store/modules/settings-store/schedule";
import Datepicker from "vue3-date-time-picker";
import scheduleApi from "@/api/settings-api/schedule";
import Cron from "vue-cron-builder";
import "vue-cron-builder/dist/cron.css";

export default {
  name: "TrnSchedule",
  components: {
    TrnLoading,
    BsModal,
    BsToast,
    Datepicker,
    Cron,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      page: 0,
      size: 100,
      sort: {
        col: "id",
        order: "asc",
      },
      showModal: false,
      titleModal: "",
      formSchedule: {},
      formScheduleType: "",
      showToast: false,
      toastPropsList: [],
      selectedTableRow: null,
      dateFormat: "dd.MM.yyyy HH:MM",
      typeModalForm: "",
      allTypeJobCron: [],
    };
  },
  validations() {
    return {
      formSchedule: {
        cron: {
          required,
        },
        enabled: {
          required,
        },
        title: {
          required,
          reTextAndNum: helpers.regex(/^[a-zA-Zа-яА-ЯіїґєІЇҐЄёЁ0-9\s_]+$/u),
          maxLength: maxLength(255),
        },
        type: {
          required,
        },
        nextScheduledDate: {
          required,
        },
      },
    };
  },
  computed: {
    dataResp: {
      get() {
        return this.$store.state.schedule.dataResp;
      },
      set(newValue) {
        this.$store.state.schedule.dataResp = newValue;
      },
    },
    ...mapState({
      isLoading: (state) => state.schedule.isLoading,
      isLoadingScroll: (state) => state.schedule.isLoadingScroll,
      dataModalResp: (state) => state.schedule.dataModalResp,
      errorResp: (state) => state.schedule.errorResp,
    }),
    formScheduleTitle() {
      return this.selectedTableRow.title
        ? this.selectedTableRow.title
        : this.selectedTableRow.type;
    },
  },
  methods: {
    fetchSchedule() {
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
      });
      this.$store.dispatch(actionTypes.getSchedule, {
        apiParams: stringifiedParams,
      });
    },
    scrollTable(event) {
      const currentEl = event.target;
      let checkScrollEnd =
        currentEl.scrollTop + currentEl.clientHeight + 3 >= currentEl.scrollHeight;
      if (checkScrollEnd && this.isLoadingScroll === false) {
        const currentPage = this.page + 1;
        const totalPages = this.dataResp.totalPages;
        if (currentPage < totalPages) this.loadMoreData();
      }
    },
    loadMoreData() {
      this.page += 1;
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
      });
      this.$store.dispatch(actionTypes.getScheduleScrollPush, {
        apiParams: stringifiedParams,
      });
    },
    reloadPage() {
      this.page = 0;
      this.fetchSchedule();
    },
    addClassValidInput(inputName) {
      return {
        "is-invalid":
          this.v$.formSchedule[inputName].$error === true &&
          this.v$.formSchedule[inputName].$dirty === true,
        "is-valid":
          this.v$.formSchedule[inputName].$error === false &&
          this.v$.formSchedule[inputName].$dirty === true,
      };
    },
    clickTableRow(rowItem) {
      this.selectedTableRow = rowItem;
    },
    clickChangeGroupObj(type) {
      this.formScheduleType = type;
      this.showModal = true;
      this.typeModalForm = "AddUpdate";
      if (type === "add") {
        this.selectedTableRow = null;
        this.formSchedule = {};
        this.titleModal = this.$t(`Schedule.table.title`);
      } else {
        this.titleModal = this.selectedTableRow.title
          ? this.selectedTableRow.title
          : this.selectedTableRow.type;

        this.formSchedule.cron = this.selectedTableRow.cron;
        this.formSchedule.enabled = this.selectedTableRow.enabled;
        this.formSchedule.title = this.selectedTableRow.title
          ? this.selectedTableRow.title
          : this.selectedTableRow.type;
        this.formSchedule.type = this.selectedTableRow.type;

        this.formSchedule.nextScheduledDate = this.$filters.formatDateTime(
          this.selectedTableRow.nextScheduledDate,
          "date-obj"
        );
      }
    },
    submitScheduleForm() {
      this.v$.formSchedule.$touch();
      if (!this.v$.formSchedule.$error) {
        this.formSchedule.nextScheduledDate = this.$filters.formatDateTime(
          this.formSchedule.nextScheduledDate,
          "yyyy-MM-dd HH:mm"
        );
        if (this.formScheduleType === "add") {
          scheduleApi
            .addSchedule({ ...this.formSchedule })
            .then(function (result) {
              return result;
            })
            .catch(function (result) {
              return result;
            })
            .then((result) => {
              // console.log("result", result);
              if (result.status === 200) {
                this.selectedTableRow = {};
                this.formSchedule = {};
                this.showModal = false;

                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.successSave`)}`,
                  bgColor: "bg-success",
                  textColor: "text-white",
                });
                this.showToast = true;

                this.fetchSchedule();
              } else {
                console.log("err");
                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.errSave`)}`,
                  bgColor: "bg-danger",
                  textColor: "text-white",
                });
                this.showToast = true;
              }
            });
        } else {
          scheduleApi
            .updateSchedule(this.selectedTableRow.id, {
              ...this.formSchedule,
            })
            .then(function (result) {
              return result;
            })
            .catch(function (result) {
              return result;
            })
            .then((result) => {
              // console.log("result", result);
              if (result.status === 200) {
                this.selectedTableRow = {};
                this.showModal = false;

                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.successSave`)}`,
                  bgColor: "bg-success",
                  textColor: "text-white",
                });
                this.showToast = true;

                this.fetchSchedule();
              } else {
                console.log("err");
                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.errSave`)}`,
                  bgColor: "bg-danger",
                  textColor: "text-white",
                });
                this.showToast = true;
              }
            });
        }
      }
    },
    valChangedCron(val) {
      this.formSchedule.cron = val;
    },
    clickForcedStartJob() {
      this.showModal = true;
      this.titleModal = this.$t(`Schedule.btn.forcedStart`);
      this.typeModalForm = "forcedStartJob";
    },
    runForcedStartJob() {
      scheduleApi
        .updateSchedule(this.selectedTableRow.id)
        .then(function (result) {
          return result;
        })
        .catch(function (result) {
          return result;
        })
        .then((result) => {
          // console.log("result", result);
          if (result.status === 200) {
            this.selectedTableRow = {};
            this.showModal = false;

            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.successSave`)}`,
              bgColor: "bg-success",
              textColor: "text-white",
            });
            this.showToast = true;

            this.fetchSchedule();
          } else {
            console.log("err");
            this.toastPropsList.push({
              content: `${this.$t(`uiElements.toast.errSave`)}`,
              bgColor: "bg-danger",
              textColor: "text-white",
            });
            this.showToast = true;
          }
        });
    },
  },
  watch: {
    "formSchedule.nextScheduledDate"() {
      let startDate = new Date();
      let endDate = new Date(this.formSchedule.nextScheduledDate);
      // console.log("formTmsKey.effDate", startDate > endDate)
      if (startDate > endDate) {
        this.formSchedule.nextScheduledDate = startDate;
      }
    },
  },
  mounted() {
    this.fetchSchedule();
  },
};
</script>

<style scoped>
.select-td > td {
  background-color: #8b8b8b !important;
  color: white !important;
}
</style>
