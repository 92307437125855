import axios from "@/api/axios";

const transactions = (apiUrl) => {
  return axios.get(apiUrl);
};

const terminalId = (value) => {
  return axios.get("/terminals?terminalId=" + value);
};

const deviceId = (value) => {
  return axios.get("/devices/terminal/" + value);
};

const receiptNumber = (value) => {
  return axios.get(`/transactions/receipt?lang=RUS&receiptDataId=${value}`);
};

export default {
  transactions,
  terminalId,
  receiptNumber,
  deviceId,
};
