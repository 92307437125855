<template>
  <ul class="list-group list-group-flush">
    <li
      class="list-group-item d-flex justify-content-between align-items-center"
    >
      {{ $t(`Transaction.table.terminalId`)
      }}<span class="badge bg-primary">{{
        dataModalResp.content[0].terminalId
      }}</span>
    </li>
    <li
      class="list-group-item d-flex justify-content-between align-items-center"
    >
      {{ $t(`TerminalGroups.table.groupNumber`)
      }}<span class="badge bg-primary">{{
        dataModalResp.content[0].groupNumber
      }}</span>
    </li>
    <li
      class="list-group-item d-flex justify-content-between align-items-center"
    >
      {{ $t(`Terminal.table.dateTimeInit`)
      }}<span class="badge bg-primary">{{
        this.$filters.formatDateTime(
          dataModalResp.content[0].dateTimeInit,
          "date"
        )
      }}</span>
    </li>
    <li
      class="list-group-item d-flex justify-content-between align-items-center"
    >
      {{ $t(`Terminal.table.merchantName`)
      }}<span class="badge bg-primary">{{
        dataModalResp.content[0].merchant.merchantName
      }}</span>
    </li>
    <li
      class="list-group-item d-flex justify-content-between align-items-center"
    >
      {{ $t(`Terminal.table.merchantLegalName`)
      }}<span class="badge bg-primary">{{
        dataModalResp.content[0].merchant.merchantLegalName
      }}</span>
    </li>
    <li
      class="list-group-item d-flex justify-content-between align-items-center"
    >
      {{ $t(`MerchantRegistration.table.taxId`)
      }}<span class="badge bg-primary">{{
        dataModalResp.content[0].merchant.taxId
      }}</span>
    </li>
    <li
      class="list-group-item d-flex justify-content-between align-items-center"
    >
      {{ $t(`Terminal.table.bankName`)
      }}<span class="badge bg-primary">{{
        dataModalResp.content[0].merchant.bank.name
      }}</span>
    </li>
    <li
      class="list-group-item d-flex justify-content-between align-items-center"
    >
      {{ $t(`MerchantRegistration.table.mcc`)
      }}<span class="badge bg-primary">{{
        dataModalResp.content[0].merchant.mcc
      }}</span>
    </li>
    <li
      class="list-group-item d-flex justify-content-between align-items-center"
    >
      {{ $t(`Transaction.table.language`)
      }}<span class="badge bg-primary">{{
        dataModalResp.content[0].device.language
      }}</span>
    </li>
    <li
      class="list-group-item d-flex justify-content-between align-items-center"
    >
      {{ $t(`TerminalGroups.table.opQr`)
      }}<span class="badge bg-primary">{{
        dataModalResp.content[0].opQr
      }}</span>
    </li>
    <li
      class="list-group-item d-flex justify-content-between align-items-center"
    >
      {{ $t(`TerminalGroups.table.opNfc`)
      }}<span class="badge bg-primary">{{
        dataModalResp.content[0].opNfc
      }}</span>
    </li>
    <li
      class="list-group-item d-flex justify-content-between align-items-center"
    >
      {{ $t(`TerminalGroups.table.opManual`)
      }}<span class="badge bg-primary">{{
        dataModalResp.content[0].opManual
      }}</span>
    </li>
    <li
      class="list-group-item d-flex justify-content-between align-items-center"
    >
      {{ $t(`TerminalGroups.table.opReversal`)
      }}<span class="badge bg-primary">{{
        dataModalResp.content[0].opReversal
      }}</span>
    </li>
    <li
      class="list-group-item d-flex justify-content-between align-items-center"
    >
      {{ $t(`TerminalGroups.table.opRefund`)
      }}<span class="badge bg-primary">{{
        dataModalResp.content[0].opRefund
      }}</span>
    </li>
    <li
      class="list-group-item d-flex justify-content-between align-items-center"
    >
      {{ $t(`TerminalGroups.table.opPinMastercard`)
      }}<span class="badge bg-primary">{{
        dataModalResp.content[0].opPinMasterCard
      }}</span>
    </li>
    <li
      class="list-group-item d-flex justify-content-between align-items-center"
    >
      {{ $t(`TerminalGroups.table.opPinVisa`)
      }}<span class="badge bg-primary">{{
        dataModalResp.content[0].opPinVisa
      }}</span>
    </li>
    <li
        class="list-group-item d-flex justify-content-between align-items-center"
    >
      {{ $t(`TerminalGroups.table.opPinProstir`)
      }}<span class="badge bg-primary">{{
        dataModalResp.content[0].opPinProstir
      }}</span>
    </li>
    <li
      class="list-group-item d-flex justify-content-between align-items-center"
    >
      {{ $t(`TerminalGroups.table.opPinAmex`)
      }}<span class="badge bg-primary">{{
        dataModalResp.content[0].opPinAmex
      }}</span>
    </li>
    <li
        class="list-group-item d-flex justify-content-between align-items-center"
    >
      {{ $t(`TerminalGroups.table.opPinDiscover`)
      }}<span class="badge bg-primary">{{
        dataModalResp.content[0].opPinDiscover
      }}</span>
    </li>
    <li
        class="list-group-item d-flex justify-content-between align-items-center"
    >
      {{ $t(`TerminalGroups.table.geoPosition`)
      }}<span class="badge bg-primary">{{
        dataModalResp.content[0].geoPosition
      }}</span>
    </li>
    <li
      class="list-group-item d-flex justify-content-between align-items-center"
    >
      {{ $t(`TerminalGroups.table.receiptSendChannelIdList`)
      }}<span class="badge bg-primary">
        <div
          class="py-1"
          v-for="receiptSendChannel in dataModalResp.content[0]
            .receiptSendChannels"
          :key="receiptSendChannel"
        >
          {{ receiptSendChannel.name }}
        </div>
      </span>
    </li>
    <li
      class="list-group-item d-flex justify-content-between align-items-center"
    >
      {{ $t(`TerminalGroups.table.opPurchase`)
      }}<span class="badge bg-primary">{{
        dataModalResp.content[0].opPurchase
      }}</span>
    </li>
    <li
      class="list-group-item d-flex justify-content-between align-items-center"
    >
      {{ $t(`TerminalGroups.table.productName`) }}
      <span class="badge bg-primary">
        <div
          class="py-1"
          v-for="product in dataModalResp.content[0].products"
          :key="product"
        >
          {{ product.productName }}
        </div>
      </span>
    </li>
    <li
      class="list-group-item d-flex justify-content-between align-items-center"
    >
      {{ $t(`TerminalGroups.table.receiptTemplateId`)
      }}<span class="badge bg-primary">{{
        dataModalResp.content[0].receiptTemplate.id
      }}</span>
    </li>
    <li
      class="list-group-item d-flex justify-content-between align-items-center"
    >
      {{ $t(`TerminalGroups.table.oneTransactionLimit`)
      }}<span class="badge bg-primary">{{
        dataModalResp.content[0].oneTransactionLimit
      }}</span>
    </li>
    <li
      class="list-group-item d-flex justify-content-between align-items-center"
    >
      {{ $t(`TerminalGroups.table.noPinLimit`) }}а<span
        class="badge bg-primary"
        >{{ dataModalResp.content[0].noPinLimit }}</span
      >
    </li>
    <li
      class="list-group-item d-flex justify-content-between align-items-center"
    >
      {{ $t(`Devices.table.deviceName`)
      }}<span class="badge bg-primary">{{
        dataModalResp.content[0].device.deviceName
      }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: "TrnTransactionModalTerminalContent",
  props: {
    dataModalResp: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
