<template>
  <div class="title-table mb-2 ps-3 py-1 fw-bold">
    {{ $t(`left-menu.user`) }}
  </div>

  <!-- ToolBar -->
  <div class="toolbar">
    <div class="btn-group mb-2">
      <button
        class="btn btn-outline-primary btn-sm"
        type="button"
        @click="clickChangeUserObj('add')"
      >
        <i class="bi bi-file-earmark-plus"></i>
        <span class="ms-1">{{ $t(`Users.addUser`) }}</span>
      </button>

      <button
        class="btn btn-outline-primary btn-sm"
        :class="{ disabled: !selectedTableRow?.username }"
        type="button"
        @click="clickChangeUserObj('update')"
      >
        <i class="bi bi-pencil-square"></i>
        <span class="ms-1">{{ $t(`uiElements.btn.change`) }}</span>
      </button>

      <button
        class="btn btn-outline-primary btn-sm"
        :class="{ disabled: !selectedTableRow?.username }"
        type="button"
        @click="clickChangePasswordObj"
      >
        <i class="bi bi-pencil-square"></i>
        <span class="ms-1">{{ $t(`Users.changePassword`) }}</span>
      </button>
    </div>
  </div>

  <!-- ToolBar кінець -->

  <div
    class="table-responsive table-block overflow-auto"
    id="table-block"
    @scroll="scrollTable"
  >
    <table class="table table-sm table-light">
      <thead>
        <tr class="text-center">
          <th scope="col">
            {{ $t(`Users.table.username`) }}
          </th>
          <th scope="col">
            {{ $t(`Users.table.firstName`) }}
          </th>
          <th scope="col">
            {{ $t(`Users.table.lastName`) }}
          </th>
          <th scope="col">
            {{ $t(`Users.table.email`) }}
          </th>
          <th scope="col">
            {{ $t(`Users.table.userRole`) }}
          </th>
          <th scope="col">
            {{ $t(`Users.table.status`) }}
          </th>
        </tr>
      </thead>
      <tbody class="text-nowrap">
        <template v-if="isLoading">
          <tr>
            <td colspan="16">
              <div class="loading text-center">
                <trn-loading></trn-loading>
              </div>
            </td>
          </tr>
        </template>
        <template v-if="dataResp">
          <tr
            v-for="item in dataResp.content[0].users"
            :key="item"
            class="text-center cursor-pointer"
            :class="{
              'select-td': item.username === selectedTableRow?.username,
            }"
            @click="clickTableRow(item)"
          >
            <td>{{ item.username }}</td>
            <td>{{ item.firstName }}</td>
            <td>{{ item.lastName }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.userRole }}</td>
            <td>{{ item.status }}</td>
          </tr>
          <template v-if="isLoadingScroll">
            <tr>
              <td colspan="16">
                <div class="loading text-center">
                  <trn-loading></trn-loading>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>

  <bs-modal
    :modal-toggle-emit="showModal"
    :title="titleModal"
    @hideMainModal="showModal = false"
  >
    <template v-if="showFormTemplate === 'AddUpdate'">
      <form
        class="needs-validation"
        @submit.prevent="submitAddUpdateUser"
        novalidate
      >
        <!--  input-username  -->
        <div class="row mb-3">
          <label for="input-username" class="col-sm-6 col-form-label">
            {{ $t(`Users.table.username`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInput('formUsers', 'username')"
              id="input-username"
              v-model="v$.formUsers.username.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formUsers.username.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formUsers.username.reTextAndNum.$invalid">
                {{ $t(`Validations.reTextAndNum`) }}
              </div>
              <div v-if="v$.formUsers.username.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 255 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-email  -->
        <div class="row mb-3">
          <label for="input-email" class="col-sm-6 col-form-label">
            {{ $t(`Users.table.email`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInput('formUsers', 'email')"
              id="input-email"
              v-model="v$.formUsers.email.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formUsers.email.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formUsers.email.reEmail.$invalid">
                {{ $t(`Validations.reEmail`) }}
              </div>
              <div v-if="v$.formUsers.email.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 255 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-firstName  -->
        <div class="row mb-3">
          <label for="input-firstName" class="col-sm-6 col-form-label">
            {{ $t(`Users.table.firstName`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInput('formUsers', 'firstName')"
              id="input-firstName"
              v-model="v$.formUsers.firstName.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formUsers.firstName.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formUsers.firstName.reTextAndNum.$invalid">
                {{ $t(`Validations.reTextAndNum`) }}
              </div>
              <div v-if="v$.formUsers.firstName.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 255 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-lastName  -->
        <div class="row mb-3">
          <label for="input-lastName" class="col-sm-6 col-form-label">
            {{ $t(`Users.table.lastName`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInput('formUsers', 'lastName')"
              id="input-lastName"
              v-model="v$.formUsers.lastName.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formUsers.lastName.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formUsers.lastName.reTextAndNum.$invalid">
                {{ $t(`Validations.reTextAndNum`) }}
              </div>
              <div v-if="v$.formUsers.lastName.maxLength.$invalid">
                {{ $t(`Validations.maxLength`, { num: 255 }) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-password  -->
        <div class="row mb-3" v-if="passRequiredIf">
          <label for="input-password" class="col-sm-6 col-form-label">
            {{ $t(`Users.table.password`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="password"
              class="form-control"
              :class="addClassValidInput('formUsers', 'password')"
              id="input-password"
              v-model="v$.formUsers.password.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formUsers.password.requiredIf.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div
                v-if="v$.formUsers.password.passRule1.$invalid"
                v-html="
                  $t(`Validations.passRule1`, { specChar: '@,#,$,%,^,&,+,=,!' })
                "
              ></div>
              <div
                v-if="v$.formUsers.password.passRule2.$invalid"
                v-html="$t(`Validations.passRule2`)"
              ></div>
            </div>
          </div>
        </div>

        <!--  input-passwordRepeat  -->
        <div class="row mb-3" v-if="passRequiredIf">
          <label for="input-passwordRepeat" class="col-sm-6 col-form-label">
            {{ $t(`Validations.passwordRepeat`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="password"
              class="form-control"
              :class="addClassValidInput('formUsers', 'passwordRepeat')"
              id="input-passwordRepeat"
              v-model="v$.formUsers.passwordRepeat.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formUsers.passwordRepeat.requiredIf.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div v-if="v$.formUsers.passwordRepeat.sameAs.$invalid">
                {{
                  $t(`MerchantRegistration.validationRegForm.passwordRepeatErr`)
                }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-status  -->
        <div class="row mb-3">
          <label for="input-status" class="col-sm-6 col-form-label">
            {{ $t(`Users.table.status`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('formUsers', 'status')"
              id="input-status"
              v-model="v$.formUsers.status.$model"
            >
              <option value="ACTIVE">ACTIVE</option>
              <option value="BLOCKED">BLOCKED</option>
              <option value="DELETED">DELETED</option>
            </select>
            <div class="invalid-feedback">
              <div v-if="v$.formUsers.status.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-userRoleCodeList  -->
        <div class="row mb-3">
          <label for="input-userRoleCodeList" class="col-sm-6 col-form-label">
            {{ $t(`Users.table.userRole`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <select
              class="form-select"
              :class="addClassValidInput('formUsers', 'userRoleCodeList')"
              id="input-userRoleCodeList"
              v-model="v$.formUsers.userRoleCodeList.$model"
            >
              <option
                v-for="item in dataResp.content[0].roles"
                :key="item"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
            <div class="invalid-feedback">
              <div v-if="v$.formUsers.userRoleCodeList.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  Buttons form  -->
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-outline-secondary me-3"
            @click="showModal = false"
          >
            {{ $t(`uiElements.btn.close`) }}
          </button>
          <button
            type="submit"
            class="btn btn-outline-primary"
            :disabled="v$.$invalid && v$.$dirty ? true : false"
          >
            {{ $t(`uiElements.btn.save`) }}
          </button>
        </div>
      </form>
    </template>

    <template v-if="showFormTemplate === 'changePassword'">
      <form
        class="needs-validation"
        @submit.prevent="submitChangePasswordUser"
        novalidate
      >
        <!--  input-username  -->
        <div class="row mb-3">
          <label for="input-username" class="col-sm-6 col-form-label">
            {{ $t(`Users.table.username`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              :class="addClassValidInput('formChangePassword', 'username')"
              id="input-username"
              disabled
              v-model="v$.formChangePassword.username.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formChangePassword.username.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
            </div>
          </div>
        </div>

        <!--  input-password  -->
        <div class="row mb-3">
          <label for="input-password" class="col-sm-6 col-form-label">
            {{ $t(`Users.table.password`) }}
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-6">
            <input
              type="password"
              class="form-control"
              :class="addClassValidInput('formChangePassword', 'password')"
              id="input-password"
              v-model="v$.formChangePassword.password.$model"
            />
            <div class="invalid-feedback">
              <div v-if="v$.formChangePassword.password.required.$invalid">
                {{ $t(`Validations.required`) }}
              </div>
              <div
                v-if="v$.formChangePassword.password.passRule1.$invalid"
                v-html="
                  $t(`Validations.passRule1`, { specChar: '@,#,$,%,^,&,+,=,!' })
                "
              ></div>
              <div
                v-if="v$.formChangePassword.password.passRule2.$invalid"
                v-html="$t(`Validations.passRule2`)"
              ></div>
            </div>
          </div>
        </div>

        <!--  Buttons form  -->
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-outline-secondary me-3"
            @click="showModal = false"
          >
            {{ $t(`uiElements.btn.close`) }}
          </button>
          <button
            type="submit"
            class="btn btn-outline-primary"
            :disabled="v$.$invalid && v$.$dirty ? true : false"
          >
            {{ $t(`uiElements.btn.save`) }}
          </button>
        </div>
      </form>
    </template>
  </bs-modal>
  <div class="toast-container position-absolute p-3 end-0 z-index-1500" v-if="showToast">
    <bs-toast v-for="toast in toastPropsList" :key="toast" :="toast"></bs-toast>
  </div>
</template>

<script>
import TrnLoading from "@/components/Loading";
import BsModal from "@/components/UI-elements/BsModal";
import BsToast from "@/components/UI-elements/BsToast";
import useVuelidate from "@vuelidate/core";
import {
  helpers,
  maxLength,
  required,
  requiredIf,
  sameAs,
} from "@vuelidate/validators";
import { mapState } from "vuex";
import { stringify } from "query-string";
import { actionTypes } from "@/store/modules/users";
import usersApi from "@/api/users";

export default {
  name: "TrnUsers",
  components: {
    TrnLoading,
    BsModal,
    BsToast,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      page: 0,
      size: 100,
      sort: {
        col: "username",
        order: "asc",
      },
      showModal: false,
      titleModal: "",
      showFormTemplate: "",
      formUsers: {},
      formUsersType: "",
      formChangePassword: {},
      showToast: false,
      toastPropsList: [],
      selectedTableRow: null,
      passRequiredIf: false,
    };
  },
  validations() {
    return {
      formUsers: {
        email: {
          required,
          reEmail: helpers.regex(/^\S+@\S+\.\S+$/),
          maxLength: maxLength(255),
        },
        firstName: {
          required,
          reTextAndNum: helpers.regex(/^[a-zA-Zа-яА-ЯіїґєІЇҐЄёЁ0-9\s]+$/u),
          maxLength: maxLength(255),
        },
        lastName: {
          required,
          reTextAndNum: helpers.regex(/^[a-zA-Zа-яА-ЯіїґєІЇҐЄёЁ0-9\s]+$/u),
          maxLength: maxLength(255),
        },
        password: {
          requiredIf: requiredIf(this.passRequiredIf),
          passRule1: helpers.regex(
            /^(?=.*\d)(?=.*[A-Z])(?=.*[@#$%^&+=!])(?!.*\s).{8,}$/
          ),
          passRule2: helpers.regex(/^(?!.*(.)\1\1\1)[\s\S]*$/),
        },
        passwordRepeat: {
          requiredIf: requiredIf(this.passRequiredIf),
          sameAs: sameAs(this.formUsers.password),
        },
        status: {
          required,
        },
        userRoleCodeList: {
          required,
        },
        username: {
          required,
          reTextAndNum: helpers.regex(/^[a-zA-Zа-яА-ЯіїґєІЇҐЄёЁ0-9\s]+$/u),
          maxLength: maxLength(255),
        },
      },
      formChangePassword: {
        password: {
          required,
          passRule1: helpers.regex(
            /^(?=.*\d)(?=.*[A-Z])(?=.*[@#$%^&+=!])(?!.*\s).{8,}$/
          ),
          passRule2: helpers.regex(/^(?!.*(.)\1\1\1)[\s\S]*$/),
        },
        username: {
          required,
        },
      },
    };
  },
  computed: {
    dataResp: {
      get() {
        return this.$store.state.users.dataResp;
      },
      set(newValue) {
        this.$store.state.users.dataResp = newValue;
      },
    },
    ...mapState({
      isLoading: (state) => state.users.isLoading,
      isLoadingScroll: (state) => state.users.isLoadingScroll,
      dataModalResp: (state) => state.users.dataModalResp,
      errorResp: (state) => state.users.errorResp,
    }),
  },
  methods: {
    fetchUsers() {
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
      });
      this.$store.dispatch(actionTypes.getUsers, {
        apiParams: stringifiedParams,
      });
    },
    scrollTable(event) {
      const currentEl = event.target;
      let checkScrollEnd =
        currentEl.scrollTop + currentEl.clientHeight + 3 >=
        currentEl.scrollHeight;
      if (checkScrollEnd && this.isLoadingScroll === false) {
        const currentPage = this.page + 1;
        const totalPages = this.dataResp.totalPages;
        if (currentPage < totalPages) this.loadMoreData();
      }
    },
    loadMoreData() {
      this.page += 1;
      const stringifiedParams = stringify({
        page: this.page,
        size: this.size,
        sort: `${this.sort.col},${this.sort.order}`,
      });
      this.$store.dispatch(actionTypes.getUsersScrollPush, {
        apiParams: stringifiedParams,
      });
    },
    reloadPage() {
      this.page = 0;
      this.fetchUsers();
    },
    addClassValidInput(formName, inputName) {
      return {
        "is-invalid":
          this.v$[formName][inputName].$error === true &&
          this.v$[formName][inputName].$dirty === true,
        "is-valid":
          this.v$[formName][inputName].$error === false &&
          this.v$[formName][inputName].$dirty === true,
      };
    },
    clickTableRow(rowItem) {
      this.selectedTableRow = rowItem;
    },
    clickChangeUserObj(type) {
      this.formUsersType = type;
      this.showModal = true;
      this.showFormTemplate = "AddUpdate";
      if (type === "add") {
        this.selectedTableRow = null;
        this.formUsers = {};
        this.titleModal = this.$t(`Users.addUser`);
        this.passRequiredIf = true;
      } else {
        this.formUsers = {};
        this.titleModal = this.selectedTableRow.username;

        this.formUsers.username = this.selectedTableRow.username;
        this.formUsers.firstName = this.selectedTableRow.firstName;
        this.formUsers.lastName = this.selectedTableRow.lastName;
        this.formUsers.email = this.selectedTableRow.email;
        this.formUsers.userRoleCodeList = this.selectedTableRow.userRole;
        this.formUsers.status = this.selectedTableRow.status;
        this.passRequiredIf = false;
      }
    },
    clickChangePasswordObj() {
      this.showModal = true;
      this.showFormTemplate = "changePassword";
      this.titleModal = this.$t("Users.changePassword");
      this.formChangePassword.username = this.selectedTableRow.username;
    },
    submitChangePasswordUser() {
      this.v$.formChangePassword.$touch();
      if (!this.v$.formChangePassword.$error) {
        usersApi
          .changePasswordUser({
            username: this.selectedTableRow.username,
            password: this.formChangePassword.password,
          })
          .then(function (result) {
            return result;
          })
          .catch(function (result) {
            return result;
          })
          .then((result) => {
            // console.log("result", result);
            if (result.status === 200) {
              this.showModal = false;

              this.toastPropsList.push({
                content: `${this.$t(`uiElements.toast.successSave`)}`,
                bgColor: "bg-success",
                textColor: "text-white",
              });
              this.showToast = true;

              this.fetchUsers();
            } else {
              console.log("err");
              this.toastPropsList.push({
                title: `${this.$t(`uiElements.toast.errSave`)}`,
                content: result.response.data.error.errorText,
                bgColor: "bg-danger",
                textColor: "text-white",
              });
              this.showToast = true;
            }
          });
      }
    },
    submitAddUpdateUser() {
      this.v$.formUsers.$touch();
      if (!this.v$.formUsers.$error) {
        if (this.formUsersType === "add") {
          let addObj = { ...this.formUsers };
          addObj.userRoleCodeList = [this.formUsers.userRoleCodeList];
          usersApi
            .addUser({ ...addObj })
            .then(function (result) {
              return result;
            })
            .catch(function (result) {
              return result;
            })
            .then((result) => {
              // console.log("result", result);
              if (result.status === 200) {
                this.showModal = false;
                this.selectedTableRow = null;
                this.formUsers = {};

                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.successSave`)}`,
                  bgColor: "bg-success",
                  textColor: "text-white",
                });
                this.showToast = true;

                this.fetchUsers();
              } else {
                console.log("err");
                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.errSave`)}`,
                  bgColor: "bg-danger",
                  textColor: "text-white",
                });
                this.showToast = true;
              }
            });
        } else {
          let updateObj = { ...this.formUsers };
          delete updateObj.password;
          updateObj["username"] = this.selectedTableRow.username;
          updateObj.userRoleCodeList = [this.selectedTableRow.userRole];
          usersApi
            .updateUser({
              ...updateObj,
            })
            .then(function (result) {
              return result;
            })
            .catch(function (result) {
              return result;
            })
            .then((result) => {
              // console.log("result", result);
              if (result.status === 200) {
                this.showModal = false;

                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.successSave`)}`,
                  bgColor: "bg-success",
                  textColor: "text-white",
                });
                this.showToast = true;

                this.fetchUsers();
              } else {
                console.log("err");
                this.toastPropsList.push({
                  content: `${this.$t(`uiElements.toast.errSave`)}`,
                  bgColor: "bg-danger",
                  textColor: "text-white",
                });
                this.showToast = true;
              }
            });
        }
      }
    },
  },
  mounted() {
    this.fetchUsers();
  },
};
</script>

<style scoped>
.select-td > td {
  background-color: #0d6efd !important;
  color: white !important;
}
</style>
